(function(angular) {
	angular.module('mobilezuz').service('EsLanguage', function() {
		this.id = 3;
		this.culture = 'es';
		this.name = 'Español';
		this.direction = 'ltr';
		this.translations = {
			'en': 'es',
			'my': 'mi',
			'me': 'yo',
			'in {name} dep.': 'במחלקת {name}',
			'in specials': 'En especiales',
			'special products': 'Productos Especiales',
			'shopping lists': 'Listas de Compras',
			'shopping list': 'Listas de Compras',
			'shop': 'Tienda',
			'shop ': 'Tienda',
			'by departments': 'Por Departamento',
			'email': 'Correo Electrónico',
			'email_error': 'Por favor ingrese una dirección de correo electrónico valida',
			'password': 'Contraseña',
			'log in': 'Iniciar Sesión',
			'log in with facebook': 'Iniciar Sesión con Facebook',
			'password recovery message was sent to your email': 'Un mensaje para recuperar su contraseña ha sido enviado a su correo electrónico',
			'home': 'Inicio',
			'departments': 'Departamentos',
			'scanner': 'Escáner',
			'all products': 'Productos',
			'orders': 'Ordenes',
			'sales': 'Ventas',
			'specials': 'Especiales',
			'sale': 'Venta',
			'edit': 'Editar',
			'search': 'Buscar',
			'total': 'Total',
			'checkout': 'Finalizar compra',
			'forgot your password?': 'Olvidó su contraseña?',
			'my shopping lists': 'Mis listas de compras',
			'remove': 'Eliminar',
			'add list': 'Añadir Lista',
			'name': 'Nombre',
			'creationdate': 'Fecha de creación',
			'itemscount': 'Contador de Artículos',
			'shopping list name': 'Nombre de ista de compras',
			'sorting by': 'Ordenado por',
			'add': 'Añadir',
			'add_product': 'Añadir',
			'close': 'Cerrar',
			'clean cart': 'Vaciar Carrito de Compras',
			'cart': 'Carrito',
			'recipient\'s name': 'Nombre del Destinatario',
			'email address': 'Dirección de correo electrónico',
			'the offer is valid from': 'Oferta válida desde',
			'until': 'Hasta',
			'terms and conditions': 'Términos y Condiciones',
			'this purchase order is an offer by the company identified on the face of this purchase order (the \'buyer\') for the purchase of the goods (the \'goods or services (the “services”) specified\')': 'Esta compra es una oferta de la compañía identificada en la cara de esta orden. (el \'Comprador\') para la compra de los productos (la \'Productos o Servicios (los “Servicios”) especificados\')_h',
			'pay with': 'Pagar con',
			'card number': 'Número de Tarjeta',
			'delivery address': 'Dirección de Envío',
			'add card number': 'Añadir número de tarjeta',
			'country': 'País',
			'zip code': 'Código Postal',
			'zipcode': 'Código Postal',
			'entrance': 'Entrada',
			'city': 'Ciudad',
			'street': 'Calle',
			'house number': 'Casa No.',
			'floor': 'Piso',
			'apartment': 'Apartamento',
			'available days': 'Días Disponibles',
			'entry: spautocompleterequirematch error': 'Entrada Inválida',
			'house_entry_none': 'Ninguno',
			'house_entry_a': 'a',
			'house_entry_b': 'b',
			'house_entry_c': 'c',
			'house_entry_d': 'd',
			'house_entry_e': 'e',
			'house_entry_f': 'f',
			'house_entry_g': 'g',
			'house_entry_h': 'h',
			'house_entry_i': 'i',
			'house_entry_j': 'j',
			'personal info': 'Información Personal',
			'first name': 'Nombre',
			'last name': 'Apellido',
			'full name': 'Nombre completo',
			'phone number': 'Número Telefónico',
			'delivery time': 'Tiempo de Envío',
			'day': 'Día',
			'time': 'Hora',
			'notes': 'Notas',
			'add instructions': 'Añadir Instrucciones',
			'summary': 'Resumen',
			'products': 'Productos',
			'delivery charge': 'Gastos de Envío',
			'ok': 'Ok',
			'cancel': 'Cancelar',
			'recent': 'Reciente',
			'purchases': 'Compras',
			'please enter street': 'Por favor ingrese la calle',
			'please enter email': 'Por favor ingrese su correo electrónico',
			'please enter password': 'Por favor ingrese la contraseña',
			'please enter first name': 'Por Favor ingrese el Nombre',
			'please enter last name': 'Por Favor Ingrese el Apellido',
			'please enter your email address': 'Por favor, ingrese su dirección de correo electrónico',
			'the shopping list was successfully added to the cart': 'La Lista de Compras fue añadida correctamente al carrito de compras',
			'callcustomer': 'Llamar al Cliente',
			'creditcard': 'Tarjeta de Crédito',
			'storecredit': 'Crédito de la Tienda',
			'between': 'Entre',
			'sunday': 'Domingo',
			'monday': 'Lunes',
			'tuesday': 'Martes',
			'wednesday': 'Miércoles',
			'thursday': 'Jueves',
			'friday': 'Viernes',
			'saturday': 'Sábado',
			'today': 'Hoy',
			'your order has been received': 'Su pedido ha sido recibido',
			'register': 'Registrarse',
			'creation date': 'Fecha de Creación',
			'items count': 'Contador de Artículos',
			'shop by': 'Comprar por',
			'my orders history': 'Mi historial de pedidos',
			'orders history': 'Historial de Pedidos',
			'previous orders': 'Pedidos anteriores',
			'number of provided products' : 'Número de productos proporcionados',
			'payment method ending with':'Método de pago que termina con',
			'credit card charge':'Cobrar con tarjeta de crédito',
			'order time':'Tiempo de pedido',
			'add to shopping list':'Agregar a la lista de compras',
			'order details':'Detalles del pedido',
			'payment information':'Información de pago',
			'view more':'Ver más',
			'replaced with':'Sustituido por',
			'new product':'Producto nuevo',
			'call customer':'Llama al cliente',
			'received_items': 'Recibido',
			'total (estimated)': 'Total(estimado)',
			'view details': 'Ver detalles',
			'account': 'Cuenta',
			'retailer details': 'Detalles de la Tienda',
			'more links': 'Más Enlaces',
			'link': 'Enlaces',
			'change language': 'Cambiar Idioma',
			'languages': 'Idiomas',
			'help': 'Ayuda',
			'settings': 'Ajustes',
			'personal details': 'Datos Personales',
			'birth date': 'Fecha de Nacimiento',
			'allow send promotions': 'Permitir envío de Promociones',
			'gender': 'Género',
			'male': 'Masculino',
			'female': 'Femenino',
			'prefer not to say': 'Prefiero no decirlo',
			'save': 'Guardar',
			'print': 'Imprimir',
			'addresses': 'Dirección',
			'logout': 'Cerrar Sesión',
			'info': 'Información',
			'policy': 'Políticas',
			'contact details': 'Detalles de Contacto',
			'continue without membership': 'Continuar sin membresía',
			'not a member yet? join now!': 'Todavía no es un miembro? Únete ahora!',
			'my cart': 'Mi Carrito de compras',
			'quantity': 'Cantidad',
			'price': 'Precio',
			'items': 'Artículos',
			'check out': 'Finalizar compra',
			'enter cvv': 'CVV',
			'add card': 'Añadir Tarjeta',
			'remove card': 'Eliminar Tarjeta',
			'thank you for your purchase': '¡Gracias por su Compra!',
			'loyalty club member': 'Miembro Club de Lealtad',
			'in this purchase': 'En esta Compra',
			'save_1': 'Guardar',
			'continue without loyalty': 'Continuar sin lealtad',
			'the delivery time is no longer available': 'El tiempo de entrega ya no está disponible',
			'finish': 'Finalizar',
			'gift total': 'Total de Regalos',
			'purchase type': 'Tipo de Compra',
			'end date': 'Fecha Final',
			'gift type': 'Tipo de Regalo',
			'confirm password': 'Confirmar Contraseña',
			'agree to receive updates': 'Aceptar recibir actualizaciones',
			'please let me know about promotions': 'Por favor, notifíquese de las promociones',
			'more': 'Más',
			'from my list': 'Desde mi lista',
			'import to cart': 'Importar al carrito de compras',
			'import products from cart?': '¿Desea importar productos desde el carrito de compras?',
			'nutrition facts': 'Información Nutricional',
			'details': 'Detalles',
			'add to cart': 'Añadir al carrito de compras',
			'view similar items': 'Ver artículos similares',
			'settings was successfully changed!': '¡La configuración se ha cambiado correctamente!',
			'sorry. we couldn\'t find this product': 'Lo sentimos. No pudimos encontrar este producto',
			'two passwords must match': 'Las dos contraseñas deben coincidir',
			'sign up completed': 'Registro finalizado',
			'product not found': 'Producto no encontrado',
			'please enter your password': 'Por Favor, ingrese su contraseña',
			'my shopping list': 'Mi lista de compras',
			'house no.': 'Casa No.',
			'from my cart': 'Desde mi carrito de compras',
			'est.': 'Est.',
			'case': 'Caso',
			'in store location': 'Ubicación en tienda',
			'units': 'Unidades',
			'unit': 'Unidad',
			'new shopping list was created': 'Se ha creado una nueva lista de compras',
			'please enter list name': 'Por Favor, Ingrese el nombre de la lista',
			'there are no products in cart': 'No hay productos en el carrito',
			'single': 'Individual',
			'pack': 'Paquete',
			'pck': 'Paquete',
			'cell phone': 'Teléfono Celular',
			'home phone': 'Teléfono de Casa',
			'work phone': 'Teléfono del Trabajo',
			'error': 'Error',
			'are you sure?': '¿Está usted seguro?',
			'phones': 'Teléfonos',
			'address': 'Dirección',
			'opening hours': 'Horario de Apertura',
			'user not found': 'Usuario no encontrado',
			'the email address doesn\'t have an associated account. please try again': 'La dirección de correo electrónico no tiene una cuenta asociada. Por Favor vuelva a intentarlo!',
			'please enter your email address to proceed': 'Por Favor ingrese su correo electrónico para continuar',
			'loyalty user not found': 'Usuario Fiel no encontrado',
			'branches': 'Sucursales',
			'login': 'Iniciar Sesión',
			'status': 'Estado',
			'time placed': 'Tiempo establecido',
			'i agree to receive promotions': 'Estoy de acuerdo con recibir promociones',
			'add to list': 'Añadir a la lista',
			'unauthorized action': 'Acción no autorizada',
			'first name is required': 'Se requiere el nombre',
			'last name is required': 'Se requiere el apellido',
			'email is required': 'Se requiere el correo electrónico',
			'email is not valid': 'Correo electrónico inválido',
			'password is required': 'Se requiere la contraseña',
			'phone number is required': 'Se requiere el número telefónico',
			'pickup location is required': 'Se requiere el lugar de entrega',
			'pickup day is required': 'Se requiere el día de entrega',
			'pickup hour is required': 'Se requiere la hora de entrega',
			'payment method is required': 'Se requiere el método de pago',
			'credit card is required': 'Se requiere tarjeta de crédito',
			'delivery day is required': 'Se requiere el día de envío',
			'password should be longer than 6 characters': 'La contraseña debe contener más de 6 caracteres',
			'new order': 'Nuevo pedido',
			'charged': 'Cobrado',
			'delivered': 'Enviado',
			'play': 'Tocar',
			'cancelled': 'Cancelado',
			'on hold': 'En Espera',
			'processing': 'En Proceso',
			'collected': 'Recogido',
			'registered': 'Registrado',
			'delete': 'Eliminado',
			'you must login first': 'Usted debe iniciar sesión primero',
			'not found shop lists': 'Listas de compras no encontrada',
			'product added to shop list': 'Producto añadido a la lista de compras',
			'yes': 'Si',
			'no': 'No',
			'brand': 'Marca',
			'new name': 'Nuevo Nombre',
			'invalid name': 'Nombre Inválido',
			'could not find your location': 'No se pudo encontrar su ubicación',
			'new list': 'Nueva Lista',
			'+add to list': 'Añadir a la lista',
			'your cart is still empty': 'Su carrito de compras permanece vacío',
			'in all departments': 'En todos los departamentos',
			'this is a pick and go order': 'Esta es una orden de Pick & Go',
			'start shopping': 'Empieza a comprar',
			'your order has been received but not charged': 'Su pedido ha sido recibido, pero no ha sido cobrado',
			'your credit card has been charged for your order': 'Su tarjeta de crédito ha sido cobrada por su pedido',
			'your order has been delivered and is on its way to you': 'Su pedido ha sido enviado y va en camino',
			'the order was cancelled for various reasons and is not being handled': 'El pedido ha sido cancelado por varios motivos y no está siendo preparado',
			'order could not be completed, customer intervention is required': 'El pedido no pudo completarse. Se requiere la intervención del cliente',
			'the order is currently being processed': 'El pedido se esta procesado en este momento',
			'the items have been collected but not yet registered in the pos': 'Los artículos han sido recogidos, pero aún no han sido registrados en los puntos de venta',
			'the products were collected and scanned, but the transaction was yet to be completed': 'Los productos fueron recogidos y escaneados, pero la operación aún no se ha completado',
			'product already exists': '#l producto ya existe',
			'approve & register': 'Aprobar y Registrar',
			'not approve': 'No Aprobado',
			'i agree to the': 'Estoy de acuerdo con la ',
			'terms & conditions': 'Términos y Condiciones',
			'loyalty Club terms & conditions': 'Club de fidelidad términos y condiciones',
			'policy_end': '',
			'privacy policy': 'Política de privacidad',
			'about us': 'Sobre nosotros',
			'track my order': 'Rastrear mi pedido',
			'order id:': 'Número de identificación del pedido',
			'no internet connection': 'No hay conexión a Internet',
			'please enable internet connection and retry': 'Por favor active la conexión a internet, e inténtelo de nuevo',
			'retry': 'Inténtelo de nuevo',
			'no branch': 'Sin sucursal',
			'lists': 'Listas',
			'street is required': 'Se requiere ingresar la calle',
			'house number is required': 'Se requiere ingresar el número de casa',
			'country is required': 'Se requiere colocar el país',
			'cvv is required': 'Se requiere el CVV',
			'connect': 'Conectar',
			'connect_loyalty': 'Connect',
			'not all of the products given where found': 'No todos los productos fueron encontrados',
			'not all of the given products were found': 'No se encontraron todos los productos indicados',
			'you have some missing products in your cart': 'Algunos productos faltan en el carrito de compras',
			'select method': 'Seleccionar método',
			'call me when there is missing item': 'Llámenos cuando falte algún artículo',
			'this email already exist': 'Este correo electrónico ya existe',
			'this email already exists': 'Estos correos electrónicos ya existen',
			'unable to create user': 'No se puede crear el usuario',
			'km': 'Km',
			'scanning failed: ': 'No se pudo escanear:',
			'scan barcode': 'Escanear el código de barra',
			'please point your camera at the barcode.': 'Por favor, apunte la cámara hacia el código de barras.',
			'i confirm that i have read the': 'Confirmo que he leído los ',
			'we are not able to add the following products to your current order': 'No podemos agregar los siguientes productos a su pedido actual',
			'terms': 'Términos',
			'payment by': 'Pago por',
			'phone': 'Teléfono',
			'payment': 'Pago',
			'shipping address': 'Dirección de envío',
			'payment method': 'Método de pago',
			'you save': 'Usted a ahorrado',
			'supervised': 'Supervisado',
			'floor /\ apt': 'Piso /\ Apto',
			'open': 'Abierto',
			'per lb': 'Por libra',
			'lb': 'Lb',
			'to see if we deliver to your area,': 'Comprobemos si hacemos envíos en su área',
			'click here': 'Haga click aquí',
			'we\'re available in': 'Estamos disponibles en',
			'checking delivery areas': 'Verificar áreas de envío',
			'loyalty club': 'Club de usuarios fieles',
			'have a club member?': '¿Es miembro del club?',
			'join our members club': 'Disfrute ser miembro del club',
			'join the club': 'Unirse al club',
			'connect to club': 'Conectarse al club',
			'member id': 'Identificación del miembro',
			'club id': 'Identificación del Club',
			'connect to your club account': 'Conectar a la cuenta del club',
			'choose': 'Escoger',
			'choose your delivery area': 'Escoger su área de envío',
			'delivery area': 'Áreas de envío',
			'enter zip code': 'Ingresar código postal',
			'mobile phone': 'Teléfono móvil',
			'personal id': 'Identificación personal',
			'loyalty card id': 'Identificación de la tarjeta de usuario fiel',
			'update club details': 'Actualizar detalles del club',
			'how do you want to get your shopping?': '¿Cómo desea obtener sus compras?',
			'home delivery': 'Envío a casa',
			'pickup': 'Recogida en tienda',
			'pick and go': 'Pick & Go',
			'pick & go': 'Pick & Go',
			'shipping details': 'Detalles de envío',
			'mobile': 'Móvil',
			'next': 'Siguiente',
			'choose delivery time': 'Elegir hora de envío',
			'shipping method & time': 'Método de envío y tiempo',
			'localdelivery': 'Local Delivery',
			'send the delivery sooner, if possible': 'Enviar la entrega antes, si es posible',
			'sooner if possible': 'Antes si es posible',
			'allow substitutions?': '¿Permite devoluciones?',
			'select card': 'Seleccionar tarjeta',
			'your order will be delivered': 'Su pedido será enviado',
			'you saved': 'Usted a ahorrado',
			'subtotal': 'Sub-total',
			'tax': 'Impuestos',
			'estimated': 'Estimado',
			'only': 'Solo',
			'contact details:': 'Detalles de Contacto',
			'place order': 'Realizar pedido',
			'place order using masterpass': 'Realizar pedido con masterpassMasterPass',
			'sending your order...': 'Enviando su pedido...',
			'please check your email for order details. thank you!': 'Por favor verifique su correo electrónico para los detalles del pedido. ¡Gracias!',
			'back to home screen': 'Volver a la plantalla de inicio',
			'back to top': 'Volver arriba',
			'questions? get in touch': '¿Preguntas? Contactactenos',
			'current location:': 'Ubicación actual',
			'pickup location': 'Lugar de la entrega',
			'click to choose pickup location': 'Haga click para seleccionar el lugar de entrega',
			'pickup time': 'Hora de entrega',
			'contact & payment details': 'Detalles de pago y contacto',
			'your order will be ready': 'Su pedido estará listo',
			'shipping': 'Envío',
			'delivery': 'Envío',
			'call me': 'Llámenos',
			'delivery_type_1': 'Delivery',
			'delivery_type_2': 'Pickup',
			'delivery_type_3': 'Pick And Go',
			'delivery_type_5': 'Express Delivery',
			'area_type_1': 'Delivery',
			'area_type_2': 'Delivery',
			'area_type_3': 'Delivery',
			'area_type_4': 'Delivery',
			'area_type_5': 'Pickup',
			'sun': 'dom',
			'mon': 'lun',
			'tue': 'mar',
			'wed': 'mie',
			'thu': 'jue',
			'fri': 'vie',
			'sat': 'sat',
			'the address you provided is not served at this time. please enter another address or choose pickup': 'La dirección propocionada no funciona en este momento. Por favor, ingrese otra dirección o elija retirar el pedido',
			'we\'re  sorry, but the address you provided is not served at this time': 'Lo siento, la dirección que proporcionó no funciona en este momento',
			'change to pickup': 'Cambiar a recoger',
			'can\'t find a product ?': 'No puede encontrar un producto?',
			'can\'t find a product?': 'No puede encontrar un producto?',
			'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call.': 'Si no estamos familiarizados con el producto o está agotado, le llamaremos',
			'product name': 'Nombre del producto',
			'select category': 'Seleccionar categoría',
			'clear cart': 'Limpiar carrito de compras',
			'clear cart?': '¿vaciar la cesta?',
			'are you sure you want to remove all products from your shopping cart?': '¿está seguro de que desea eliminar todos los productos de su cesta de compra?',
			'yes, clear cart': 'sí, vaciar la cesta',
			'menu': 'Menú',
			'customer id': 'Identificación del usuario',
			'congradulations for joining the customers club': 'Felicitaciones se ha unido al club de clientes',
			'your club member number': 'Su número de miembro del club',
			'loyalty member': 'Miembro de lealtad',
			'you have connected successfully': 'Te has conectado con éxito',
			'pay attention': 'Presta atención',
			'do you want to continue': 'Desea continuar',
			'continue without club discounts': 'Continuar sin descuentos club',
			'return and pay with loyalty credit card': 'Volver a elegir tarjeta de crédito del club',
			'paying with different payment method will discard {clubname} club discounts': 'Pagar con un método de pago diferente descartará los descuentos del club',
			'join to club': 'Únete al Club',
			'connect to club': 'Conectarse al club',
			'join': 'Unirse',
			'all the fields is required': 'Se requiere llenar todos los campos',
			'did you mean?': ' ¿Ested quiso decir?',
			'callcustomer_description': 'Le llamaremos para confirmar el pago',
			'creditcard_description': 'Choose or add a credit card',
			'pay_creditcard_description': 'You will be asked to pay with credit card at the end',
			'storecredit_description': 'Pay with your credit on file ID',
			'paypal_description': 'Pagar con PayPal',
			'icreditmasterpass_description': 'Pagar con cuenta MasterPass',
			'bit_description': 'Pagar con Bit',
			'select:': 'Seleccionar:',
			'select': 'Seleccionar',
			'edit comment': 'Editar comentario',
			'add comment': 'Añadir comentario',
			'add a comment': 'Añadir un comentatio',
			'write a comment': 'Escribir comentario',
			'type your comment here': 'Escribe tu comentario aquí',
			'address 1': 'Dirección 1',
			'to join': 'Unir',
			'join now': 'Únete ahora',
			'register now to join our membership club': 'Regístrese ahora para unirse a nuestro club',
			'reset code': 'Código de restablecimiento',
			'reset password': 'Restablecer contraseña',
			'password reset': 'Restablecer contraseña',
			'reset': 'Restablecer',
			'a password recovery code has been sent to your email': 'Un código de recuperación de contraseña ha sido enviado a su correo electrónico',
			'please check your inbox and follow the instructions': 'Por favor revise su bandeja de entrada y siga las instrucciones',
			'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'Si no ha recibido un correo electrónico, verifique si escribió mal su dirección de correo electrónico o comuníquese con el servicio al cliente',
			'est. weight': 'peso estimado',
			'please confirm that you accept the terms and conditions for using this service': 'Por favor, confirme que acepta los términos y condiciones para el uso de este servicio',
			'explanation': 'Explicación',
			'invalid cvv': 'CVV no es válido',
			'choose card': 'Elegir tarjeta',
			'invalid phone number': 'Numero de telefono invalido',
			'invalid city': 'Ciudad inválida',
			'invalid address': 'Dirección inválida',
			'invalid street': 'Calle inválida',
			'invalid address 1': 'Dirección 1 inválida',
			'invalid zip code': 'Código postal inválido',
			'products are no longer available': 'Los productos ya no están disponibles',
			'payments number': 'Número del pago',
			'online grocery service': 'Servicio de comestibles en línea',
			'download': 'Descargar',
			'get<br>the app': 'Conseguir<br>la aplicación',
			'branch not found': 'No se encontró la sucursal',
			'missing premise or street number': 'Número de calle faltante',
			'estimated tax': 'Impuestos estimados',
			'estimated total': 'Total estimado',
			'contact us': 'Contáctenos',
			'how can we help you?': '¿En qué podemos ayudarle?',
			'how can we help you today?': '¿Cómo podemos hoy ayudarle?',
			'submit': 'Enviar',
			'invalid message': 'El mensaje no es válido',
			'invalid email': 'Correo electrónico no es válido',
			'your message was sent successfully': 'Su mensaje fue enviado exitosamente',
			'we are sorry but this service is no longer available': 'Lo sentimos pero este servicio ya no está disponible',
			'continue with this cart': 'Continúe con este carrito de compras',
			'merge carts': 'Fusionar carritos de compras',
			'would you like to merge the two carts or continue using this cart': '¿Desea usted fusionar dos carritos de compras o prefiere continuar utilizando este carrito?',
			'somewhere else': 'en algún otro lugar',
			'you have an open cart with': 'Usted tiene un carrito abierto con ',
			'powered by': 'Impulsado por',
			'day0': 'dom',
			'day1': 'lun',
			'day2': 'mar',
			'day3': 'mier',
			'day4': 'jue',
			'day5': 'vie',
			'day6': 'sab',
			'to ': 'a',
			'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'El totl de su orden {{areaName}}no cumple con el {{minimum | currency}}',
			'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'Por favor agregue a su carrito artículos por valor {{difference | currency}} para proceder con el checkout',
			'not including items on sale': 'Sin incluir artículos en oferta',
			'please add additional products to the cart to proceed': 'Por favor, añadir productos adicionales al carrito de compras para continuar',

			'substitute_preference_1_label': 'Permitir',
			'substitute_preference_1_description': 'Reemplazar el producto que falta sin contactarle',
			'substitute_preference_2_label': 'Llamame',
			'substitute_preference_2_description': 'Lo llamaremos antes reemplazar el producto faltante',
			'substitute_preference_3_label': 'No Permitir',
			'substitute_preference_3_description': 'No vamos a reemplazar el producto faltante',
			'substitute_preference_4_label': 'Enviar Mensaje',
			'substitute_preference_4_description': 'Le enviaremos un mensaje de texto antes de reemplazar un producto',
			'substitute_preference_5_label': 'Enviar WhatsApp',
			'substitute_preference_5_description': 'Le enviaremos un WhatsApp mensaje antes de reemplazar un producto',
			'substitute_preference__label': 'substituto_preferencia__etiqueta',
			'substitute_preference__description': 'substituto_preferencia__descripción',
			'leave_by_the_door': "Por favor dejar el envio en la puerta si no hay nadie en casa",
			'sorry, currently we don\'t deliver to this address.': 'Lo sentimos, pero actualmente no hacemos envíos a esa dirección',
			'sorry, currently we don\'t deliver to this address, for further inquiries call to customer service.': 'Lo sentimos, pero actualmente no hacemos envíos a esa dirección, para más consultas llame al servicio al cliente.',
			'sorry, branch change required, this address could only be delivered from branch': 'Lo sentimos, se requiere un cambio de sucursal, esta dirección solo de puede envíar desde la sucursal',
			'please enter a more accurate address to continue': 'Ingrese una dirección más precisa para continuar',
			'switch branch': 'Cambiar sucursal',
			'try another address or choose pickup option': 'Intente otra dirección o cambie la opción de entrega',
			'delivery fee': 'Gastos de envío',
			'pickup fee': 'Tarifa de recogida',
			'service fee': 'Tarifa de servicio',
			'add a comment here:': 'Añadir un comentario aquí',
			'at': 'a las',
			'next delivery': 'Siguiente envío',
			'next pickup': 'Siguiente recogida',
			'get it within': 'Conseguirlo dentro',
			'the following products were not saved correctly due to an error': 'Los siguientes productos no se guardaron correctamente debido a un error',
			'hrs': 'hrs',
			'days': 'Días',
			'business days': 'Dias laborales',
			'delivery within': 'Entrega dentro',
			'within': 'dentro',
			'pickup within': 'Recogida dentro',
			'coupon number': 'Número de cupón',
			'coupon': 'Cupón',
			'please enter quantity': 'Por favor, ingrese la cantidad',
			'please enter a name with less than 200 characters': 'Por favor, ingrese un nombre con menos de 200 caracteres',
			'we didn\'t recognize the address you entered': 'No reconocemos la dirección que ingresó',
			'continue anyway': 'de todas maneras, continúe',
			'more details': 'Más detalles',
			'product description': 'Descripción del producto',
			'ingredients': 'Ingredientes',
			'more information': 'Más información',
			'directions': 'Instrucciones',
			'serving size': 'Tamaño de la porción',
			'servings per container': 'Porciones por envase',
			'amount per serving': 'Cantidad de porciones',
			'calories': 'Calorías',
			'calories from fat': 'Calorías provenientes de grasa',
			'daily value': 'Valor Diario',
			'total fat': 'Grasa total',
			'saturated fat': 'Grasas saturadas',
			'trans fat': 'Grasas trans',
			'cholesterol': 'Colesterol',
			'sodium': 'Sodio',
			'total carbohydrate': 'Total de carbohidratos',
			'dietary fiber': 'Fibra dietética',
			'sugars': 'Azúcar',
			'protein': 'Proteína',
			'vitamin a': 'Vitamina A',
			'vitamin c': 'Vitamina C',
			'calcium': 'Calcio',
			'iron': 'Hierro',
			'g': 'Gr',
			'monounsaturated fat': 'Grasas mono saturadas',
			'polyunsaturated fat': 'Grasas poli saturadas',
			'percent daily': 'Porcentaje diario',
			'welcome': 'Bienvenidos',
			'let\'s make sure we\'re available in your area': 'Permítanos asegurarnos de que estamos cerca de usted',
			'area': 'Área',
			'check': 'Verificar',
			'continue shopping': 'Seguir comprando',
			'change area': 'Cambiar área',
			'quantity limitation': 'Limitación en las cantidades',
			'is limited to ': 'Está limitado a',
			'items only': 'Artículos solamente',

			'our clubs specials': 'Ofertas de nuestro club',

			'similar items': 'Artículo similar',
			'nutrition': 'Nutrición',
			'description': 'Descripción',
			'and': 'y',
			'and the': 'y la',
			'currently we do not deliver to your area': 'Actualmente no entregamos en su área',
			'ticket_status_1': 'estado del boleto 1',
			'ticket_status_2': 'estado del boleto 2',
			'ticket_status_3': 'estado del boleto 3',
			'no previous inquiries': 'No hay pedidos anteriores',
			'join membership': 'Unirse al club',
			'join club': 'Unirse al club',
			'view club member details': 'Ver los detalles del servicio de lealtad',
			'thank you for signing up to {0} app': 'Gracias por suscribirse a la aplicación {0}',
			'join our loyalty club to benefit from exclusive promotions': 'Únase a nuestro club de usuarios fieles para obtener beneficios y promociones exclusivas',
			'connect to our loyalty club to benefit from exclusive promotions': 'Conéctese a nuestro club de usuarios fieles para obtener beneficios y promociones exclusivas',
			'membership number': 'Número de membrecía',
			'membership savings': 'Ahorros de membresía',
			'could save': 'podría ahorrar',
			'customer service': 'Servicio al cliente',
			'expiration date': 'Fecha de expiración',
			'comments': 'Comentarios',
			'send': 'Enviar',
			'credit cards': 'Tarjetas de crédito',
			'drag files or browse...': 'Arrastrar archivos o navegar...',
			'you are now connected to your membership club account': 'Te has conectado con éxito al club',
			'remove credit card info': 'Eliminar la información de la tarjeta de crédito',
			'are you sure you want to remove credit card information': 'Esta seguro que desea eliminar la información de la tarjeta de crédito?',
			'type in new request': 'Escriba una nueva solicitud',
			'by': 'por',
			'from': 'de',
			'read more': 'Lea más',
			'read less': 'Lea menos',
			'recipe': 'Receta',
			'video': 'Vídeo',
			'min': 'min',
			'invalid coupon code': 'Código de cupón inválido',
			'order status': 'Estatus del pedido',
			'view order': 'Ver pedido',
			'track order no.': 'Número de orden de seguimiento',
			'forgot something': '¿Olvidó algo',
			'update order': 'Actualizar pedido',
			'update order failed': 'Actualizar orden fallida',
			'order received': 'Orden recibida',
			'in process': 'En proceso',
			'ready': 'Listo',
			'received': 'Recibido',
			'need help': 'Necesita ayuda?',
			'edit order': 'Editar orden',
			'edit your order': 'Editar su orden',
			'your order': 'Su orden',
			'has been updated': 'Ha sido actualizado',
			'are you sure you want to cancel your order update and return to your original order': 'Está seguro que desea cancelar la actualización de su pedido y volver a su pedido original?',
			'do you want to remove / add items to your existing order': 'Quiere eliminar / agregar artículos a su pedido existente?',
			'note': 'Nota',
			'prices and specials will change according to the update date': 'Los precios y las ofertas especiales cambiarán según la fecha de actualización',
			'please check your email for order details': 'Por favor revise su correo electrónico para conocer los detalles del pedido',
			'thank you': 'Gracias',
			'we have started collecting your original order.': 'Hemos comenzado a recoger su pedido original.',
			'we have finished collecting your original order.': 'Hemos terminado de recoger su pedido original.',
			'this is a canceled order.': 'Este es un pedido cancelado',
			'your order cannot be changed at this time': 'Su pedido no se puede cambiar en este momento',
			'your order can no longer be changed': 'Su orden ya no se puede cambiar',
			'cancel update': 'Cancelar actualización',
			'canceling': 'Cancelada',
			'update': 'Actualizar',
			'replace shopping cart': 'Reemplazar carrito de compras',
			'are you sure you want to replace your shopping cart with the order from': 'Está seguro de que desea reemplzar su carrito de compras con el pedido de',
			'replace': 'reemplazar',
			'replace_2': 'Reemplazo',
			'you can still edit your order': 'Todavía puedes editar tu pedido',
			'your original order is': 'Tu orden original es',
			'cancel order update': 'Cancelar la actualización del pedido',
			'in process ': 'En proceso',
			'your cart is empty': 'Su carrito esta vacío',
			'continue as new cart': 'Continuar con un carrito nuevo',
			'you can move the selected items into a new order': 'Puede mover los productos seleccionados a una nueva orden',
			'my coupons': 'Mis cupones',
			'clip & add': 'Recortar y agregar',
			'clip': 'Agregar',
			'the site is undergoing maintenance': 'El sitio está en mantenimiento',
			'promotion details': 'Detalles de la promoción',
			'hide details': 'Ocultar detalles',
			'show details': 'Mostrar detalles',
			'the following products are not active, and were removed from the cart': 'Los siguientes productos no están activos y fueron removidos del carrito',
			'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'Una vez que terminemos de recolectar su orden, ajustamos el monto final basado en ajustes en pesos, sustituciones y productos no encontrados',
			'due to changes in address details': 'Debido a cambios en detalles de dirección',
			'please select your delivery time again': 'Por favor seleccione el tiempo de entrega nuevamente',
			'order': 'orden',
			'order id': 'Id de orden',
			'pack items': 'Articulos empacados',
			'how do you want to get': '¿Como desea recibirlo?',
			'cost': 'Costo',
			'express': 'Express',
			'express delivery': 'Envío Express',
			'no_deliveries_first_row': 'Disculpe, actualmente no',
			'no_deliveries_second_row': 'entregas en este día',
			'no_pickup_first_row': 'Disculpe, actualmente no',
			'no_pickup_second_row': 'recogidas en este día',
			'add your comment here': 'Agregue su comentario aquí',
			'substitutions': 'Substituciones',
			'before_checkout_delivery_title_1': 'Escoja',
			'before_checkout_delivery_title_2': 'el tiempo de envio deseado',
			'before_checkout_pickup_title_1': 'Recoja su orden',
			'before_checkout_pickup_title_2': 'de la tienda',
			'your credit cards': 'Su tarjeta de crédito',
			'add another card': 'Agregar otra tarjeta',
			'order update': 'Actualización del pedido',
			'updated order value': 'Valor del pedido actualizado',
			'(estimated)': '(estimado)',
			'in order to complete the order update, please choose the payment method': 'Para completar la actualización del pedido, por favor, elija un método de pago',
			'confirm update': 'CONFIRMAR ACTUALIZACIÓN',
			'add your credit/debit card': 'Añade tus tarjetas de crédito/débito',
			'use my delivery address as my billing address*': 'Usar mi dirección de entrega como mi dirección de facturación*',
			'*by checking this option you approve transfer of your address data to the payment provider': 'Al marcar esta opción, aprueba la transferencia de los datos de su dirección al proveedor de pago',
			'add another account': 'Agregar otra cuenta',
			'please enter a name': 'Por favor ingrese su nombre',
			'please enter a mobile phone': 'Por favor ingrese su número celular',
			'please enter an address': 'Por favor ingrese su dirección',
			'please enter house number': 'Porfavor ingrese su número de casa',
			'please enter city': 'Por favor ingrese ciudad',
			'please enter a correct phone number': 'Por favor ingrese un número celular correcto',
			'is an anti-fraud security feature to help verify that you are in possession of your credit card': 'CVV - Credit Validation Value, Es una forma de seguridad anti-fraude que permite verificar la posesión de la tarjeta',
			'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'Los 3 digitos CVV se encuentran impresos en el panel de firmas ubicado en la parte trasera justo después del numero de cuenta de la tarjeta',
			'enter your credit on file id': 'Ingrese Id de Crédito en el sistema',
			'checkout_title': 'Finalizar compra',
			'cart no.': 'Carrito no.',
			'pick_and_go_title': 'Pick & Go',
			'no need to wait in line': 'No hay necesidad de esperar en cola',
			'we will take care of your': 'nosotos nos encargamos de su',
			'cart and deliver': 'carrito y envío',
			'to begin, pick a': 'Para comenzar, escoja un',
			'barcode card': 'Tarjeta de codigo de barra',
			'start pick & go': 'Comenzar pick & go',
			'are you sure you want to stop the': 'está seguro que quiere detener el',
			'pick & go service': 'servicio pick & go',
			'stop pick & go': 'Detener pick & go',
			'please enter your credit on file id': 'Por favor ingrese su id de credito en sistema',
			'substitutions choice': 'Opciones de substitucion',
			'home page': 'Página de inicio',
			'thank you for': 'Gracias por',
			'shopping at {retailername}': 'comprar en {retailerName}',
            'an order summary email was sent to your inbox': 'Se ha enviado un correo electrónico de resumen de su pedido a su dirección de correo electrónico',
			'forgot': 'Olvidaste',
			'something': 'algo',
			'1 hours delivery': 'Envío en 1 hora',
			'2 hours delivery': 'Envío en 2 horas',
			'{hours} hours delivery': 'Envío en {hours} horas',
			'{minutes} minutes delivery': 'Envío en {minutes} minutos',
			'1 hour pickup': 'Recogida en 1 hora',
			'2 hour pickup': 'Recogida en 2 horas',
			'{hours} hour pickup': 'Recogida en {hours} horas',
			'hours': 'horas',
			'within': 'en',
			'minutes': 'minutos',
			'pickup time and address': 'Dirección y hora de recogida',
			'pickup details': 'Detalles de recogida',
			'your order will be ready for pickup': 'Su orden estará lista para recoger',
			'loading': 'Cargando',
			'paypal accounts': 'Cuentas de Paypal',
			'create new order': 'Crear nueva orden',
			'create new shopping list': 'Crear nueva lista de compras',
			'add cart products': 'Añadir productos al carrito',
			'create': 'Crear',
			'no shopping list created yet': 'No has creado listas de compras',
			'remove format': 'remover formato',
			'since your address was not detected': 'Debido a que su dirección no fue detectada',
			'please re-select a new delivery time': 'Por favor vuelva a seleccionar una hora de envío',
			'select delivery time': 'Seleccione tiempo de envío',
			'please try again': 'Por favor intentelo otra vez',
			'add a credit card': 'Agregar tarjeta de credito',
			'add paypal account': 'Agregar cuenta PayPal',
			'it seems you are editing your order on a different device.': 'Al parecer usted está editando su orden desde un dispositivo diferente',
			'note! it seems you have finished editing your order on a different device/browser.': 'Atención! Parece que finalizó su orden en un dispositivo/buscador diferente',
			'it seems you have finished your order on a different device.': 'Al parecer usted a finalizado su orden en un dispositivo diferente',
			'wee are currently updating this device too': 'Estamos actualizando este dispositivo en este momento',
			'sorry, no available delivery time slots': 'Diculpe, no hay ventanas de tiempo para envíos disponible',
			'sorry, no available pickup time slots': 'Disculpe, no hay ventanas de tiempo para recogida disponible',
			'today\'s special deals': 'Oferta especial del día',
			'loyalty membership expired': 'Membresia de lealtad ha expirado',
			'hello': 'Hola',
			'free': 'Gratis',
			'your membership has expired': 'Su membresía a expirado',
			'your club membership has expired on': 'Su membresía club exipiró el',
			'renew you membership and enjoy exclusive benefits and discounts': 'Renueve su membresía y disfrute de exclusivos descuentos y beneficios',
			'enter a valid number': 'Ingrese un número válido',
			'no thanks, continue shopping': 'No gracias, continúe su compra',
			'renew membership': 'Renovar membresía',
			'your membership has been renewed successfully': 'Su membresía ha sido renovada exitosamente',
			'you can enjoy from the club benefits in this order': 'Puede disfrutar de los beneficios del club en esta orden',
			'loyalty_mode_1_label': 'Miembro Existente',
			'loyalty_mode_2_label': 'Nuevo Miembro',
			'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon.png': 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon-hebrew.png',
			'please fill in membership number': 'Por favor ingrese su número de membresía',
			'{retailername} membership offers exclusive': ' {retailername} ofrece ofertas exclusivas',
			'promotions in-store and online': 'Promociones en tienda y online',
			'hi': 'Hola',
			'the items in your shopping cart will be cleared if you choose to edit your previous order': 'Los artículos en su carrito serán borrados si usted escoje editar tu orden anterior',
			'edit order & clear cart': 'Editar orden y borrar carrito',
			'it seems you are editing your order on a different device/browser.': 'Al parecer esta editando su orden en un dispositivo/buscador diferente',
			'it seems you have finished editing your order on a different device/browser.': 'Al parecer a terminado de editar su orden en un dispositivo/buscador diferente',
			'do you want to add the product to this new order or to the order that is being updated?': '¿Desea agregar este producto a una nuev orden o a la orden que está siendo actualizada?',
			'note! you in the middle of editing order': 'Atención! Esta en medio de editar la orden',
			'you in the middle of editing order. changing store is impossible': 'Usted esta en medio de editar su orden. Cambiar de tienda no es posible.',
			'add to new order': 'Agregar a nueva orden',
			'add to updated order': 'Agregar a orden actualizada',
			'we are not able to register your membership club account': 'No pudimos registrar su cuenta del club de membresía',
			'please make sure your details are correct or try again later': 'Por favor asegúrese que la informacion es correcta o intente luego nuevamente',
			'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/backgrounds/loyalty-titles.png': 'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/backgrounds/loyalty-titles.png',
			'special_exp': 'Termina a las',
			'special_exp_new': 'Termina a las',
			'ends': 'Termina',
			'forpackquantity': 'for',
			'verify_1': 'Por favor verifique',
			'verify_2': 'su edad',
			'verify_3': 'Debe tener al menos',
			'verify_4': 'años para comprar',
			'verify_5': 'este artículo',
			'verify_6': 'Soy',
			'verify_7': 'mayor',
			'verify_8': 'que',
			'verify_9': 'menor',
			'years': 'años',
			'sorry': 'Lo siento',
			'you cannot purchase': 'no puede comprar',
			'this item': 'este artículo',
			'please enter a valid email address': 'Ingrese una dirección de correro electrónico válida',
			'password must include at least 6 characters': 'La contraseña debe contener al menos 6 caracteres',
			'password should not be longer than 30 characters': 'La contraseña no debe contener mas de 30 caracteres',
			'password must contain at least one numeric digit and one character': 'La contraseña debe contener al menos un dígito numerico y un caracter',

			'let\'s start': 'Empezemos',
			'adding some items': 'a agregar artículos',

			'please notice': 'Por favor fíjese',
			'the expiration of these': 'en la fecha de expiración',
			'sales will not be valid for this chosen time': 'Ventas no serán validas para la hora seleccionada',
			'the special displayed below, will expire on the date you are supposed to receive your order': 'El especial que aparece abajo, expira en la fecha seleccionada para recibir la orden',
			'these items will be charged at their regular price': 'Estos artículs serán cobrados a su precio regular',
			'this product will be charged the regular price, promotion will not be applied': 'Este producto será cobrado a su precio regular, no aplican promociones',
			'price of updated cart (est.)': 'Precio del carrito actualizado (est.)',

			'general specials': 'Ofertas generales',
			'loyalty specials': 'Ofertas Club de Lealtad',
			'category': 'Categoria',
			'categories': 'Categorias',
			'special type': 'tipo especial',
			'clear all': 'Borrar todo',
			'filter by': 'Filtrar por',
			'sort by': 'Ordenar por',
			'type': 'Tipo',
			'our top picks': 'Productos Seleccionados',
			'membership deals': 'Ofertas para miembros',
			'it\'s easy!': 'Es fácil',
			'please sign in to continue...': 'Por favor inicie sesión para continuar...',
			'e-mail': 'Correo Electrónico',
			'sign in': 'Iniciar Sesión',
			'club member name': 'Nombre del miembro del club',
			'{clubname} special deals': 'Ofertas {clubname}',
			'membership_club': 'Club de Membresía',
			'join our membership and enjoy exclusive benefits and promotions': 'Suscríbase a nuestra membresía y disfrute de exclusivos beneficios y promociones',
			'identify': 'Identifica',
			'join our membership club': 'Suscríbase a nuestro club de membresía',
			'activate': 'Active',
			'please activate credit card {xxxx} in order to continue': 'Por favor active la tarjeta de crédito {xxxx} para poder continuar',
			'membership_title_register': 'Suscribirse',
			'membership_title_identify': 'Identificar',
			'membership_title_{clubname}': '{clubname}',
			'membership_{clubname}_title': '{clubname}',
			'to complete your purchase, join/connect to {clubname} membership account': 'Para completar su compra, Suscríbase a su cuenta de membresía {clubname}',
			'in order to complete your purchase, you must connect to the loyalty club': 'Para comlpetar su compra, usted debe conectar el club de lealtad',
			'stores info': 'Informacion de Tienda',
			'store info': 'Informacion de Tienda',
			'pickup address': 'Dirección de recogida',
			'select address': 'Seleccione dirección',
			'buy': 'Comprar',
			'please enter e-mail': 'Por favor ingrese correo electrónico',
			'menu_stores_info': 'Informacion de la tienda',
			'menu_store_info': 'Informacion de la tienda',
			'your order is being edited by another device': 'Su orden está siendo editada por otro dispositivo',
			'organization': 'Organización',
			'organization_description': 'Pago a crédito',
			'enter order confirmation no.': 'Ingrese el no. de confirmación de la orden',

			'icreditmasterpass': 'MasterPass',

			'friend\'s email': 'Correo electrónico de un amigo',
			'or share your promo code': 'o comparte el código promocional',
			'congratulations': 'Felicidades!',
			'shop now': 'Compra ahora',
			'copy': 'Copiar',
			'no, thanks': 'No, gracias',
			'got it': 'Entendido',
			'invitation sent successfully': 'Invitación enviada exitosamente',
			'promo code unidentified': 'Código promocional no identificado',
			'enter your promo code': 'Ingrese su código promocional',
			'there are no coupons currently available': 'No hay cupones disponibles en este momento',

			'express delivery is allowed only with products that contain express delivery tag': 'Envío Express está permitido solo con artículos que contienen la etiqueta de Envío Express',
			'express delivery products limit exceeded': 'Limite de productos de Envío Express exedido ',
			'this service is available for express items only. removing non express items from your cart will enable using this option': 'Este servicio solamente está disponible para artículos express, Al remover los productos que no son express se habilitara esta opcion',
			'express delivery is limited to': 'El Envío Express esta limitado a',
			'items. to enable this service, remove items from your cart': 'artículos. Para habilitar el servicio , remueva artículos del carrito',

			'buy_x_for_y': 'Comprar {{purchaseTotal}}',

			/*pele card errors*/
			'permitted transaction.': 'Transacción permitida.',
			'the card is blocked, confiscate it.': 'La tarjeta esta bloqueada, confísquela.',
			'the card is stolen, confiscate it.': 'La tarjeta fue robada, confísquela.',
			'contact the credit company.': 'Contacte a su banco.',
			'refusal by credit company.': 'Rechazado por el banco.',
			'the card is forged, confiscate it.': 'La tarjeta es falsa, confísquela.',
			'incorrect cvv/id.': 'CVV incorrecto.',
			'an error occurred while building access key for blocked card files.': 'Ocurrió un error mientras se obtenía la llave de acceso para los archivos bloqueados de la tarjeta.',
			'no communication. please try again or contact system administration': 'La comunicación falló. Por favor intente nuevamente o contacte al administrador del sistema',
			'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'El programa fue detenido por comando del usuario (Esc) o el puerto de com no puede abrirse (WINDOWS).',
			'the acquirer is not authorized for foreign currency transactions': 'El adquirente no está autorizada para transacciones en moneda extranjera.',
			'this card is not permitted for foreign currency transactions': 'Esta tarjeta no esta permitida para transacciones extranjeras.',
			'this card is not supported.': 'La tarjeta no tiene soporte.',
			'track 2 (magnetic) does not match the typed data.': 'track 2 (magnetic) no concuerda con la información ingresada.',
			'additional required data was entered/not entered as opposed to terminal settings (z field).': 'Información adicional requerida fué/ no fué ingresada a diferencia de la configuración de la terminal.',
			'last 4 digits were not entered (w field).': 'Los últimos 4 digitos no fueron ingresados.',
			'entry in int_in file is shorter than 16 characters.': ' La entrada en el archivo INT_IN es más corta que 16 carcteres.',
			'the input file (int_in) does not exist.': 'El archivo ingresado (IN_INT) no existe.',
			'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'Archivos de tarjeta bloqueados (NEG) no existen o no está actualizados, transmitir o pedir autorización para cada transacción.',
			'one of the parameter files/vectors does not exist.': 'Uno de los parametros archivos/vectores no existe.',
			'date file (data) does not exist.': 'Archivo de data (DATA) no existe .',
			'format file (start) does not exist.': 'Formato del archivo (START) no existe.',
			'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'La diferencia en días en los carritos bloqueados.',
			'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'La diferencia en generaciones y el ingreso de las tarjetas bloqueadas es muy grande, transmitir o pedir autorización para cada transacción.',
			'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'Cuando la cinta magnética no es ingresada completamente, definir la tansacción como un número de teléfono o firma solamente.',
			'the central terminal number was not entered into the defined main supplier terminal.': 'El número de terminal central no fue ingresado en la terminal definida del proveedor principal.',
			'the beneficiary number was not entered into the defined main beneficiary terminal.': 'E número de beneficiario no fue ingresado en la terminal definida el beneficiario principal .',
			'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'El número proveedor/beneficiario fue ingresado sin embargo, la terminal no se actualizó como principal proveedor / beneficiario.',
			'the beneficiary number was entered, however the terminal was updated as the main supplier': 'El número de beneficiario fue ingresado sin embargo, la terminal fue actualizada como el provedor principal.',
			'old transactions, transmit or request authorization for each transaction.': 'Transacciones antiguas, transmitir o pedir autorización por cada transacción.',
			'defective card.': 'Tarjeta de crédito inválida. Intente otra vez',
			'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'Esta tarjeta no está permitida en esta terminal o no está autorizada para este tipo de transacción.',
			'this card is not permitted for this transaction or type of credit.': 'Esta tarjeta no está permitida para esta transacción o tipo de crédito .',
			'expired card.': 'Tarjeta expirada.',
			'expired token': 'Token expirado',
			'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'Error de pago, la cantidad de transacciones debe ser igual a: primer pago más cuotas fijas multiplicado por el número de cuotas.',
			'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'Incapaz de ejecutar una transacción de débito que sea superior al límite máximo de la tarjeta de crédito..',
			'random where input file contains j1 (contact prohibited).': 'Aleatorio donde el archivo de entrada contiene j1 (contacto prohibido).',
			'the terminal is prohibited from requesting authorization without transaction (j5).': 'La terminal tiene prohibida solicitar autorización sin transacción (5J).',
			'the terminal is prohibited for supplier-initiated authorization request (j6).': 'La terminal está prohibida para solicitudes de autorización iniciadas por el proveedor (6J).',
			'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'La terminal debe solicitar autorización donde el archivo de entrada contiene j1, j2 o j3 (contacto prohibido).',
			'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': 'Se debe ingresar un código secreto donde el archivo de entrada contenga j1, j2 o j3 (contacto prohibido)',
			'incorrect vehicle number.': 'Nombre de vehículo incorrecto.',
			'the number of the distance meter was not entered.': 'No se ingresó el número del medidor de distancia.',
			'the terminal is not defined as gas station (petrol card or incorrect transaction code was used)': 'La terminal no está definida como gasolinera (se utilizó una tarjeta de gasolina o un código de transacción incorrecto).',
			'an id number is required (for israeli cards only) but was not entered.': 'Se requiere un número de identificación (solo para tarjetas israelíes) pero no se ingresó.',
			'cvv is required but was not entered.': 'Se requiere CVV pero no se ingresó.',
			'cvv and id number are required (for israeli cards only) but were not entered.': 'Se requieren el CVV y el número de identificación (solo para tarjetas israelíes) pero no se ingresaron.',
			'abs attachment was not found at the beginning of the input data in memory.': 'No se encontró adjunto abs al comienzo de los datos de entrada en la memoria.',
			'the card number was either not found or found twice.': 'El número de tarjeta no se encontró o se encontró dos veces.',
			'incorrect transaction type.': 'Tipo incorrecto de transacción.',
			'incorrect transaction code.': 'Código de transacción incorrecto.',
			'incorrect credit type.': 'Tipo de crédito incorrecto.',
			'incorrect currency.': 'Moneda incorrecta.',
			'the first installment and/or fixed payment are for non-installment type of credit.': 'La primera cuota y / o pago fijo son para el tipo de crédito sin cuotas.',
			'number of installments exist for the type of credit that does not require this.': 'Existe número de cuotas para el tipo de crédito que no requiere este.',
			'linkage to dollar or index is possible only for installment credit.': 'La vinculación al dólar o al índice solo es posible para el crédito a plazos.',
			'the magnetic strip is too short.': 'La banda magnética es demasiado corta.',
			'the pin code device is not defined.': 'El dispositivo de código pin no está definido.',
			'must enter the pin code number.': 'Debe ingresar el número de código pin.',
			'smart card reader not available - use the magnetic reader.': 'Lector de tarjetas inteligentes no disponible - use el lector magnético.',
			'must use the smart card reader.': 'Debe usar el lector de tarjetas inteligentes.',
			'denied - locked card.': 'Denegado - tarjeta bloqueada.',
			'denied - smart card reader action didn\'t end in the correct time.': 'Denegado: la acción del lector de tarjetas inteligentes no finalizó en el momento correcto.',
			'denied - data from smart card reader not defined in system.': 'Denegado: datos del lector de tarjetas inteligentes no definidos en el sistema.',
			'incorrect pin code.': 'Código pin incorrecto.',
			'currency does not exist in vector 59.': 'La moneda no existe en el vector 59.',
			'the club code entered does not match the credit type.': 'El código del club introducido no coincide con el tipo de crédito.',
			'cannot cancel charge transaction. please create a credit transaction.': 'No se puede cancelar la transacción de cargo. Por favor cree una transacción de crédito.',
			'unable to read/write/open the tran file.': 'incapaz de leer / escribir / abrir el archivo TRAN.',
			'no authorization from credit company for clearance.': 'Sin autorización de la compañía de crédito para la liquidación.',
			'the terminal is not permitted to send queries for immediate debit cards.': 'La terminal no puede enviar consultas para tarjetas de débito inmediatas.',
			'the transaction amount is too large, divide it into a number of transactions.': 'El monto de la transacción es demasiado grande, divídalo en varias transacciones.',
			'the terminal is not authorized to execute forced transactions.': 'La terminal no está autorizada para ejecutar transacciones forzadas.',
			'the terminal is not authorized for cards with service code 587.': 'La terminal no está autorizada para tarjetas con código de servicio 587.',
			'the terminal is not authorized for immediate debit cards.': 'La terminal no está autorizada para tarjetas de débito inmediatas.',
			'the terminal is not authorized for installment transactions.': 'La terminal no está autorizada para transacciones a plazos.',
			'the terminal is authorized for installment transactions only, not telephone/signature.': 'La terminal está autorizada para transacciones a plazos únicamente, no por teléfono / firma.',
			'the terminal is not authorized for telephone transactions.': 'La terminal no está autorizada para transacciones telefónicas.',
			'the terminal is not authorized for signature-only transactions.': 'La terminal no está autorizada para transacciones de solo firma.',
			'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': 'La terminal no está autorizada para transacciones en moneda extranjera, o la transacción no está autorizada.',
			'the terminal is not authorized for club transactions.': 'La terminal no está autorizada para transacciones de clubes.',
			'the terminal is not authorized for star/point/mile transactions.': 'La terminal no está autorizada para transacciones de estrellas / puntos / millas.',
			'the terminal is not authorized for isracredit credit.': 'La terminal no está autorizada para crédito isracredit.',
			'the terminal is not authorized for amex credit.': 'La terminal no está autorizada para crédito amex.',
			'the terminal is not authorized for dollar linkage.': 'La terminal no está autorizada para vinculación en dólares.',
			'the terminal is not authorized for index linkage.': 'La terminal no está autorizada para el enlace de índice.',
			'the terminal is not authorized for index linkage with foreign cards.': 'La terminal no está autorizada para la vinculación de índices con tarjetas extranjeras.',
			'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'La terminal no está autorizada para transacciones de estrella / punto / milla para este tipo de crédito.',
			'the terminal is not authorized for isra 36 credit.': 'La terminal no está autorizada para crédito isra 36.',
			'the terminal is not authorized for amex 36 credit.': 'La terminal no está autorizada para crédito amex 36.',
			'the terminal is not authorized for this club code.': 'La terminal no está autorizada para este código de club.',
			'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'La terminal no está autorizada para transacciones de débito inmediato (excepto para tarjetas de débito inmediato).',
			'the terminal is not authorized to accept visa card starting with 3.': 'La terminal no está autorizada a aceptar tarjetas visa a partir de 3.',
			'the terminal is not authorized to execute credit transactions above the ceiling.': 'La terminal no está autorizada para ejecutar transacciones de crédito por encima del límite.',
			'the card is not permitted to execute club transactions.': 'La tarjeta no puede ejecutar transacciones del club.',
			'the card is not permitted to execute star/point/mile transactions.': 'La tarjeta no puede ejecutar transacciones de estrellas / puntos / millas.',
			'the card is not permitted to execute dollar transactions (regular or telephone).': 'La tarjeta no puede ejecutar transacciones en dólares (regulares o telefónicas).',
			'the card is not valid according to isracard`s list of valid cards.': 'La tarjeta no es válida de acuerdo con la lista de tarjetas válidas de isracard.',
			'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'Tarjeta defectuosa según las definiciones del sistema (isracard vector1), error en el número de cifras de la tarjeta.',
			'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'la tarjeta no puede ejecutar transacciones en dólares de acuerdo con las definiciones del sistema (isracard vector1).',
			'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'La tarjeta pertenece a un grupo al que no se le permite ejecutar transacciones de acuerdo con las definiciones del sistema (vector visa 20).',
			'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'El prefijo de la tarjeta (7 cifras) no es válido según las definiciones del sistema (diners vector21).',
			'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'La tarjeta no puede realizar transacciones a plazos de acuerdo con la lista de tarjetas válidas de isracard.',
			'the number of installments is too large according to isracard`s list of valid cards.': 'El número de cuotas es demasiado grande según la lista de tarjetas válidas de isracard.',
			'visa and diners cards are not permitted for club installment transactions.': 'Las tarjetas Visa y Diners no están permitidas para transacciones a plazos del club.',
			'series of cards are not valid according to system definition (isracard vector5).': 'Las series de tarjetas no son válidas según la definición del sistema (Isracard vector5).',
			'invalid service code according to system definitions (isracard vector6).': 'Código de servicio no válido según las definiciones del sistema (Isracard vector6).',
			'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'el prefijo de la tarjeta (2 cifras) no es válido según las definiciones del sistema (isracard vector7).',
			'invalid service code according to system definitions (visa vector12).': 'Código de servicio no válido según las definiciones del sistema (visa vector12).',
			'invalid service code according to system definitions (visa vector13).': 'Código de servicio no válido según las definiciones del sistema (visa vector13).',
			'immediate debit card is prohibited for executing credit transaction.': 'La tarjeta de débito inmediata está prohibida para ejecutar transacciones de crédito..',
			'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'La tarjeta no puede ejecutar transacciones a plazos según el vector alfa no. 31.',
			'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.',
			'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'La tarjeta no está permitida para transacciones telefónicas según el vector alfa no. 31.',
			'credit is not approved for immediate debit cards.': 'El crédito no está aprobado para tarjetas de débito inmediatas.',
			'credit is not approved for foreign cards.': 'El crédito no está aprobado para tarjetas extranjeras.',
			'incorrect club code.': 'Código de club incorrecto.',
			'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'La tarjeta no puede ejecutar transacciones de crédito flexibles (adif / 30 +) según las definiciones del sistema (diners vector21).',
			'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'La tarjeta no puede ejecutar transacciones de débito inmediatas de acuerdo con las definiciones del sistema (diners vector21)',
			'the payment amount is too low for credit transactions.': 'El monto del pago es demasiado bajo para transacciones de crédito.',
			'incorrect number of installments for credit transaction.': 'Número incorrecto de cuotas para la transacción de crédito.',
			'zero ceiling for this type of card for regular credit or credit transaction.': 'Límite cero para este tipo de tarjeta para crédito regular o transacción de crédito.',
			'zero ceiling for this type of card for immediate debit credit transaction.': 'Límite cero para este tipo de tarjeta para transacciones de crédito de débito inmediato.',
			'zero ceiling for this type of card for immediate debit in dollars.': 'Límite cero para este tipo de tarjeta para débito inmediato en dólares.',
			'zero ceiling for this type of card for telephone transaction.': 'Límite cero para este tipo de tarjeta para transacciones telefónicas.',
			'zero ceiling for this type of card for credit transaction.': 'Límite cero para este tipo de tarjeta para transacciones de crédito.',
			'zero ceiling for this type of card for installment transaction.': 'Límite  cero para este tipo de tarjeta para la transacción a plazos.',
			'american express card issued abroad not permitted for instalments transaction.': 'Tarjeta american express emitida en el extranjero no permitida para transacciones a plazos.',
			'jcb cards are only permitted to carry out regular credit transactions.': 'Las tarjetas jcb solo pueden realizar transacciones de crédito regulares..',
			'the amount in stars/points/miles is higher than the transaction amount.': 'La cantidad en estrellas / puntos / millas es mayor que la cantidad de la transacción.',
			'the club card is not within terminal range.': 'La tarjeta del club no está dentro del alcance de la terminal.',
			'star/point/mile transactions cannot be executed.': 'Las transacciones de estrella / punto / milla no se pueden ejecutar.',
			'dollar transactions cannot be executed for this type of card.': 'No se pueden ejecutar transacciones en dólares para este tipo de tarjeta.',
			'credit transactions cannot be executed with other than regular credit.': 'Las transacciones de crédito no se pueden ejecutar con otro crédito que no sea regular.',
			'amount of discount on stars/points/miles is higher than the permitted.': 'La cantidad de descuento en estrellas / puntos / millas es mayor que la permitida.',
			'forced transactions cannot be executed with credit/immediate debit card.': 'Las transacciones forzadas no se pueden ejecutar con tarjeta de crédito / débito inmediato.',
			'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'La transacción anterior no se puede cancelar (la transacción de crédito o el número de tarjeta no son idénticos).',
			'double transaction.': 'Transacción doble.',
			'the terminal is not permitted for index linkage of this type of credit.': 'La terminal no está permitida para la vinculación de índices de este tipo de crédito.',
			'the terminal is not permitted for dollar linkage of this type of credit.': 'La terminal no está permitida para la vinculación en dólares de este tipo de crédito.',
			'the card is invalid according to system definitions (isracard vector1).': 'La tarjeta no es válida según las definiciones del sistema (isracard vector1).',
			'unable to execute the self-service transaction if the gas station does not have self service.': 'Incapaz de ejecutar la transacción de autoservicio si la estación de servicio no tiene autoservicio.',
			'credit transactions are forbidden with stars/points/miles.': 'Las transacciones de crédito están prohibidas con estrellas / puntos / millas.',
			'dollar credit transactions are forbidden on tourist cards.': 'Las transacciones de crédito en dólares están prohibidas en tarjetas turísticas.',
			'phone transactions are not permitted on club cards.': 'No se permiten transacciones telefónicas con tarjetas de club.',
			'application error.': 'Error de la aplicación.',
			'transaction amount missing or zero.': 'El monto de la transacción falta o es cero.',
			'no communication to pelecard.': 'Sin comunicación con pelecard.',
			'doubled transaction.': 'Transacción duplicada.',
			'terminal number does not exist.': 'El número de terminal no existe.',
			'terminal executes broadcast and/or updating data. please try again later.': 'La terminal ejecuta la transmisión y / o actualización de datos. Por favor, inténtelo de nuevo más tarde.',
			'user name and/or password not correct. please call support team.': 'Nombre de usuario y / o contraseña incorrectos. por favor llame al equipo de soporte.',
			'user password has expired. please contact support team.': 'La contraseña de usuario ha expirado. por favor contacte al equipo de soporte.',
			'please enter confirm password': 'Por favor ingrese confirmar contraseña',
			'the passwords must match': 'Las contraseñas deben coincidir',
			'locked user. please contact support team.': 'Usuario bloqueado. Por favor contacte al equipo de soporte.',
			'blocked terminal. please contact account team.': 'Terminal bloqueada. Por favor contacte al equipo de cuentas.',
			'token number abnormal.': 'Número de token anormal.',
			'user is not authorized in this terminal.': 'El usuario no está autorizado en este terminal.',
			'validity structure invalid. use mmyy structure only.': 'Estructura de validez inválida. usar la estructura MMYY solamente .',
			'ssl verifying access is blocked. please contact support team.': 'El ssl acceso está bloqueado. Por favor contacte al equipo de soporte.',
			'data not exist.': 'Los datos no existen.',
			'general error. please contact support team.': 'Error general. Por favor contacte al equipo de soporte.',
			'necessary values are blocked/wrong.': 'Los valores necesarios están bloqueados / incorrectos.',
			'general error. repeat action.': 'Error general. Repetir la acción.',
			'necessary values missing to complete installments transaction': 'Faltan los valores necesarios para completar la transacción de cuotas.',
			/*end pele card errors*/

			'card not enrolled': 'Error tarjeta no registrada',

			'removed': 'Removido',

			'type in zip code': 'Escriba el código postal',
			'type in city': 'Escriba en la ciudad',
			'type your address': 'Escriba su dirección',
			'or choose branch for pickup': 'o elija una sucursal para recoger',
			'pickup the order from one of the branches at your time': 'Recoger el pedido de una de las sucursales en su momento',
			'we serve your area': 'Nosotros servimos a su area',
			'pickup service only': 'Solo servicio de recogida',
			'<< back': '<< Atrás',
			'limited to {deliveryitemslimit} products': 'Limitado a {deliveryitemslimit} productos',
			'limited to products marked with "{tagname}" tag only': 'Limitado a productos marcados solo con la etiqueta "{tagname}"',
			'has no items limit': 'No tiene límite de artículos',
			'choose the pickup location': 'Elegir el lugar de recogida',
			'products limit': 'Límite de productos',
			'this delivery has a limit of {deliveryitemslimit} products': 'Esta entrega tiene un límite de {deliveryitemslimit}productos',
			'if you wish to add another product, remove one from the cart first': 'Si desea agregar otro producto, primero retire uno del carrito',
			'continue': 'Seguir',
			'or change the delivery method': 'o cambiar el método de entrega',
			'change delivery method': 'Cambiar el método de entrega',
			'are you sure you want to change the delivery type': 'Estás seguro que desea cambiar el tipo de envío?',
			'changes in delivery times may apply': 'Pueden aplicarse cambios en los tiempos de entrega',
			'changes in inventory between the stores may apply': 'Pueden aplicarse cambios en el inventario entre tiendas',
			'yes, change delivery method': 'Sí, cambie el método de entrega',
			'no, stay in current delivery': 'No, permanecer en el método de entrega actual',
			'unfortunately we didn\'t find any stores in this area': 'Lamentablemente no encontramos ninguna tienda en esta área',
			'try a different area name': 'Pruebe con un nombre de área diferente',
			'please type a valid address': 'Por favor escriba una direción válida',
			'please add street and house number': 'Por favor agregue la calle y el número de casa',
			'delivery method in this area': 'Método de entrega en esta área',
			'apologies': 'Disculpas',
			'sorry, we do not deliver to your area': 'Lo siento, no entregamos a su área',
			'please type in your email and we will let you know when we expand to your location': 'Por favor escriba su correo electrónico y le avisaremos cuando nos expandamos a su localización',
			'try a different address': 'Pruebe una dirección diferente',
			'try a different city': 'Pruebe una ciudad diferente',
			'try a different zip code': 'Pruebe un código postal diferente',
			'keep in touch': 'Mantengámonos en contacto',
			'your chosen delivery type is': 'Su tipo de entrega elegido es',
			'change to ': 'Cambiar a',
			'branch': 'Sucursal',
			'from branch': 'de la sucursal',
			'delivery products limit exceeded': 'Límite de productos de entrega excedido',
			'see you soon': 'Nos vemos pronto',
			'the order will wait for you at the store': 'El pedido te esperará en la tienda',
			'nagish_close_component': 'Hide',
			'chametz': 'Chametz',
			'passoverkosher': 'Kosher para Pesach',
			'passoverkoshertemporarily': 'Temporalmente Kosherpara Pesach',
			'passoverkitniyotkosher': 'Kosher for Passover (Kitniyot)',
			'passoverkitniyotkoshertemporarily': 'Temporarily Kosher for Passover (Kitniyot)',
			'meat': 'Kosher Fleischig',
			'milky': 'Kosher Milchig',
			'pareve': 'Kosher Pareve',
			'israelmilk': 'Cholov Yisroel',
			'israelcooking': 'Bishul Yisroel',
			'sabbathobservingfactory': 'Sabbath Observing Factory',
			'noshviitconcern': 'No Shviit Concern',
			'noorlaconcern': 'No Orla Concern',
			'notevelconcern': 'No Tevel Concern',
			'noovernightconcern': 'Sin preocupaciones durante la noche',
			'nogeniza': 'No Geniza',
			'recipes and allergies': 'Recetas y Alergias',
			'list of ingredients': 'Lista de ingredientes',
			'allergies contained': 'Alergias contenidas',
			'possible allergies': 'Posibles alergias',
			'about the product': 'Sobre el producto',
			'extra info and instructions': 'Información e instrucciones adicionales',
			'supervisions': 'Supervisiones',
			'usage warnings': 'Advertencias de uso',
			'storage instructions': 'Instrucciones de almacenamiento.',
			'usage instructions': 'Instrucciones de uso',
			'fat percentage': 'Porcentaje de grasa',
			'cream percentage': 'Porcentaje de crema',
			'fruit percentage': 'Porcentaje de fruta',
			'alcohol percentage': 'Porcentaje de alcohol',
			'ph': 'ph',
			'country of origin': 'País de origen',
			'date taken into effect': 'Fecha de entrada en vigor',
			'height': 'Altura',
			'depth': 'Profundidad',
			'width': 'Anchura',
			'net width': 'Anchura promedio',
			'gross width': 'Anchura total',
			'general information': 'Información general',
			'nutrition values': 'Valores nutricionales',
			'the waiting period will not exceed two hours': 'Tiempo de entrega no excederá las dos horas',
			'at_hour': 'a las',
			'special_reminder_bubble_title': 'Ahorra!',
			'special_reminder_awarded_bubble_title': 'Ahorraste!',
			'special_reminder_button_title': 'Presione y ahorre!',
			'add products to earn this promotion': 'Agregar productos para ganar esta promoción',
			'done': 'Listo!',
			'select your benefit': 'Seleccione su beneficion',
			'show more specials': 'Mostrar más especiales',
			'show less specials': 'Mostrar menos especiales',
			'coupon_requirements_error': 'You haven\'t met the requirements',
			'add products': 'Agregar productos',
			'remaining credit': 'Crédito restante',
			'budget': 'Presupuesto',
			'cart amount higher': 'Cantidad en el carrio mayor',
			'than your credit': 'que tu crédito',
			'edit cart': 'editar carrito',
			'cart total': 'total del carrito',
			'to complete the purchase, remove products from the cart valued at': 'Para completar la compra, retire productos del carrito valorado en',
			'this user is not set as credit customer': 'Este usuario no está configuradocomo cliente de crédito',
			'cart amount higher than your credit. to proceed please remove at least': 'El monto del carrito es mayor que su crédito. Para continuar, elimine al menos',
			'please verify that you are not a robot': 'Por favor verifique que no es un robot',
			'all conditions must be marked in order to complete the order': 'Todas las condiciones deben estar marcadas para completar el pedido',
			'similar products': 'Productos similares',
			'additional special\'s variety': 'Variedad especial adicional',
			'select from variety': 'Seleccionar de la variedad',
			'product info disclaimer': 'Información sobre el producto o envase mostrados pueden no estar actualizados o completos. Siempre consulte el producto físico para la información más precisa y advertencias. Para obtener información adicional, póngase en contacto con el vendedor o fabricante.',
			'employee discount': 'Descuento para empleados',
			'card id': 'Tarjeta de identificación',
			'discount': 'Descuento',
			'remaining obligo': 'Obligo restante',
			'monthly obligo': 'Obligo mensual',
			'club name': 'Nombre del club',
			'obligo type': 'Tipo de obligo',
			'monthly discount obligo': 'Descuento del mes',
			'cardid: required error': 'Tarjeta de identificación: error requerido',
			'pin: required error': 'Pin: error requerido',
			'employee discount card holder?': 'Titular de la tarjeta de descuento para empleados?',
			'employee discount card data': 'Datos de la tarjeta de descuento para empleados',
			'employee discount account not found': 'Cuenta de descuento para empleados no encontrada',
			'estimated employee savings': 'Ahorro estimado de los empleados',
			'unlimited': 'Ilimitado',
			'getting data...': 'Obteniendo datos...',
			'store is filtered': 'La tienda esta filtrada',
			'what\'s your lifestyle?': 'Cual es tu estilo de vida?',
			'filter entire store': 'Filtrar toda la tienda',
			'filter the entire store according to your preferred lifestyle or diet!': 'Filtre toda la tienda de acuerdo con su estilo de vida o dieta preferidos!',
			'filtering store': 'Filtrar tienda',
			'the entire store is now filtered by': 'Toda la tienda ahora está filtrada por',
			'clear all filters': 'Borrar todos los filtros',
			'back to filters': 'Volver a filtros',
			'all filters': 'Todos los filtros',
			'filters': 'Filtros',
			'show more': 'Mostrar más',
			'show less': 'Mostrar menos',
			'permanent_filters_legal_text': 'Al utilizar las diversas opciones de filtrado que se ofrecen en el sitio, la compañia no garantiza que no habrá mal funcionamiento y/o errores en el sitio, incluso en el desempeño del filtrado en particular. La compañia no se compromete a que la categorización de productos sea precisa y excluya productos que no cumplan con los criterios requeridos ni que todos los productos presentados cumplan con los criterios. El uso de información obtenida es de su exclusiva responsabilidad, incluso con respecto a cualquier daño causado como resultado del uso de la información o la confianza en ella. Cada producto, incluido sus componentes, debe probarse individualmente. Usted será responsable de verificar cualquier información con los datos del fabricante para ese producto y, en caso de discrepancia entre la información presentada en el sitio y la información presentada por el fabricante, se debe seguir la información presentada por el fabricante. Lo anterior no creará responsabilidad, ya sea por daños indirectos o directos, de la compañía en relación con la información mostrada por el fabricante de cualquier producto. ',
			'we couldn\'t find any products': 'No se ha encontrado ningún productos',
			'we couldn\'t find any specials': 'No se ha encontrado ningún especial',
			'try editing your filters': 'Intente editar sus filtros',
			'clear_all_filters_2': 'Borrar todos los filtros',
			'or': 'o',
			'edit global filters': 'Editar filtros globales',
			'verify account': 'Verificar cuenta',
			'you are now registered': 'Ahora estás registrado',
			'there was a problem verifying your account': 'Hubo un problema al verificar su cuenta',
			'please contact customer service': 'Por favor póngase en contacto con el servicio al cliente',
			'please verify your account': 'Por favor, verifique su cuenta',
			'before your first purchase, a verification code will be sent to your mobile phone': 'Antes de su primera compra, se enviará un código de verificación a su teléfono móvil',
			'please enter your phone number': 'Por favor, introduzca su número de teléfono',
			'send sms': 'Enviar mensaje',
			'enter the code that was sent to your phone': 'Ingrese el código que se le envío a su teléfono',
			'change number': 'Cambiar número',
			'verify': 'Verificar',
			'i didn\'t receive the code (resend)': 'No recibí el código (reenviar)',
			'the code is invalid or expired': 'El código no es válido o venció',
			'your account is verified': 'Su cuenta está verificada',
			'invalid verification code': 'Código de verificación invalido',
			'loyalty club details': 'Detalles del club de fidelidad',
			'id': 'Documento de identidad',
			'loyalty terms': 'Términos de fidelidad',
			'please enter id': 'Por favor ingrese id',
			'invalid mobile phone': 'Teléfono móvil inválido',
			'please confirm that you accept the terms': 'Por favor confirme que acepta los términos',
			'member number': 'Número de miembro',
			'you have successfully registered': 'Se ha registrado exitosamente',
			'for the customer club': 'Para el club de miembros',
			'for the customer club and the website': 'Para el club de miembros y el sitio web',
			'website registration': 'Registro del sitio web',
			'please enter a 9-digit number': 'Por favor ingrese un código de 9 dígitos',
			'only digits should be typed': 'Solo se deben escribir dígitos',
			'please let me know about promotions on site and club': 'Por favor avíseme sobre las promociones en el sitio y el club',
			'organization management': 'Gestión de la organización',
			'payment by organization credit': 'Pago mediante crédito de la organización',
			'sorry, your organization account is inactive': 'Lo sentimos, su cuenta de la organización está inactiva',
			'sorry, there are no branches assigned to this organization': 'Lo sienot, no hay sucursales asignadas a esta organización',
			'please contact your organization admin': 'Por favor contacte al administrador de su organización',
			'credit cards can not be added. you are assigned to an organization': 'No se pueden agregar tarjetas de crédito. Estás asignado a una organización',
			'cancel order': 'Cancelar orden',
			'are you sure you want to cancel this order': 'Está seguro que desea cancelar este pedido?',
			'order #': 'Número de orden',
			'keep the order': 'Mantener la orden',
			'your order was cancelled': 'Tu pedido fue cancelado',
			'this order will not be charged': 'Este pedido no se cobrará',
			'your payment method will not be charged': 'Su método de pago no se cobrará',
			'a confirmation email was sent to {email}': 'Se envió un correo electrónico de confirmación a {email}',
			'no available deliveries in the upcoming days': 'No hay entregas disponibles en los próximos días',
			'required error': '{{Nombre}} es requerido',
			'no data on this order': 'No hay datos sobre este pedido',
			'payment failed': 'Pago fallido',
			'back to payment': 'Volver al pago',
			'remove item from cart': 'Eliminar artículo del carrito',
			'you either remove the product from the cart or change the delivery area': 'Retire el producto del carrito o cambie el área de entrega',
			'or change the delivery area': 'o cambie el área de entrega',
			'we will contact you in order to schedule the delivery hours': 'Nos pondremos en contacto para programar los horarios de entrega',
			'delivery price': 'Precio de entrega',
			'ordered': 'Ordenado',
			'address_warning_enter_house_num': 'Por favor ingrese su número de casa',
			'so we can assign you to the right branch': 'para que podamos asignarle la súcursal corecta',
			'items to cart': 'articulos al carrito',

			'your products from a website': 'Sus productos desde un sitio web',
			'inventory availability may vary according to the delivery area': 'La disponibilidad de inventario puede variar según el área de entrega',
			'please note - we could not identify {number}': 'Tenga en cuenta que no pudimos identificar {número}',
			'more products': 'Productos adicionales',
			'more product': 'Producto adicional',
			'from the {name} website': 'del sitio web de {nombre}',
			'out of stock': 'Agotado',
			'added to cart': 'Añadido al carrito',
			'finish & go to cart': 'Terminar e ir al carrito',
			'the site is active only for organization users': 'El sitio está activo solo para usuarios de la organización',
			'please login': 'Por favor iniciar sesión',
			'please login or register': 'Por favor inicie sesión o regístrese',
			'sorry, this website is for organization users only': 'Lo siento, este sitio web es solo para usuarios de la organización',
			'you user is not assigned to an organization': 'El usuario no está asignado a la organización',
			'sorry, you can not register with this email address': 'Lo siento, no puedes registrarte con esta dirección de correo electrónico',
			'send again': 'Enviar de nuevo',
			'didn\'t receive an email': 'No recibiste un correo electrónico',
			'user email address not found': 'Correo electrónico del usuario no encontrada',
			'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'Se enviará un correo electrónico de verificación a su casilla de correo electrónico. El enlace es válido por {link_ttl} minutos',
			'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'Su cuenta requiere activación, un correo electrónico con el enlace de activación sera enviado. El enlace es válido por {link_ttl} minutos.',
            'your account requires activation.': 'Su cuenta requiere activación.',
            'email with activation link have been sent to your email.': 'Un correo electrónico con el enlace de activación sera enviado.',
            'the link is valid for {link_ttl} minutes': 'El enlace es válido por {link_ttl} minutos.',
			'activation code expired': 'El código de activación venció',
			'website login': 'Inicio de sesión del sitio web',
			'send a new link': 'Enviar nuevo enlace',
			'sorry, this link has expired': 'Lo siento, este enlace ha caducado',
			'a new link can be sent': 'Se puede enviar un nuevo enlace',
			'you have successfully registered for the site': 'Se ha registrado con éxito en el sitio',
			'this account has already been verified': 'Esta cuenta ya ha sido verificada',
			'you may enter the trade site': 'Puede ingresar al sitio comercial',
			'this account is an organizational account. you may need to verify it similarly from time to time': 'Esta cuenta es una cuenta de la organización. Es posible que deba verificarla de vez en cuando',
			'please verify your identity': 'Por favor verifique su identidad',
			'to verify your identity, please enter a phone number': 'Para verificar su identidad, ingrese un número de telefono',
			'continue to registration': 'Continuar con el registro',
			'phone number does not match': 'El número de télefono no coincide',
			'user invitation not found': 'Invitaciíon de usuario no encontrada',
			'user already registered': 'Usuario ya registrado(a)',
			'phone number hint': 'pista de número de teléfono',
			'account not verified': 'Cuenta no verificada',
			'sorry, there was an authentication error': 'Lo siento, hubo un error de autenticación',
			'please try again or contact the person in charge of the organization': 'Inténtelo de nuevo o póngase en contacto con la persona a cargo de la organización',
			'this account is an organizational account. you account verification has expired': 'Esta cuenta es una cuenta de organización. La verificación de su cuenta ha expirado',
			'account verification succeed': 'Verificación de cuenta exitosa',
			'pickandgo time': 'Tiempo de Pick & Go',
			'floor /apt': 'piso/apartamento',
			'home screen': 'pantalla de inicio',
			'can\'t find a product': 'no puede encontrar el producto',
			'items in cart must be a total of {price} or more': 'los artículos en el carrito debe ser por un total de {price}, o más',
			'delivery cost': 'costo de envío',
			'delivery cost tax': 'impuestos de envío',

			'this user may already be on the system': 'This user may already be on the system',
			'please try to login': 'Please try to login',
			'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'Your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox',

			'user products list not found': 'No se encontró la lista de productos del usuario',
			'user products list is disabled for this retailer': 'La lista de productos del usuario no está habilitada para este tienda',
			'my lists': 'Mis listas',
			'my products': 'Mis productos',
			'my products list': 'Mi lista de productos',
			'products i usually purchase': 'Mi lista de productos',
			'no products were selected': 'Ningún producto ha sido seleccionado',
			'your time is precious': 'Tu tiempo es valioso',
			'we have prepared a list of your personal products': 'Hemos preparado una lista con tus productos personales',
			'we have prepared a list of products you usually purchase': 'Hemos preparado una lista con tus productos personales',
			'add products from the list': 'Agregar productos de la lista',
			'in cart': 'En el carrito',

			'we use cookies to improve your experience': 'Usamos cookies para mejorar tu experiencia',
			'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': 'Hacemos uso de cookies u otros rastreadores para facilitar el uso del sitio, mejorar el rendimiento y la seguridad del sitio, personalizar el contenido ofrecido, incluyendo el contenido publicitario personalizado, y compilar estadísticas de tráfico y navegación. Algunas cookies, además de las cookies funcionales y de seguridad, requieren su consentimiento para ser colocadas.',
			'accept': 'Aceptar',
			'accept all cookies': 'Aceptar todas las cookies',
			'setup my cookies': 'Configurar mis cookies',
			'managing cookies': 'Administrar cookies',
			'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'Estas cookies le permitirán gozar de los servicios ofrecidos por el sitio, para optimizar su uso y personalización (en particular, la personalización de ofertas y anuncios) en función de su perfil.',
			'marketing': 'Márketing',
			'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'Las cookies de marketing sirven para mejorar la relevancia de los anuncios que ve y para asegurarse de que no vea el mismo anuncio en múltiples ocasiones.',
			'additional features': 'Características adicionales',
			'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'Estas cookies hacen posible la activación de características adicionales del sitio. Por ejemplo, para facilitar sus compras.',
			'audience measurement': 'Medición de audiencia',
			'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'Estas cookies nos permiten obtener estadísticas de navegación en nuestro sitio. Estos datos son utilizados para identificar posibles fallas del sitio, para mejorar el sitio, con fines de marketing y para personalizar su experiencia de navegación en el sitio.',
			'google analytics': 'Google analytics',
			'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'Estas cookies permiten la obtención de estadísticas respecto del número de visitas a nuestro sitio (por ejemplo, número de visitas, páginas más visitadas, etc.). Usamos estos datos para comprender mejor a nuestros usuarios, verificar el rendimiento de nuestro contenido, obtener información e identificar posibles fallas de funcionamiento con el fin de realizar mejoras.',
			'functional (not configurable)': 'Funcionales (no configurables)',
			'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'Estas cookies le permiten recordar sus elecciones y preferencias en su cuenta y proporcionan funciones que facilitan el uso del sitio. Son imprescindibles para el buen funcionamiento del sitio y son los estrictamente necesarios para la prestación de un servicio que ha solicitado expresamente.',
			'sitemap': 'Mapa del sitio',
			'pages': 'Paginas',
			'products marked with': 'Productos marcados con',
			'limited quantity': 'Cantidad limitada',
			'tag are limited to': 'la etiqueta se limita a',
			'items only.': 'artículos solamente.',
			'you_can_not_add_more_than': 'You can not add to the shopping cart more than',
			'items_marked_with_symbol': 'items marked with this symbol',

			'the {product_count} products listed below will be': 'Los {product_count} productos que se muestran a continuación serán',
			'removed from your cart': 'Eliminado de tu carrito',
			'out of stock products': 'Productos agotados',
			'the items below will be removed from your cart': 'Los siguientes articulos serán eliminados de su carrito',
			'back to cart': 'Volver al carrito',
			'confirm': 'Confirmar',
			'expired':'expiré',
			'{product_count} out of stock products will not be supplied': '{product_count} los productos agotados no se suministrarán',
			'{coupons_count} expired coupons cannot be redeemed': '{coupons_count} los cupones caducados no se pueden canjear',
			'registration attempt has been blocked, please contact support': 'Se ha bloqueado el intento de registro, póngase en contacto con el soporte',
			'account has been suspended, please contact support': 'Cuenta suspendida, comuníquese con soporte',
			'delivery-fee-explain-default': 'Se espera que el importe final de los gastos de envío se actualice en el momento de la compra y podría estar sujeto a cambios debido a la zona/hora de entrega o a cualquier descuento adicional. ',
			'delivery_type_1_fee': 'Gastos de entrega',
			'delivery_type_2_fee': 'Gastos de recogida',
			'includes_delivery_type_1_fee': 'Incluye los gastos de entrega',
			'includes_delivery_type_2_fee': 'Incluye los gastos de recogida',
			'not_includes_delivery_fee': '(no incluye los gastos de envío)',
			'valid until': 'Válido hasta',
			'proceed to checkout': 'Pagar',

			'add coupon': 'Añadir cupón',
			'your points': 'Sus puntos',
			'new': 'Nuevo',
			'buy with points': 'Comprar más puntos',
			'almost expired': 'Casi vencido',
			'coupon clipped': 'Cupón utilizado',
			'add product': 'Añadir producto',
			'product in cart': 'Producto en el carrito',
			'clip coupon': 'Utilizar cupón',
			'clipped externally': 'Cupón desde otro sitio web',
			'personal coupons': 'Cupones personales',
			'may interest you': 'También le puede gustar',
			'clip for {x} points': 'Utilizar por {x} puntos',
			'not enough points': 'No hay suficientes puntos',
			'coupon expires today': 'El cupón caduca hoy',
			'coupon expires in {x} days': 'El cupón caduca en {x} días',
			'clip for': 'Utilizar por',
			'valid': 'Válido',
			'can be redeemed once': 'Se puede canjear una vez',
			'can be redeemed {x} times': 'Se puede canjear {x} veces',

			'last_minute_recommendations_headline': 'tenemos recomendaciones seleccionadas especialmente para usted.',
			'last_minute_recommendations_title_coupons': 'Cupones que pueden ayudarle a ahorrar dinero',
			'last_minute_recommendations_title_products_usually': '¿Tal vez olvidó estos productos?',
			'last_minute_recommendations_title_products_complementary': 'También le puede gustar...',
			'last_minute_recommendations_title_specials_offers': 'Promociones que no querrá perderse',
			'last_minute_recommendations_title_products_offers': 'No querrá perderse estos productos',

			'account_deletion_common_customer_support_1': 'Para soporte llame al servicio al cliente:',
			'account_deletion_common_customer_support_2': 'Vuelva a intentarlo más tarde o llame al servicio de atención al cliente:',
			'account_deletion_account_title': 'Eliminar cuenta',
			'account_deletion_init_title': 'Eliminar su cuenta',
			'account_deletion_recaptcha_description': 'Se requiere una verificación siguiendo unos pocos pasos para eliminar su cuenta.',
			'account_deletion_authentication_description': 'Se ha enviado un correo electrónico con un código de verificación a {userEmail}',
			'account_deletion_authentication_help_spam': 'Es posible que deba revisar su carpeta de correo no deseado',
			'account_deletion_authentication_help_code_1': 'No recibió el código?',
			'account_deletion_authentication_help_code_2': 'enviar de nuevo',
			'account_deletion_authentication_help_error_try_again_1': 'Vuelva a intentarlo o',
			'account_deletion_authentication_help_error_try_again_2': ' reenvíe un código nuevo',
			'account_deletion_authentication_help_error_max_tries': 'Ha superado el número de intentos posibles, inténtelo de nuevo más tarde.',
			'Expired code': 'Código caducado',
			'Invalid code': 'Codigo invalido',
			'account_deletion_confirmation_description_1': 'Está a punto',
			'account_deletion_confirmation_description_2': 'de eliminar su cuenta de forma permanente ',
			'account_deletion_confirmation_description_3': 'todos los datos de su cuenta y los pedidos futuros serán eliminados',
			'account_deletion_confirmation_description_4': 'Esta acción no se puede deshacer',
			'account_deletion_confirmation_help_input_1': 'Al escribir la palabra "CONFIRM", aprobará la eliminación de su cuenta',
			'account_deletion_confirmation_help_input_2': 'Escriba "CONFIRM" para verificar',
			'account_deletion_already_deleted_title': 'No se puede continuar',
			'account_deletion_already_deleted_description': 'Retrieving data. Wait a few seconds and try to cut or copy again.',
			'account_deletion_notification_title': 'su cuenta será eliminada en {numOfDaysUntilDeletion} días',
			'account_deletion_notification_description': 'Cualquier pedido realizado para ser entregado o recogido después de este tiempo no será entregado.',
			'account_deletion_error_title': 'Ocurrió un error en el sistema',
			'account_deletion_error_description': 'No se puede continuar debido a un error del sistema',

			'please type in your zip code': 'Por favor ingrese su código postal',
			'find address': 'Encuentre la dirección',
			'you cannot edit this field before entering a zip code for your address': 'No puede editar este campo antes de ingresar un código postal para su dirección',
			'please choose your address': 'Por favor elija su dirección',
			'you will be able to edit the address details': 'Podrá editar los detalles de la dirección',
			'you cannot edit this field': 'No puede editar este campo',
			'not editable error': 'No puede editar este campo antes de ingresar un código postal para su dirección',
			'not_editable_locked error': 'Para cambiar la dirección, elija de nuevo de la lista de códigos postales',
			'not_editable_locked_empty error': 'Ingrese su código postal y elija su dirección de la lista',
			'zipcode error': 'No puede editar este campo',
			'there are no available items for this promotion': 'No hay artículos disponibles para esta promoción',
			'proceed to checkout': 'Pagar',

			'add_units_coupon': 'Añade {remaining} productos más para conseguir el descuento',
			'add_currency_coupon': 'Añade {remaining} productos más para conseguir el descuento',
			'add_measure_coupon': 'Añade {remaining} {unit} más para conseguir el descuento',
			'add_units_cart_coupon': 'Añade {remaining} productos',
			'add_unit_cart_coupon': 'Añade un producto',
			'add_currency_cart_coupon': 'Añade {remaining}',
			'add_measure_cart_coupon': 'Añade {remaining} {unit}',
			'add_units_special_purchase': 'Añade {remaining} más',
			'buy_units_total': 'Compra {total} productos',
			'buy_unit_total': 'Compra {total} productos',
			'buy_currency_total': 'Compra por importe de {total}',
			'buy_measure_total': 'Compra {total} {unit}',

			'suggestions_dialog_header_1': 'Recomendación para los reemplazos',
			'suggestions_dialog_header_2': 'Por favor seleccione un reemplazo de nuestra variedad de productos similares',
			'suggestions_dialog_header_3': 'Agotado',
			'suggestions_dialog_header_4': 'Recomendación para los reemplazos',
			'ok, continue' : 'De acuerdo, CONTINUAR',
            'change chosen time': 'Cambiar la fecha de entrega',

			'hover description for cvv': 'El número CVV («Valor de verificación de la tarjeta») en su tarjeta de crédito o débito es un número de 3 dígitos en las tarjetas de las marcas VISA, Mastercard y Discover. En su tarjeta de crédito o débito de American Express es un código numérico de 4 dígitos.',
			'enter cvv number': '',

			'the coupon displayed below, will not be valid for this chosen time': 'Tenga en cuenta que el cupón que se muestra a continuación no será válido para este horario elegido',
			'the expiration of these {coupon_number} coupons will not be valid for this chosen time': 'Tenga en cuenta que estos {coupon_number} cupones expiran y no serán válidos para esta fecha seleccionada',
			'the coupon displayed below, will not be valid for this chosen time': 'El cupón que se muestra a continuación, no será válido para este tiempo elegido',
			'these {coupon_number} coupons will not be valid for this chosen time': 'Estos {coupon_number} cupones no serán válidos para esta fecha seleccionada',
			'price does not include bottle deposit': 'El precio no incluye el depósito de la botella',
			'price includes bottle deposit': 'El precio incluye el depósito de la botella',
			'balance to be paid': 'Saldo a pagar',
			'please enter the card number to check the balance': 'Por favor, introduzca el número de tarjeta para consultar el saldo',
			'redeem': 'Canjear',
			'please save the voucher or gift card until you receive your order and are charged': 'Por favor, guarde el cupón o tarjeta de regalo hasta que reciba su pedido y se realice',
			'choose gift card/s': '',
			'add gift card': '',
			'redeem gift card & discount cards': 'Para canjear una TARJETA DE REGALO',
			'for gift card and discount cards': '',
			'gift_card_error_message': 'No hemos podido procesar su TARJETA REGALO, por favor, inténtelo de nuevo o pruebe con otra tarjeta',
			'wrong_gift_card_amount_error': 'El monto excede el valor del carrito de compras',
			'amount_exceeds_balance': 'El importe es superior al saldo',
			'customercredit': 'Credito al cliente',
			"sorry, currently we don't deliver to this address for further inquiries call to the customer service.": 'Lo sentimos, actualmente no hacemos envíos a esta dirección. Para más consultas, llame al servicio de atención al cliente',
			'january': 'Enero',
			'february': 'Febrero',
			'march': 'Marzo',
			'april': 'Abril',
			'may': 'Puede',
			'june': 'Junio',
			'july': 'Julio',
			'august': 'Agosto',
			'september': 'Septiembre',
			'october': 'Octubre',
			'november': 'Noviembre',
			'december': 'Diciembre',
			'redeemed': 'redimidos',
			'points gained': 'puntos ganados',
			'source': 'Fuente',
			'when': 'Cuando',
			'gg_map_dialog_title': 'Elija una dirección',
			'gg_map_dialog_hint': 'Estamos teniendo problemas para identificar su ubicación exacta. Asegúrese de colocar el pasador con gran precisión. Esto nos ayudará a determinar su dirección exacta',
			'autocomplete_adrs_didnt_find': '¿No encontró su dirección?',
			'autocomplete_adrs_choose_location': 'Seleccione su ubicación en el mapa',
			'gg_map_alert_need_location': 'Por favor, activa la ubicación para usar Google Maps.',
			'gg_map_alert_error': 'Hay algunos problemas al mostrar Google Maps.',
			'gg_map_alert_unsupport': 'La geolocalización no es compatible con este navegador.',
			'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'Lo sentimos, su ciudad no fue encontrada. Seleccione una dirección de las sugerencias de autocompletar o señale su dirección usando la opción de Google Maps.',
			'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'Lo sentimos, no se encontró su dirección. Seleccione una dirección de las sugerencias de autocompletar o señale su dirección usando la opción de Google Maps.',
			'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'Lo sentimos, no se ha encontrado su código postal. Por favor, seleccione una dirección de las sugerencias de autocompletado o fije su dirección utilizando la opción de Google Maps.',
			'countryInput: required error': 'Por favor ingresa tu país',
			'country_of_origin_title': 'País de origen',
			'origin_country_legal_text': 'Tenga en cuenta que, si bien el país de origen indicado es exacto en la fecha de pedido, el país de origen real del producto entregado puede variar en función de las existencias disponibles en el punto de recogida durante la entrega',
			'after package deduction': 'Después de la deducción del paquete',
			'is this address correct? you can now edit and refine it': '¿Esta dirección es correcta? Ahora puede editarla y refinarla',
			'edit your address': 'Edite su dirección',
			'contact_us': 'Contáctenos',
			'how_can_we_help_you_today': '¿Cómo podemos ayudarle hoy?',
			'leave_us_a_message': 'Déjenos un mensaje y lo contactaremos pronto',
			'fullname': 'Nombre completo',
			'phone': 'Número de teléfono',
			'email': 'Email',
			'message': '¿Cómo podemos ayudarle?',
			'send': 'Enviar',
			'your_message_has_been_sent': 'Tu mensaje ha sido enviado',
			'thank_you_for_contact_us': 'Gracias por contactarnos',
			'searchtext_storename_placeholder': '{Nombre de la tienda}',
			'customer support phone number': 'Número de teléfono de atención al cliente',
			'please wait, we are uploading the products': 'Por favor espere, estamos subiendo los productos',
			'complete_and_proceed_to_checkout': 'Completar y proceder al pago',
			'please notice, the following products are not available on this chosen time.': 'Tenga en cuenta que los siguientes artículos no están disponibles para el momento elegido.',
			'the following products are not available on this chosen time.': 'Los siguientes artículos no están disponibles para el momento elegido.',
			'if you continue, they will be removed from your cart.': 'Si continúa, se retirarán del carrito.',
			'result for “{title}”': 'Resultados para “{title}”',
			'find a product': 'Encontrar un producto',
			'results by brand:': 'Resultados por marca:',
			'please enter cvv to continue': 'Por favor, introduzca el CVV para continuar',
			'checkout is opened on another screen': 'El pago se abre en otra pantalla',
			'loading coupons, please wait...': 'Cargando cupones. Por favor, espere...',
			'please wait, we are loading the products': 'Por favor espere, estamos subiendo los productos',
			'receipt': 'Recibo',
			'api_error_message': 'Se ha producido un error{error_message} (error {error_code}). inténtelo de nuevo en unos minutos, si el problema se repite póngase en contacto con el servicio de atención al cliente',
			'payment error': 'Error en el pago',
			'error received': 'Error detectado',
			'please check your credit card details or contact your credit card provider': 'Compruebe los datos de su tarjeta de crédito o póngase en contacto con su proveedor.',
			'address_form_field_label_city': 'Ciudad',
            'address_form_field_label_address': 'Dirección',
            'address_form_field_label_zip_code': 'Código postal',
            'address_form_field_label_postal_code': 'Código postal',
            'address_form_field_label_entrance': 'Entrada',
            'address_form_field_label_apartment_number': 'Número de apartamento',
            'address_form_field_label_building_code': 'Reglamento de construcción',
            'address_form_field_label_state': 'Estado',
            'address_form_field_label_comments': 'Comentarios',
            'address_form_field_label_apartment_suite_unit': 'Apartamento / Suite / Unidad',
            'address_form_field_label_providence': 'Previsión',
            'address_form_field_label_more_details': 'Más información',
			'address_form_field_label_floor': 'piso',
			'address_form_field_label_house_number': 'Casa No.',
			'address_form_field_label_street': 'Calle',

			'address_form_field_error_city': 'Por favor, ingrese una ciudad válida',
			'address_form_field_error_address': 'Por favor, ingrese una dirección válida',
			'address_form_field_error_zip_code': 'Por favor, ingrese un código postal válido',
			'address_form_field_error_postal_code': 'Por favor, ingrese un código postal válido',
			'address_form_field_error_entrance': 'Por favor, ingrese una entrada válida',
			'address_form_field_error_apartment_number': 'Por favor, ingrese un número de apartamento válido',
			'address_form_field_error_building_code': 'Por favor, ingrese un código de edificio válido',
			'address_form_field_error_state': 'Por favor, ingrese un estado válido',
			'address_form_field_error_comments': 'Por favor, ingrese comentarios válidos',
			'address_form_field_error_apartment_suite_unit': 'Por favor, ingrese un apartamento/suite/unidad válida',
			'address_form_field_error_providence': 'Por favor, ingrese una provincia válida',
			'address_form_field_error_more_details': 'Por favor, ingrese más detalles válidos',
			'address_form_field_error_floor': 'Por favor, ingrese un piso válido',
                
			'address_drop_pin_message_1': 'Ubicación guardada con éxito. Edita si es necesario.',
			'address_drop_pin_message_cancel': 'Cancelar dirección fijada',

			'autocomplete_city_not_found_non_droppin': 'Lo sentimos, no se ha encontrado su ciudad. Por favor, seleccione una dirección de la lista o póngase en contacto con el servicio de asistencia para obtener ayuda.',
			'autocomplete_address_not_found_non_droppin': 'Lo sentimos, no se ha encontrado su dirección. Por favor, seleccione una dirección de la lista o póngase en contacto con el servicio de asistencia.',
			'autocomplete_zip_code_not_found_non_droppin': 'Lo sentimos, no se ha encontrado su código postal. Por favor, seleccione una dirección de la lista o póngase en contacto con el servicio de asistencia para obtener ayuda.',

            'confirm_full_credit_card_charge_title': 'Confirmar cargo completo a tarjeta de crédito',
            'confirm_full_credit_card_charge_content': 'Monto de pago EBT configurado en $0. ¿Estás seguro de que quieres cargar el monto completo a tu tarjeta de crédito?',
            'confirm_full_credit_card_charge_content_btn_confirm': 'Sí, cargar a la tarjeta de crédito',
            'confirm_full_credit_card_charge_content_btn_back': 'Volver',

            'pickup time:': 'Fecha de recogida:',
            'pickup address:': 'Dirección de recogida:',
            'delivery time:': 'Fecha de entrega:',
            'delivery address:': 'Dirección de entrega:',
            'on_day': '',
            'wish_to_change_delivery': '¿Desea cambiar la fecha de entrega?',
            'wish_to_change_pickup': '¿Desea cambiar la fecha de recogida?',
            'select date & time': 'Seleccionar fecha y hora',
            'confirm update': 'CONFIRMAR ACTUALIZACIÓN',
            'save & change delivery time': 'Guardar y cambiar fecha de entrega',
            'save & change pickup time': 'Guardar y cambiar fecha de recogida',
            'back to home page': 'Volver a la página principal',
            'your order has been updated': 'Su pedido se actualizó',

            'checkout_finish_last_update_time_delivery': 'Puede cambiar la fecha de entrega hasta',
            'checkout_finish_last_update_time_pickup': 'Puede cambiar la fecha de recogida hasta',
            'forgot_sth_want_to_change_delivery': '¿Desea cambiar la fecha de entrega?',
            'forgot_sth_want_to_change_pickup': '¿Desea cambiar la fecha de recogida?',

            'update_order_popup_title_def': 'Puede actualizar los artículos de su pedido o la hora de {delivery method}.',
            'update_order_popup_order_item_def': 'cambiar productos',
            'update_order_popup_delivery_time_def': 'cambiar entrega',
            'update_order_popup_pickup_time_def': 'cambiar recogida',
            'update_order_popup_cancel_def': '¿Desea cancelar su pedido? Haga clic aquí para cancelarlo {cancel order}',
            'update_order_popup_lastest_time_def': 'Puede actualizar la hora de su pedido {delivery method} hasta {time calculate}',
            'update_order_popup_err_cant_update_time': 'No es posible actualizar la hora de {delivery method}.',

            'irre_sales_collect_one': 'Este {saleType} no es válido para la fecha actualizada y se cobrará a su precio regular',
            'irre_sales_collect_many': 'Estos {count} {saleType} no serán válidos para esta fecha actualizada y se cobrarán a su precio normal',
            'irre_sales_checkout_one': 'Al actualizar este pedido, los siguientes {saleType} dejarán de ser válidos y el artículo se cobrará a su precio habitual',
            'irre_sales_checkout_many': 'Al actualizar este pedido, los siguientes {count} {saleType} dejarán de ser válidos, y los artículos se cobrarán a su precio normal',
            'irre_sales_oos_one': 'Estos artículos no están disponibles yse eliminarán del carrito',
            'irre_sales_oos_many': 'Estos artículos no están disponibles yse eliminarán del carrito',
            'irre_sales_btn_continue': 'sí, actualizar',
            'irre_sales_btn_change_slot': 'Modificar fecha de {delivery method}',
            'irre_sales_btn_cancel_update': 'Cancelar actualización',
            'thank you for your order!': '¡Gracias por su pedido!',
			'delivery_disclaimer_default_text': 'Las ventanas de entrega solo se pueden seleccionar durante el proceso de pago',

			"add your credit card": "Añada su tarjeta de crédito",
			"payment methods": "Métodos de pago",
			"your credit cards": "Sus tarjetas de crédito",
			"add a credit card": "Añadir tarjeta de crédito",
			"gift cards": "Tarjetas de regalo",
			"add gift cards": "Añadir tarjetas de regalo",
			"choose gift card/s": "Elegir TARJETA(S) DE REGALO",
			"please enter the card number to check balance": "Introduzca el número de tarjeta para consultar el saldo",
			"{gift card name} details": "Información de {Gift card name}",
			"on the next screen, you can select your payment amount": "En la siguiente pantalla, puede seleccionar el importe del pago",
			"balance:": "Saldo:",
			"please enter amount to pay": "Introduzca el importe a pagar",
			"amount exceeds balance": "El importe es superior al saldo",
			"please save the voucher or gift card until you receive your order and are charged.": "Por favor, guarde el vale o la tarjeta de regalo hasta que reciba su pedido y se le cobre.",
			"remove card": "Retirar tarjeta",
			"see more": "ver más",
			"show less": "Ver menos",
			"amount exceeds shopping cart value": "El monto excede el valor del carrito de compras",
			'user not connected to organization': 'Usuario no conectado a la organización',
			'amount to pay': 'Importe a pagar',
			'balance': 'Saldo',

			'default_first_time_popup_title': '¡Bienvenido!',
			'default_first_time_popup_sub_title': '¿Cómo le gustaría recibir su pedido?',
			'default_first_time_popup_sub_title_pickup': '¡Elige una tienda cercana para recogerlo de forma conveniente!',
			'default_first_time_popup_sub_title_delivery': 'Ingrese su dirección para ver las opciones de entrega disponibles cerca de usted',
			'default_returning_popup_title': 'Cambiar área',
			'default_returning_popup_sub_title': '¿Cómo le gustaría recibir su pedido?',
			'default_returning_popup_sub_title_pickup': 'Buscar nuevas opciones de entrega',
			'default_returning_popup_sub_title_delivery': 'Por favor, ingrese una dirección de entrega',

			'default_one_store_popup_title': '¡Buenas noticias!',
			'default_one_store_popup_sub_title': '¡Podemos entregar en su área!',
			'default_one_store_popup_continue_button_text': 'Seguir comprando',

			'default_multiple_store_popup_title': '¡Qué suerte!',
			'default_multiple_store_popup_sub_title': 'Tenemos múltiples tiendas que atienden su área',

			'default_no_store_popup_title': 'Lo lamentamos',
			'default_no_store_popup_sub_title': 'Actualmente no realizamos envíos en su área. Deje su correo electrónico, y le avisaremos cuando la cubramos.',

			'delivery_input_zip_code_placeholder': 'Code postal (ex : 75011)',
			'back_btn_text': 'Atrás',

			'try a different location': 'Pruebe con una ubicación diferente',
			'delivery_input_address_placeholder': 'Por favor, ingrese una dirección de entrega',
		}
	});
})(angular);
