
angular.module('mobilezuz')
	.constant('STORAGE', {
		MOBILE_NAVBAR_STORAGE_KEY: 'mobileNavbarSettings'
	})
	.controller('AppFooter', ['$state', '$rootScope', '$timeout', 'Api', 'BarcodeScaner', 'mDesign', 'Offers', 'Util', '$scope','$sce','Config','$q', 'Retailer','LocalStorage','STORAGE',
		function($state, $rootScope, $timeout, Api, BarcodeScaner, mDesign, Offers, Util, $scope, $sce, Config, $q, Retailer, LocalStorage, STORAGE) {
			var footerCtrl = this,
				_listeners = [],
				_specialCategories = [],
				enablePersonalizedCoupons;
			$scope.numberOfCoupons = 0;

			$scope.mobileNavbarConfig = _parseMobileNavbarConfig();
			_checkNewNavbar();
			function _parseMobileNavbarConfig(){
				try {
					return LocalStorage.getItem(STORAGE.MOBILE_NAVBAR_STORAGE_KEY)
				} catch (e) {
					return undefined
				}
			}

			$q.all({
				configuration: Retailer.getRetailerSettings(),
				init: Config.waitForInit()
			}).then(function(results) {
				configuration = results.configuration;
        var _configurationNavbar = JSON.parse(configuration.mobileNavbarSettings) || {};
        if (_configurationNavbar){
					var _currentLastUpdated = $scope.mobileNavbarConfig ? $scope.mobileNavbarConfig.lastUpdated : undefined;
          if (_configurationNavbar.lastUpdated !== _currentLastUpdated || !$scope.mobileNavbarConfig) {
            $scope.mobileNavbarConfig = _configurationNavbar;
            _checkNewNavbar();
            LocalStorage.setItem(STORAGE.MOBILE_NAVBAR_STORAGE_KEY, JSON.stringify(_configurationNavbar));
          }
          enablePersonalizedCoupons = configuration && configuration.settings && (configuration.settings.enablePersonalizedCoupons === "true");
        }
			})

			footerCtrl.renderItemFooter = renderItemFooter;
			footerCtrl.scanBarcode = scanBarcode;
			footerCtrl.goToSales = goToSales;
			footerCtrl.navigationState = navigationState;
			footerCtrl.fireEvent = fireEvent;
			footerCtrl.icons = {
				'app.home': 'home',
				'app.specials': 'specials',
				'app.specialCategories': 'specials',
				'app.specials.special': 'specials',
				'app.categories': 'departments',
				'app.ordersHistory': 'orders',
				'app.myCoupons': 'coupons',
				'scan': 'scanner',
				'app.cart': 'cart',
				'app.retailer.contactUs': 'contact-us',
				'app.recentPurchases': 'my-list'
			};

			_listeners.push($scope.$watch(function() {
				return Config.language.culture;
			}, function(newCulture, oldCulture) {
				$scope.language = newCulture;
			}));

			$rootScope.$on('numberOfCouponsRoot', function(event, data) {
				$scope.numberOfCoupons = data
			})


			angular.extend(footerCtrl, {
				isHide: false
			})

			function _checkNewNavbar() {
				try {
					if ($scope.mobileNavbarConfig) {
						$scope.navBar = [];
						($scope.mobileNavbarConfig.appNavigationHeader || []).forEach(function(item){
							var _item = $scope.mobileNavbarConfig.shortcuts[item.shortcutTo]
							_item.id = Math.random().toString(36).substring(2, 15);
							if($scope.navBar.length < $scope.mobileNavbarConfig.total && _item){
								$scope.navBar.push(_item);
							}
						});
						$scope.isEnableNewNavBar = $scope.mobileNavbarConfig && $scope.mobileNavbarConfig.isEnable ? $scope.mobileNavbarConfig.isEnable.appNavigationHeader : false;
					}
				} catch (e) {
					console.error(e)
				}

			}

			function navigationState(shortcutTo) {
				var state = shortcutTo
				switch (shortcutTo) {
					case 'scanner-cart':
						if ($rootScope.hasCordova) {
							state = '#';
						} else {
							state = 'app.cart';
						}
						break;
					case 'my-list':
						state = 'app.recentPurchases';
						break;
					case 'departments':
						state = 'app.categories';
						break;
					case 'coupons':
						state = 'app.myCoupons';
						break;
					case 'contact-us':
						state = 'app.retailer.contactUs';
						break;
					default:
						state = 'app.' + shortcutTo;
						break;
				}
				return state
			}

			function scanBarcode() {
				Util.setLiveAlert('Camera opened', null, true);
				$timeout(function () {
					return BarcodeScaner.scan().then(function(barcode) {
						if (!barcode) return;

						var query = {
							size: 1,
							from: 0,
							minScore: 0,
							barcode: barcode,
							filters: {
								must: {
									exists: ['family.id', 'family.categoriesPaths.id', 'branch.regularPrice'],
									term: {
										'branch.isActive': true,
										'branch.isVisible': true
									}
								},
								mustNot: {
									term: {
										'branch.regularPrice': 0
									}
								}
							}
						};

						return Api.request({
							method: 'GET',
							url: '/v2/retailers/:rid/branches/:bid/products',
							params: query
						});
					}).then(function(respond) {
						if (!respond) {
							return;
						} else if (!respond.products.length) {
							return mDesign.alert('Sorry. We couldn\'t find this product');
						}

						return Util.goToProductDialog(respond.products[0]);
					});
				}, 650);
			}

			function renderItemFooter(item){
				if (!$rootScope.hasCordova && item.shortcutTo === 'scanner-cart') {
					if (item.additionalField) {
						item.additionalField.shortcutTo = 'cart';
						return item.additionalField;
					}
				}
				return item;
			}

			function goToSales() {
				if (!_specialCategories) return;

				if (_specialCategories.length) {
					return $state.go('app.specialCategories');
				}

				$state.go('app.specials');
			}


			function _markIcon(toRoute) {
				if(toRoute && toRoute.name) {
					footerCtrl.markedIcon = footerCtrl.icons[toRoute.name] || '';
				}
			}

			function fireEvent(eventName) {
				// $rootScope.$emit(eventName);
				if (eventName === 'scanner') {
					scanBarcode()
				}
			}

			Offers.getFirstSpecials().then(function(specials) {
				footerCtrl.specials = specials;
			});

			// ------------- LISTENER ---------------------
			$rootScope.$on('$stateChangeStart', function(event, toRoute) {
				_markIcon(toRoute);
				footerCtrl.isHide = false
			});

			$rootScope.$on('cart.hideCartPage', function(){
				footerCtrl.isHide = true
			})

			Util.destroyListeners($scope, _listeners);
		}]);
