(function(angular) {
	angular.module('mobilezuz').service('RuLanguage', function() {
		this.id = 4;
		this.culture = 'ru';
		this.name = 'Русский';
		this.direction = 'ltr';
		this.translations = {
			//'en': 'he',
			'my': 'Мои',
			'in {name} dep.': 'в отделе {name}',
			'in specials': 'В акциях',
			'special products': 'Акционные товары',
			'shopping lists': 'Перечень покупок',
			'shopping list': 'Список покупок',
			'shop': 'Купить',
			'shop ': 'Товары',
			'by departments': 'В отделах',
			'email': 'Электронная почта',
			'email_error': 'Введите правильный адрес электронной почты',
			'password': 'Пароль',
			'log in': 'Войти',
			'log in with facebook': 'Войти через фейсбук',
			'password recovery message was sent to your email': 'Уведомление о восстановлении пароля отправлено ва ваш электронный адрес',
			'home': 'Дом',
			'departments': 'отделам',
			'scanner': 'Сканировать',
            'all products': 'Товары',
            'orders': 'Покупки',
			'sales': 'Акции',
			'specials': 'Акции',
			'sale': 'Акция',
			'edit': 'Редактирование',
			'search': 'Поиск',
			'total': 'Всего',
			'checkout': 'На кассу',
			'forgot your password?': 'Забыли пароль?',
			'my shopping lists': 'Мои списки',
			'remove': 'Удалить',
			'add list': 'Добавить список',
			'name': 'Имя',
			'creationdate': 'Дата создания',
			'itemscount': 'Количеств товаров',
			'shopping list name': 'Имя списка',
			'sorting by': 'Сортировать по',
			'add': 'Добавить',
			'add_product': 'Купить',
			'close': 'Закрыть',
			'clean cart': 'Очистить',
			'recipient\'s name': 'Имя получателя',
			'email address': 'Электронный адрес',
			'the offer is valid from': 'Акция в силе от',
			'until': 'до',
			'terms and conditions': 'Правила и условия',
			'this purchase order is an offer by the company identified on the face of this purchase order (the \"buyer\") for the purchase of the goods (the \"goods or services (the “services”) specified\")': 'This purchase order is an offer by the company identified on the face of this purchase order (the \"Buyer\") for the purchase of the goods (the \"Goods or services (the “Services”) specified\")_h',
			'pay with': 'Оплатить с помощю',
			'card number': 'Номер карты',
			'delivery address': 'Адрес доставки',
			'add card number': 'Добавить номер карты',
			'country': 'Страна',
			'zip code': 'Индекс',
			'zipcode': 'Индекс',
			'entrance': 'Подъезд',
			'city': 'Город',
			'street': 'Улица',
			'house number': 'Номер дома',
			'floor': 'Этаж',
			'apartment': 'Квартира',
			// 'entry: spautocompleterequirematch error': 'הזן אות (א-י)',
			// 'house_entry_none': 'ללא',
			// 'house_entry_a': 'א',
			// 'house_entry_b': 'ב',
			// 'house_entry_c': 'ג',
			// 'house_entry_d': 'ד',
			// 'house_entry_e': 'ה',
			// 'house_entry_f': 'ו',
			// 'house_entry_g': 'ז',
			// 'house_entry_h': 'ח',
			// 'house_entry_i': 'ט',
			// 'house_entry_j': 'י',
			'personal info': 'Личная информация',
			'first name': 'Имя',
			'last name': 'Фамилия',
			'full name': 'Полное имя',
			'phone number': 'Номер телефона',
			'delivery time': 'Время доставки',
			'day': 'День',
			'time': 'Время',
			'notes': 'Комментарий',
			'add instructions': 'Добавить указание',
			'summary': 'Итог',
			'products': 'Товары',
			'delivery charge': 'Стоимость доставки',
			'ok': 'Разрешить',
			'cancel': 'Отменить',
			'recent': 'Последние',
			'purchases': 'Покупки',
			'please enter street': 'Введите улицу',
			'please enter email': 'Введите электронный адрес',
			'please enter password': 'Введите пароль',
			'please enter first name': 'Введите имя',
			'please enter last name': 'Введите фамилия',
			'please enter your email address': 'Введите свой электронный адрес',
			'the shopping list was successfully added to the cart': 'Список покупок добавлен в корзину',
			'callcustomer': 'Позвонить мне',
			'creditcard': 'Кредитная карта',
			'storecredit': 'Клиентский счёт',
			'between': 'Во время',
			'sunday': 'Вск.',
			'monday': 'Пнд.',
			'tuesday': 'Втр.',
			'wednesday': 'Срд.',
			'thursday': 'Чтв.',
			'friday': 'Птн.',
			'saturday': 'Субб.',
			'today': 'Сегодня',
			'your order has been received': 'Ваш заказ получен',
			//'your order is in process and will be sent to you in the next hours. order id': 'Ваш заказ получен и будет отправлен в ближайшее время. Номер заказа:',
			'register': 'Зарегистрироваться',
			'creation date': 'Дата создания',
			'items count': 'Количество товаров',
			'shop by': 'Купить по',
			'my orders history': 'История моих заказов',
			'orders history': 'История заказов',
			'previous orders': 'Предыдущие заказы',
			'view details' : 'Посмотреть детали',
			'number of provided products' : 'Количество предоставленных товаров',
			'order time' :'Время заказа',
			'total (estimated)' : 'Итого (примерно)',
			'call customer' : 'Позвонить клиенту',
			'payment method ending with': 'Способ оплаты заканчивается на',
			'credit card charge' : 'Оплата кредитной картой',
			'replaced with': 'Заменено на',
			'new product' : 'Новый продукт',
			'ordered' : 'Заказано',
			'received_items' : 'Получено',
			'account': 'Профиль',
			'retailer details': 'Данные о магазине',
			'more links': 'Дополнительные ссылки',
			'change language': 'Сменить язык',
			'languages': 'Языки',
			'help': 'Помощь',
			'settings': 'Установки',
			'personal details': 'Личная информация',
			'birth date': 'Дата рождения',
			'allow send promotions': 'Согласен на получение рекламы',
			'gender': 'Пол',
			'male': 'Мужской',
			'female': 'Женский',
			'prefer not to say': 'Предпочитаю не говорить',
			'save': 'Сохранить',
			'print': 'Печатать',
			'addresses': 'Адрес',
			'logout': 'Выйти',
			'info': 'Информация',
			'policy': 'Правила',
			'contact details': 'Контактные данные',
			'my cart': 'Моя корзина',
			'quantity': 'Количество',
			'price': 'Цена',
			'items': 'Товары',
			'check out': 'Завершить покупку',
			'enter cvv': 'CVV',
			'add card': 'Добавить карту',
			'remove card': 'Удалить карту',
			'thank you for your purchase': 'Благодарим за вашу покупку',
			'the delivery time is no longer available': 'Нет доступного времени доставки',
			'finish': 'Закончить',
			'gift total': 'Размер компенсации',
			'purchase type': 'Условия акции',
			'end date': 'Дата окончания',
			'gift type': 'Вид акции',
			'confirm password': 'Подтвердить пароль',
			'please let me know about promotions': 'Пожалуйста, оповещайте меня о рекламных акциях',
			'more': 'ещё',
			'from my list': 'Из списка',
			'import to cart': 'Добавить',
			'import products from cart?': 'Импортировать товары из корзины ?',
			'nutrition facts': 'Пищевая информация',
			'details': 'Детали',
			'add to cart': 'Добавить',
			'view similar items': 'Просмотреть похожие товары',
			'settings was successfully changed!': 'Уставновки успешно изменены',
			'sorry. we couldn\'t find this product': 'Товар не найден',
			'two passwords must match': 'Пароли не совпадают',
			'sign up completed': 'Регистрация успешно завершена',
			'product not found': 'Товар не найден',
			'please enter your password': 'Введите пароль',
			'my shopping list': 'Мой список',
			'house no.': '№ дома',
			'from my cart': 'Из корзины покупок',
			'est.': 'примерно',
			'case': 'упаковка',
			'in store location': 'Расположение в магазине',
			'units': 'единицы',
			'unit': 'ед',
			'new shopping list was created': 'Новый список покупок создан',
			'please enter list name': 'Введите название списка',
			'there are no products in cart': 'Нет товаров в корзине',
			'single': 'Один',
			'pack': 'Упаковка',
			'pck': 'Упк.',
			'cell phone': 'Моб.телелфон',
			'home phone': 'Дом.теелфон',
			'work phone': 'Раб.телефон',
			'error': 'Ошибка',
			'are you sure?': 'Вы уверены, что хотите очистить корзину?',
			'phones': 'Телефон',
			'address': 'Адреса',
			'opening hours': 'Время работы',
			'user not found': 'Имя пользователя или пароль не верны',
			'the email address doesn\'t have an associated account. please try again': 'Адрес электронной почты не привязан к учетной записи. Пожалуйста, попробуйте еще раз',
			'please enter your email address to proceed': 'Пожалуйста, введите адрес электронной почты, чтобы продолжить',
			'loyalty user not found': 'Неверные данные входа в клиентский клуб',
			'branches': 'Отделения',
			'login': 'Войти',
			'status': 'Статус',
			'time placed': 'Время прибытия',
			'i agree to receive promotions': 'Я согласен\\а получать рекламу',
			'add to list': 'Добавить в перечень',
			'unauthorized action': 'Неразрешённое действие',
			'first name is required': 'Необходимо ввести имя',
			'last name is required': 'Необходимо ввести фамилию',
			'email is required': 'Необходимо ввести электронный адрес',
			'email is not valid': 'Электронный адрес не действительный',
			'password is required': 'Необхоимо ввести пароль',
			'phone number is required': 'Необходимо ввести номер телефона',
			'pickup location is required': 'Необходимо выбрать место самовывоза',
			'pickup day is required': 'Необходимо выбрать день самовывоза',
			'pickup hour is required': 'Необходимо выбрать время самовывоза',
			'payment method is required': 'Необходимо выбрать способ оплаты',
			'credit card is required': 'Необходимо добавить кредитную карту',
			'delivery day is required': 'Необходимо выбрать день доставки',
			'password should be longer than 6 characters': 'Пароль должен быть больше чем 6 символов',
			'new order': 'Новый заказ',
			'charged': 'Оплачен',
			'delivered': 'Отправлен',
			'play': 'Играть',
			'cancelled': 'Отменён',
			'on hold': 'В режиме ожидания',
			'processing': 'В обработке',
			'collected': 'Собран',
			'registered': 'Отправлен на кассу',
			'delete': 'Удалён',
			'you must login first': 'Войдите в свой профиль',
			'not found shop lists': 'Список покупок не найден',
			'product added to shop list': 'Товар добален в список',
			'yes': 'Да',
			'no': 'Нет',
			'brand': 'Производитель',
			'new name': 'Новое имя',
			'invalid name': 'Имя не верно',
			'could not find your location': 'Ваше местонахождения не найдено',
			'new list': 'Новый список',
			'+add to list': '+Добавить в список',
			'your cart is still empty': 'Ваша корзина пуста',
			'in all departments': 'Во всех отделах',
			'this is a pick and go order': 'Этот заказ',
			'start shopping': 'Начать покупку',
			'your order has been received but not charged': 'Заказ получен,но оплата ещё не снята',
			'your credit card has been charged for your order': 'Стоимость покупки снята с кредитной карты',
			'your order has been delivered and is on its way to you': 'Заказ отправлен и находится на пути к вам',
			'the order was cancelled for various reasons and is not being handled': 'Заказ отменён',
			'order could not be completed, customer intervention is required': 'Заказ не закончен, необходимо вмешательство заказчика',
			'the order is currently being processed': 'Заказ в обработке',
			'the items have been collected but not yet registered in the pos': 'Товары собрано но не отправлены на кассу',
			'the products were collected and scanned, but the transaction was yet to be completed': 'Товары собраны и отсканированны, но оплата еще не произведена',
			'product already exists': 'Товар уже находится в списке',
			'approve & register': 'Подтвердить и зарегистрироваться',
			'not approve': 'Не разрешаю',
			'{hubname}\'s privacy policy.': 'Моя политика конфиденциальности {hubname}',
			'i agree to receive promotional content & offers from {company_name} and {hubname}': 'Я хотел бы получить рекламную информацию и спецпредложения от {company_name}, которая управляет розничным сайтом {hubname}',
			'i agree to the': 'Я подтверждаю, что прочитал\\а',
			'terms & conditions': 'правила сайта',
			'policy_end': 'и согласен\\на с ними',
			'privacy policy': 'Политика конфиденциальности',
			'about us': 'О нас',
			//'add comment': 'Добавить комментарий',
			'track my order': 'Отследить мой заказ',
			'order id:': 'Номер заказа:',
			'no internet connection': 'Нет интернета',
			'please enable internet connection and retry': 'Пожалуйста, подключитесь к интернету и повторите попытку',
			'retry': 'Попробуйте ещё раз',
			'no branch': 'Нет отделения',
			'lists': 'Списки',
			'street is required': 'Необходимо ввести название улицы',
			'house number is required': 'Необходимо ввести номер дома',
			'country is required': 'Необходимо ввести страну',
			'cvv is required': 'Необходимо ввести cvv',
			'connect': 'Подключиться',
			'connect_loyalty': 'Подключиться к клубу',
			'not all of the products given where found': 'Не все продукты были найдены',
			'not all of the given products were found': 'Товары не найдены',
			'you have some missing products in your cart': 'У вас есть недостающие товары в корзине',
			'select method': 'Выбрать способ оплаты',
			'call me when there is missing item': 'Связаться со мной в случае отсутствия товара',
			'this email already exist': 'Данный электронный адрес уже существует в системе',
			'this email already exists': 'Данный электронный адрес уже существует в системе',
			'unable to create user': 'Не удалось создать пользователя',
			'km': 'км',
			'scanning failed: ': 'Сканирование не удалось:',
			'scan barcode': 'Сканировать товар',
			'please point your camera at the barcode.': 'Сосредоточьте камеру на штриховом коде',
			'i confirm that i have read the': 'Я подтверждаю, что прочитал\\а',
			'we are not able to add the following products to your current order': 'Мы не можем добавить эти товары в ваш заказ',
			'terms': 'Условия',
			'payment by': 'Оплата с помощью',
			'phone': 'Телефон',
			'payment': 'Оплата',
			'shipping address': 'Адрес доставки',
			'payment method': 'Способ оплаты',
			'you save': 'Вы сэкономили',
			'supervised': 'Под наблюдением',
			'floor /\ apt': 'этаж\\квартира',
			'open': 'Открыто',
			'per lb': 'за кг.',
			'lb': 'кг.',
			'to see if we deliver to your area,': 'Проверить регионы доставки,',
			'click here': 'Нажать',
			'we\'re available in': 'Мы доставляем товары в',
			'checking delivery areas': 'Проверить регионы доставки',
			'loyalty club': 'Клиентский клуб',
			'have a club member?': 'Существующий член клуба?',
			'join our members club': 'Присоединиться к клиентскому клубу',
			'join the club': 'Войти в клубу',
			'connect to club': 'Присоединиться к клубу',
			'member id': 'Номер удостоверения личности',
			'club id': 'Номер члена клуба',
			'connect to your club account': 'Подключиться к вашему клиентскому счёту',
			'choose': 'Выбрать',
			'choose your delivery area': 'Выберите ваш регион доставки',
			'delivery area': 'Регион доставки',
			'enter zip code': 'Ввести индекс',
			'mobile phone': 'Моб. телефон',
			'personal id': 'Номер удостоверения личности',
			'loyalty card id': 'Номер карты клуба',
			'update club details': 'Обновление информации члена клуба',
			'how do you want to get your shopping?': 'Как бы вы хотели получить свою покупку?',
			'home delivery': 'Доставка',
			'pickup': 'Самовывоз',
			'pick and go': 'Выбрать и уйти',
			'pick & go': 'Выбрать и уйти',
			'shipping details': 'Данные о покупке',
			'mobile': 'Мобильный',
			'next': 'Далее',
			'choose delivery time': 'Выбрать время доставки',
			'shipping method & time': 'Способ доставки и время',
			'localdelivery': 'Местная доставка',
			'send the delivery sooner, if possible': 'Отправить доставку по возможности раньше',
			'sooner if possible': 'Как можно скорее',
			'allow substitutions?': 'Разрешить замену?',
			'select card': 'Выбрать карту',
			'your order will be delivered': 'Ваш заказ будет доставлен',
			'you saved': 'Вы сэкономили',
			'subtotal': 'Промежуточный итог',
			'tax': 'НДС',
			'estimated': 'Ориентировочно',
			'only': 'Только',
			'contact details:': 'Контактные данные',
			'place order': 'Завершить покупку',
			'place order using masterpass': 'Завершить покупку с помощью MasterPass',
			'sending your order...': 'Пердача вашего заказа в магазин...',
			'please check your email for order details. thank you!': 'На вашу электронную почту отправленно письмо с информацией о заказе',
			'back to home screen': 'Вернуться на главную страницу',
			'questions? get in touch': 'Есть вопросы? Свяжитесь с нами',
			'current location:': 'Текущее местоположение:',
			'pickup location': 'Точки самовывоза',
			'click to choose pickup location': 'Выбрать точку самовывоза',
			'pickup time': 'Время самовывоза',
			'contact & payment details': 'Контактная и платёжная информация',
			'your order will be ready': 'Ваш заказ будет готов',
			'shipping': 'Доставка',
			'delivery': 'Доставка',
			'call me': 'Позвонить мне',
			'delivery_type_1': 'Доставка',
			'delivery_type_2': 'самовывоза',
			'delivery_type_3': 'Выбрать и уйти',
			'area_type_1': 'Доставка',
			'area_type_2': 'Доставка',
			'area_type_3': 'Доставка',
			'area_type_4': 'Доставка',
			'area_type_5': 'Самовывоз',
			// 'sun': 'א',
			// 'mon': 'ב',
			// 'tue': 'ג',
			// 'wed': 'ד',
			// 'thu': 'ה',
			// 'fri': 'ו',
			// 'sat': 'ז',
			'the address you provided is not served at this time. please enter another address or choose pickup': 'Мы не выполняем доставку по указанному адресу.Пожалуйста укажите другой адресс или выберете самовывоз',
			'we\'re  sorry, but the address you provided is not served at this time': 'Извиняемся, но указанный вами адрес не обслуживается',
			'change to pickup': 'заменить на самовывоз',
			'can\'t find a product ?': 'Не нашли товар?',
			'can\'t find a product?': 'Не нашли товар?',
			'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call.': 'Ведите имя товара,если он не будет найден, то мы с вами свяжемся.',
			'product name': 'Имя товара',
			'select category': 'Выбрать категорию',
			'clear cart': 'Удалить все товары',
			'menu': 'Меню',
			'customer id': 'Номер клиента клуба',
			'congradulations for joining the customers club': 'Благодарим за присоединение к клиентскому клубу',
			'your club member number': 'Номер члена клуба',
			'loyalty member': 'Уважаемые члены клуба',
			'you have connected successfully': 'Вы успешно подключены',
			'pay attention': 'Обратите внимание',
			'do you want to continue': 'Продолжить?',
			'continue without club discounts': 'Продожить без скидок клуба',
			'return and pay with loyalty credit card': 'Вернуться для выбора кредитной карты клуба',
			'paying with different payment method will discard {clubname} club discounts': 'скидки клуба {clubname} Действуют только при оплате кредитной картой клуба. Оплата другой картой приведет к отмене полученных скидок',
			'join to club': 'Присоединиться к клубу',
			'connect to club': 'Подключиться к клубу',
			'join': 'Присоединиться',
			'all the fields is required': 'Необходимо заполнить все поля',
			'did you mean?': 'Вы имели ввиду?',
			'callcustomer_description': 'Мы свяжемся с вами для произведения оплаты',
			'creditcard_description': 'Выбрать или добавить кредитную карту',
			'pay_creditcard_description': 'Оплатить кредитной картой',
			'storecredit_description': 'Оплатить из клиенсткого счёта',
			'paypal_description': 'Оплатить с PayPal',
			'icreditmasterpass_description': 'Оплатить с MasterPass',
			'bit_description': 'Оплатить апликацией Bit',
			'select:': 'Выбрать:',
			'select': 'Выбрать',
			'edit comment': 'Оставить комментарий',
			'add comment': 'Добавить комментарий',
			'write a comment': 'Написать комментарий',
			'address 1': 'Улица и номер дома',
			'to join': 'Присоединиться',
			'register now to join our membership club': 'Зарегистрироваться на сайте и вступить в клиентский клуб',
			'reset code': 'Переустановить код',
			'reset password': 'Переустановить пароль',
			'password reset': 'восстановление пароля',
			'reset': 'Восстановить',
			'a password recovery code has been sent to your email': 'На ваш адрес был отправлен код восстановления пароля',
			'please check your inbox and follow the instructions': 'Посмотрите входящие сообщения и следуйте инструкциям',
			'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'Если вы не получили эл. письмо, проверьте правильность написания адреса эл. почты или обратитесь в службу поддержки клиентов',
			'est. weight': 'Примерный вес',
			'please confirm that you accept the terms and conditions for using this service': 'Убедитесь, что вы согласны с условиями использования данных услуг',
			'explanation': 'Объяснение',
			'invalid cvv': 'Не верный cvv',
			'choose card': 'Выбрать карту',
			'invalid phone number': 'Неверный номер телефона',
			'invalid city': 'Неверный город',
			'invalid address': 'Ошибочный адрес',
			'invalid street': 'Ошибочное название улицы',
			'invalid address 1': 'Ошибочные улица и номер',
			'invalid zip code': 'Неверный индекс',
			'products are no longer available': 'Товаров нет в наличии',
			'payments number': 'Количество платежей',
			'online grocery service': 'Покупка продуктов питания онлайн',
			'download': 'Начать установку',
			'get<br>the app': 'установить<br>Апликация',
			'branch not found': 'Мы ещё не осуществляем доставки по указанному адресу',
			'missing premise or street number': 'Необходимо ввести номер дома',
			'estimated tax': 'Примерно налог',
			'estimated total': 'Итого примерно',
			'contact us': 'Связаться с нами',
			'how can we help you?': 'Как мы можем вам помочь?',
			'how can we help you today?': 'Как мы можем вам помочь?',
			'submit': 'Отправить',
			'invalid message': 'Введите текст',
			'invalid email': 'Ошибочный электронный адрес',
			'your message was sent successfully': 'Сообщение успешно отправлено',
			'we are sorry but this service is no longer available': 'Данная услуга недоступна',
			'continue with this cart': 'Продолжить с данной корзиной',
			'merge carts': 'Объединить корзины',
			'would you like to merge the two carts or continue using this cart': 'Вы хотите объединить две корзины ли продолжить с данной корзиной?',
			'somewhere else': 'В другом месте',
			'you have an open cart with': 'У вас открыта корзина с',
			'powered by': 'Разработано',
			'day0': 'Вск.',
			'day1': 'Пнд.',
			'day2': 'Втр.',
			'day3': 'Срд.',
			'day4': 'Чтв.',
			'day5': 'Птн.',
			'day6': 'Субб.',
			'to ': 'к',
			'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'Ваш заказ {{areaName}}ещё не достиг минимума {{minimum | currency}}',
			'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'добавте в корзину товары на сумму{{difference | currency}} Для завершения покупки',
			'not including items on sale': 'Не включая товары по скидкам',
			'please add additional products to the cart to proceed': 'Добавте товары в корзину для того, что бы продолжить',
			'substitute_preference_1_label': 'Заменить товары',
			'substitute_preference_1_description': 'Мы заменим недостающие товары без согласования с вами',
			'substitute_preference_2_label': 'Позвонить мне',
			'substitute_preference_2_description': 'Мы заменим товары согласовав по телефону\'',
			'substitute_preference_3_label': 'Не заменять',
			'substitute_preference_3_description': 'Мы не заменим недостающие товары',
			'substitute_preference_4_label': 'Отправить SMS',
			'substitute_preference_4_description': 'Перед тем как заменить недостающие товары мы отправим вам SMS',
			'substitute_preference_5_label': 'Отправить WhatsApp',
			'substitute_preference_5_description': 'Перед тем как заменить недостающие товары мы отправим вам сообщение WhatsApp',
			'substitute_preference__label': 'Избежать ошибки',
			'substitute_preference__description': 'Избежать ошибки',
			'leave_by_the_door': 'Прошу оставить доставку возле двери в случае, если никого нет дома',
			'sorry, currently we don\'t deliver to this address.': 'К сожалению, в данный момент мы не осуществляем доставки по указанному адресу',
			'sorry, currently we don\'t deliver to this address, for further inquiries call to customer service.': 'К сожалению, в данный момент мы не осуществляем доставку по указанному адресу, для дополнительной проверки звоните в службу поддержки клиентов.',
			'sorry, branch change required, this address could only be delivered from branch': 'Необходимо заменить отделение, по данному адресу возможна доставка только из отделения',
			'please enter a more accurate address to continue': 'Дла продолжения введите более точный адрес',
			'switch branch': 'Заменить отделение',
			'try another address or choose pickup option': 'Попробуйте другой адрес или воспользуйтесь самовывозом',
			'delivery fee': 'Стоимость доставки',
			'pickup fee': 'Стоимость самовывоза',
			'service fee': 'Стоимость услуг',
			'add a comment here:': 'Добавте комментарий здесь:',
			'at': 'в',
			'next delivery': 'Следующая доставка',
			'next pickup': 'Следующий самовывоз',
			'get it within': 'Будет отправлено в течение',
			'the following products were not saved correctly due to an error': 'Следующие продукты не были сохранены правильно из-за ошибки',
			'hrs': 'Часы',
			'coupon number': 'Номер купона',
			'coupon': 'Купон',
			'please enter quantity': 'Введите количество',
			'please enter a name with less than 200 characters': 'Название товара не может быть длиннее 200 символов',
			'we didn\'t recognize the address you entered': 'Мы не узнаём адрес, который вы ввели',
			'continue anyway': 'Продолжить в любом случае',
			'more details': 'Доп. информация',
			'product description': 'Описание товара',
			'ingredients': 'Состав',
			'more information': 'Доп. информация',
			'directions': 'Описание',
			'serving size': 'Размер порции',
			'servings per container': 'Содержимое',
			'amount per serving': 'Вес единицы',
			'calories': 'Калории (энергия)',
			'calories from fat': 'Калории из жира',
			'daily value': 'Дневная ценность',
			'total fat': 'Жиры',
			'saturated fat': 'Насыщенные жиры',
			'trans fat': 'Транс-жиры',
			'cholesterol': 'Холестерин',
			'sodium': 'Натрий',
			'total carbohydrate': 'Углеводы',
			'dietary fiber': 'Пищевые волокна',
			'sugars': 'Сахар',
			'protein': 'Белки',
			'vitamin a': 'Витамин A',
			'vitamin c': 'Витамин C',
			'calcium': 'Кальций',
			'iron': 'Железо',
			'g': 'г',
			'monounsaturated fat': 'Ненасыщенные жирные кислоты',
			'polyunsaturated fat': 'Полиненасыщенные жирные кислоты',
			'percent daily': 'Ваша дневная потребность может быть высокой или низкой в зависимости от вашей потребности в калориях.',
			'let\'s make sure we\'re available in your area': 'Давайте убедимся, что мы доступны в вашем регионе',
			'type in zip code': 'Введите индекс',
			'type in city': 'Введите  город',
			'area': 'Населённый пункт',
			'check': 'Проверка',
			'continue shopping': 'Продолжить покупку',
			'change area': 'Сменить регион',
			'quantity limitation': 'Ограничение количества',
			'is limited to ': 'Ограничено до - ',
			'items only': 'только товары',

			'our clubs specials': 'Специальные предложения нашего клуба',

			'similar items': 'Похожие товары',
			'nutrition': 'Пищевая ценность',
			'description': 'Описание товара',
			'and': 'и',
			'and the': 'и',
			'currently we do not deliver to your area': 'В данный момент мы не осуществляем доставку в вашем регионе',
			'ticket_status_1': 'Новый',
			'ticket_status_2': 'В обработке',
			'ticket_status_3': 'Завершён',
			'no previous inquiries': 'Нет запросов',
			'join membership': 'Присоединиться к клубу',
			'join club': 'Присоединиться к клубу',
			'view club member details': 'Просмотреть сведения о члене клуба',
			'thank you for signing up to {0} app': 'Благодарим что присоединились к апликации {0}',
			'join our loyalty club to benefit from exclusive promotions': 'Присоединитесь к клубу клиентов и получите эксклюзивные скидки',
			'connect to our loyalty club to benefit from exclusive promotions': 'Подключитесь к нашему клиентскому клубу и получите эксклюзивные скидки',
			'membership number': 'Номер члена клуба',
			'membership savings': 'Экономия клуба',
			'could save': 'Могли бы сэкономить',
			'customer service': 'Служба поддержки клиентов',
			'expiration date': 'Дата истечения срока действия',
			'comments': 'Комментарии',
			'send': 'Отправить',
			'credit cards': 'Кредитные карты',
			'drag files or browse...': 'Перетащите файлы здесь или нажмите, чтобы загрузить...',
			'you are now connected to your membership club account': 'Вы успешно присоединились к клиентскому клубу',
			'remove credit card info': 'Удалить информацию о кредитной карте',
			'are you sure you want to remove credit card information': 'Вы уверены, что хотите удалить информацию о кредитной карте',
			'type in new request': 'Введите новый запрос',
			'by': 'по',
			'from': 'от',
			'read more': 'Читать дальше',
			'recipe': 'Рецепт',
			'video': 'Видео',
			'min': 'мин.',
			'invalid coupon code': 'Неверный код купона',
			'order status': 'Статус заказа',
			'view order': 'Просмотреть заказ',
			'track order no.': 'Номер отслеживания заказа',
			'forgot something': 'Что-то забыли',
			'update order': 'Обновить заказ',
			'order received': 'Получен заказ',
			'in process': 'В процессе',
			'ready': 'Готов',
			'received': 'Получен',
			'need help': 'Необходима помощь',
			'edit order': 'Редактировать заказ',
			'edit your order': 'Редактировать заказ',
			'your order': 'Ваш заказ',
			'has been updated': 'отредактирован',
			'are you sure you want to cancel your order update and return to your original order': 'Вы действительно хотите отменить свой заказ и вернуться к первоначальному заказу?',
			'do you want to remove / add items to your existing order': 'Возможно вы хотите удалить / добавить продукты в существующий заказ',
			'note': 'Обратите внимание',
			'prices and specials will change according to the update date': 'Цены и акции изменятся в зависимости от даты изменения заказа',
			'please check your email for order details': 'Пожалуйста, проверьте электронную почту, чтобы увидеть подробности заказа',
			'thank you': 'Спасибо',
			'we have started collecting your original order.': 'Начата комплектация заказа',
			'we have finished collecting your original order.': 'Комплектация заказа завершена',
			'your order cannot be changed at this time': 'В данный момент ваш заказ не может быть изменён',
			'your order can no longer be changed': 'Ваш заказ больше не может быть изменён',
			'cancel update': 'Отменить обновления',
			'canceling': 'Отмена',
			'update': 'Редактирование',
			'replace shopping cart': 'Замена товаров в корзине',
			'are you sure you want to replace your shopping cart with the order from': 'Вы уверены, что хотите заменить продукты в вашей корзине на продукты, заказанные в дату',
			'replace': 'Заменить',
			'replace_2': 'Заменить',
			'you can still edit your order': 'Вы всё ещё можете отредактировать ваш заказ',
			'your original order is': 'Вернуться в',
			'cancel order update': 'Отменить редактирование',
			'cancel order': 'Отменить заказ',
			'in process ': 'Ваш первоначальный заказ',
			'your cart is empty': 'Ваша корзина пуста',
			'continue as new cart': 'Создать новую корзину',
			'you can move the selected items into a new order': 'Вы можете переместит выбранные товары в новый заказ',
			'my coupons': 'Мои купоны',
			'clip & add': 'Выбрать',
			'clip': 'Выбрать',
			'the site is undergoing maintenance': 'Сайт на техническом обслуживании',
			'promotion details': 'Подробности',
			'hide details': 'Спрятать подрбности',
			'show details': 'Показать подробности',
			'the following products are not active, and were removed from the cart': 'Следующие товары отсутствуют и были удалены из корзины',
			'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'Во время сбора товаров сумма к оплате может быть изменена в результате взвешивания и замены продуктов, а также их отсутствия на складе',
			'due to changes in address details': 'Этот адрес не соответствует выбранной дате отправки',
			'please select your delivery time again': 'Выберете новую дату доставки',
			'order': 'Заказ',
			'order id': 'Номер заказа',
			'pack items': 'Упаковка товаров',
			'how do you want to get': 'Как вы желаете получить',
			'cost': 'цена',
			'express': 'экспресс',
			'no_deliveries_first_row': 'К сожалению, не найден',
			'no_deliveries_second_row': 'Время доставки на этот день',
			'no_pickup_first_row': 'К сожалению, не найдено',
			'no_pickup_second_row': 'Время самовывоза на этот день',
			'add your comment here': 'Добавить комментарий',
			'substitutions': 'Замена товара',
			'before_checkout_delivery_title_1': 'Выбрать',
			'before_checkout_delivery_title_2': 'время доставки',
			'before_checkout_pickup_title_1': 'Забрать',
			'before_checkout_pickup_title_2': 'заказ из магазина',
			'your credit cards': 'Ваша кредитная карта',
			'add another card': 'Добавить кредитную карту',
			'order update': 'Обновление заказа',
			'updated order value': 'Обновленная стоимость заказа',
			'(estimated)': '(приблизительно)',
			'in order to complete the order update, please choose the payment method': 'Чтобы завершить обновление заказа, выберите способ оплаты',
			'confirm update': 'ПОДТВЕРДИТЬ ОБНОВЛЕНИЕ',
			'add your credit/debit card': 'Добавьте свои кредитные/дебетовые карты',
			'use my delivery address as my billing address*': 'Использовать мой адрес доставки в качестве платежного адреса*',
			'*by checking this option you approve transfer of your address data to the payment provider': 'Установив этот флажок, вы соглашаетесь на передачу данных о вашем адресе поставщику платежного сервиса.',
			'add another account': 'Добавить другой профиль',
			'please enter a name': 'Ввести имя',
			'please enter a mobile phone': 'Ввести моб. телефон',
			'please enter an address': 'Ввести улицу и № дома',
			'please enter house number': 'Ввести номер дома',
			'please enter city': 'Ввести город',
			'please enter a correct phone number': 'Ввести верный номер телефона',
			'is an anti-fraud security feature to help verify that you are in possession of your credit card': 'Код CVV - Credit Validation Value, это код, используемый для проверки того, что карта находится в вашем распоряжении',
			'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'Код CVV это три цифры, которые печатаются на обратной стороне карты на полосе для подписи',
			'enter your credit on file id': 'Введите номер своего счёта',
			'checkout_title': 'Завершение покупки',
			'cart no.': '№ корзины',
			'pick_and_go_title': 'Выбрать и уйти',
			'no need to wait in line': 'Не нужно ждать в очереди',
			'we will take care of your': 'Мы о вас позаботимся',
			'cart and deliver': 'К вашей корзине и доставке',
			'to begin, pick a': 'Для того,что бы начать, возьмите',
			'barcode card': 'Карта баркода',
			'start pick & go': 'Начать \'Выбрать и уйти\'',
			'are you sure you want to stop the': 'Вы уверены что хотите остановить',
			'pick & go service': 'услуга \'Выбрать и уйти\'',
			'stop pick & go': 'Закончить услугу \'Выбрать и уйти\'',
			'please enter your credit on file id': 'Ввести номер вашего счёта',
			'substitutions choice': 'Замена товаров',
			'home page': 'На главную страницу',
			'thank you for': 'Благодарим за',
			'shopping at {retailername}': 'покупку в {retailerName}',
            'an order summary email was sent to your inbox': 'Итог заказа отправлен на вашу электронную почту',
			'forgot': 'Забыли',
			'something': 'что-то',
			'1 hours delivery': 'Доставка в течение часа',
			'2 hours delivery': 'Доставка в течение 2-х часов',
			'{hours} hours delivery': 'Доставка в течение {hours} часов',
			'{minutes} minutes delivery': 'Доставка в течение {minutes} минут',
			'1 hour pickup': 'Самовывоз в течение часа',
			'2 hour pickup': 'Самовывоз в течение 2-х часов',
			'{hours} hour pickup': 'Самовывоз в течение {hours} часов',
			'hours': 'время',
			'within': 'внутри',
			'minutes': 'минут',
			'pickup time and address': 'Время и адрес самовывоза',
			'pickup details': 'Детали самовывоза',
			'your order will be ready for pickup': 'Ваш заказ будет готов к самовывозу',
			'loading': 'Загружается',
			'paypal accounts': 'Счёт PayPal',
			'create new order': 'Создать новый заказ',
			'create new shopping list': 'Создать новый список покупок',
			'add cart products': 'Добавьте продукты из корзины',
			'create': 'Создать',
			'no shopping list created yet': 'Перечень покупок еще не создан',
			'remove format': 'Удалить формат',
			'since your address was not detected': 'Поскольку ваш адрес не был распознан',
			'please re-select a new delivery time': 'Пожалуйста, выберете новое время поставки',
			'select delivery time': 'Выберете время доставки',
			'please try again': 'Повторите попытку',
			'add a credit card': 'Добавить кредитную карту',
			'add paypal account': 'Добавить счёт PayPal',
			'it seems you are editing your order on a different device.': 'Вы редактируете заказ на другом устройстве',
			'note! it seems you have finished editing your order on a different device/browser.': 'Обратите внимание! Вы уже отредактировали заказ на другом устройстве / браузере',
			'it seems you have finished your order on a different device.': 'Ваш заказ уже отредактирован',
			'wee are currently updating this device too': 'Мы также обновляем это устройство',
			'sorry, no available delivery time slots': 'Извините, нет свободного времени доставки',
			'sorry, no available pickup time slots': 'Извините, нет свободного времени самовывоза',
			'today\'s special deals': 'Лучшие предложения',
			'loyalty membership expired': 'Срок действия вашего клуба истёк',
			'hello': 'Здравствуйте',
			'free': 'Бесплатно',
			'your membership has expired': 'Срок действия вашего клуба истёк',
			'your club membership has expired on': 'Срок действия вашего клуба истёк',
			'renew you membership and enjoy exclusive benefits and discounts': 'Обновите свое членство в клубе и получите особые скидки при покупке товаров',
			'enter a valid number': 'Введите другой номер клуба',
			'no thanks, continue shopping': 'Продолжить без скидок клуба',
			'renew membership': 'Обновить членство',
			'your membership has been renewed successfully': 'Ваше членство успешно обновлено',
			'you can enjoy from the club benefits in this order': 'Вы можете пользоваться сидками клуба уже при этом заказе',
			'loyalty_mode_1_label': 'Существующий член клуба',
			'loyalty_mode_2_label': 'Вступить в клуб',
			'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon.png': 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/club-icon.png',
			'please fill in membership number': 'Введите номер члена клуба',
			'{retailername} membership offers exclusive': 'членство в клубе {retailername} Предоставляет вам',
			'promotions in-store and online': 'Специальные скидки и предложения',
			'hi': 'Здравствуйте',
			'the items in your shopping cart will be cleared if you choose to edit your previous order': 'Перейти к изменению заказа очистить все предметы, добавленные в корзину',
			'edit order & clear cart': 'Отредактировать заказ и очистить корзину',
			'it seems you are editing your order on a different device/browser.': 'Вы редактируете заказ на другом устройстве / браузере',
			'it seems you have finished editing your order on a different device/browser.': 'Вы сделали заказ с другого устройства / браузера',
			'do you want to add the product to this new order or to the order that is being updated?': 'Вы хотите добавить продукт в этот новый заказ или в заказ, который в режиме редактирования?',
			'note! you in the middle of editing order': 'Обратите внимание! Вы находитесь в процессе заказа',
			'you in the middle of editing order. changing store is impossible': 'Вы находитесь в процессе оформления заказа. Невозможно поменять магазин',
			'add to new order': 'Добавить в новый список',
			'add to updated order': 'Добавить в редактируемый список',
			'we are not able to register your membership club account': 'Невозможно присоединиться к клубу',
			'please make sure your details are correct or try again later': 'Убедитесь, что введенная вами информация верна или повторите попытку позже.',
			'https://d226b0iufwcjmj.cloudfront.net/lobal/mobile/images/backgrounds/loyalty-titles.png': 'https://d226b0iufwcjmj.cloudfront.net/global/mobile/images/backgrounds/loyalty-titles-he.png',
			'special_exp': 'Истекает',
			'special_exp_new': 'Истекает',
			'ends': 'Истекает',
			'forpackquantity': 'в',
			'verify_1': 'Пожалуйста подтвердите',
			'verify_2': ' ваш возраст',
			'verify_3': 'Ваш возраст должен быть не менее',
			'verify_4': 'лет что бы',
			'verify_5': 'приобрести данный товар',
			'verify_6': 'Мне',
			'verify_7': 'больше',
			'verify_8': 'чем',
			'verify_9': 'меньше',
			'years': ' лет',
			'sorry': 'Сожалеем',
			'sorry_2': ' Приносим извинения',
			'you cannot purchase': ' Вы не можете приобрести',
			'this item': ' данный товар',
			'please enter a valid email address': 'Введите адрес электронной почты',
			'password must include at least 6 characters': 'Пароль должен состоять минимум из 6 символов',
			'password should not be longer than 30 characters' :'Пароль должен содержать не более 30 символов',
			'password must contain at least one numeric digit and one character': 'Пароль должен содержать хотя бы одну цифру и одну букву',

			'let\'s start': 'Начать',
			'adding some items': 'Добавить товары',

			'please notice': 'Обратите внимание',
			'the expiration of these': 'Сроки годности',
			'sales will not be valid for this chosen time': 'Скидки на следующие товары истекают в день доставки заказа',
			'the special displayed below, will expire on the date you are supposed to receive your order': 'Срок действия скидок истекает в день доставки заказа',
			'these items will be charged at their regular price': 'Стоимость этих товаров будет взиматься по полной цене',
			'this product will be charged the regular price, promotion will not be applied': 'Стоимость данного товара будет взыматься по полной цене',
			'price of updated cart (est.)': 'Стоимость корзины обновлена',

			'general specials': 'Акции сети',
			'loyalty specials': 'Акции клуба',
			'category': 'Категория',
			'categories': 'Категории',
			'special type': 'Тип скидки',
			'clear all': 'Очистить всё',
			'filter by': 'Фильтровать по',
			'sort by': 'Сортировать по',
			'type': 'Тип',
			'our top picks': 'Рекомендуемые',
			'membership deals': 'Акции клуба',
			'it\'s easy!': 'Это легко!',
			'please sign in to continue...': 'Зайдите на сайт чтобы продожить...',
			'e-mail': 'Электронный адрес',
			'sign in': 'Войти',
			'club member name': 'Имя члена клуба',
			'{clubname} special deals': 'Скидки {clubname}',
			'membership_club': 'Клуб',
			'join our membership and enjoy exclusive benefits and promotions': 'Присоединитесь к клиентскому клубу и получите эксклюзивные скидки',
			'identify': 'Идентифицировать',
			'join our membership club': 'Присоединиться к клиентскому клубу',
			'activate': 'Активизировать',
			'please activate credit card {xxxx} in order to continue': 'Для того что бы продолжить, активируйте кредитную карту, заканчивающуюся цифрами {xxxx}',
			'membership_title_register': 'Присоединиться сейчас',
			'membership_title_identify': 'Идентифицироваться',
			'membership_title_{clubname}': 'В клуб {clubname}',
			'membership_{clubname}_title': 'В клуб {clubname}',
			'stores info': 'Информация о магазинах',
			'store info': 'Информация о магазине',
			'pickup address': 'Адрес самовывоза',
			'select address': 'Выберете адрес',
			'buy': 'Купить',
			'please enter e-mail': 'Введите электронные адрес',
			'menu_stores_info': 'Список отделений',
			'menu_store_info': 'Информация о иагазине',
			'your order is being edited by another device': 'Ваш заказ редактируется на другом устройстве',
			'organization': 'Организация',
			'organization_description': 'Оплата в кредит',
			'enter order confirmation no.': 'Введите номер подтверждения заказа',
			'a confirmation email was sent to {email}': 'письмо с подтверждением будет выслано на адрес {email}',
			'your payment method will not be charged': 'оплата взиматься не будет с вашего способа оплаты',
			'icreditmasterpass': 'MasterPass',
			'save_1': 'сэкономь',

			'friend\'s email': 'Электронный адрес друга\\подруги',
			'or share your promo code': 'или поделитесь своим промокодом',
			'congratulations': 'Специально для вас',
			'shop now': 'Начать покупку',
			'copy': 'Копировать',
			'no, thanks': 'Нет, спасибо',
			'got it': 'Спасибо, я понял',
			'invitation sent successfully': 'Приглашение успешно отправлено',
			'promo code unidentified': 'Промокод не найден',
			'enter your promo code': 'Ввести промокод',
			'there are no coupons currently available': 'Нет доступных купонов сейчас',

			'express delivery is allowed only with products that contain express delivery tag': 'Быстрая доставка возможна только с товарами, отмеченными как быстрая доставка',
			'express delivery products limit exceeded': 'Количество товаров превышает ограничение для отправки',
			'this service is available for express items only. removing non express items from your cart will enable using this option': 'Эта услуга доступна только для товаров, отмеченных как быстрая доставка. Удаление неотмеченных товаров позволит использовать эту услугу',
			'express delivery is limited to': 'Экспресс доставка ограничивается до',
			'items. to enable this service, remove items from your cart': 'Только детали. Чтобы включить эту услугу, удалите продукты из корзины',

			'buy_x_for_y': 'Купить {{purchaseTotal}}',

			/*pele card errors*/
			'permitted transaction.': 'Сделка разрешена.',
			'the card is blocked, confiscate it.': 'Карта заблокированна.',
			'the card is stolen, confiscate it.': 'Карта украдена.',
			'contact the credit company.': 'Позвонить в кредитную компанию.',
			'refusal by credit company.': 'Отказ от компании кредитной карты cvv неверный.',
			'the card is forged, confiscate it.': 'Поддельная карта.',
			'incorrect cvv/id.': 'номер паспорта или/номер-CVV не соответствует номеру карты.' /*'Необходимо позвонить в кредитную компанию.'*/,
			'an error occurred while building access key for blocked card files.': 'Не удалось создать ключ доступа для заблокированного файла.',
			'no communication. please try again or contact system administration': 'Не удалось связаться.',
			'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'Программа была прекращена в соответствии с распоряжением оператора (ESC) или COM PORT невозможно открыть(WINDOWS).',
			'the acquirer is not authorized for foreign currency transactions': 'Нет разрешения на операции с иностранной валютой.',
			'this card is not permitted for foreign currency transactions': 'Данная карта не предусматвает аперации в иностранной валюте.',
			'this card is not supported.': 'Карта не поддерживается.',
			'track 2 (magnetic) does not match the typed data.': 'Набранный номер не соответствует магнитной полосе.',
			'additional required data was entered/not entered as opposed to terminal settings (z field).': 'Дополнительные данные не являются или противоречат настройкам терминала.',
			'last 4 digits were not entered (w field).': 'Не введены 4 последние цифры.',
			'entry in int_in file is shorter than 16 characters.': 'Запись файла INT_IN менее 16 символов.',
			'the input file (int_in) does not exist.': 'Входной файл (IN_INT) не существует.',
			'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'Заблокированный файл (NEG) отсутствует или не обновляется. Выполните запрос на трансляцию или утверждение для каждой сделки.',
			'one of the parameter files/vectors does not exist.': 'Один из параметров или векторов не существует.',
			'date file (data) does not exist.': 'Файл даты (DATA) не существует.',
			'format file (start) does not exist.': 'Загрузочный файл (START) не существует.',
			'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'Слишком большая разница в днях приема заблокированных звонков - Выполнить трансляцию или запросить одобрение каждой сделки.',
			'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'Разница в поколениях заблокированных карт слишком велика - Выполнить трансляцию или запросить одобрение каждой сделки.',
			'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'Когда магнитная полоса не вставлена, сделка рассматривается как телефонная или только по подписи.',
			'the central terminal number was not entered into the defined main supplier terminal.': 'Номер центрального терминала не был введен в терминал, для работы в качестве поставщика.',
			'the beneficiary number was not entered into the defined main beneficiary terminal.': 'Номер получателя не был указан в терминале, для работы в качестве терминала-бенефициара.',
			'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'Номер поставщика\бенефициара введён, но терминал не был обновлён в качестве поставщика\бенефициара.',
			'the beneficiary number was entered, however the terminal was updated as the main supplier': 'Номер бенефициара введён, но терминал обновлён в качестве основного поставщика.',
			'old transactions, transmit or request authorization for each transaction.': 'Старые сделки, трансляция или запрос авторизаии для каждой сделки.',
			'defective card.': 'Ошибка при вводе кредитной карты, повторите попытку.' /*'Карта неверна.'*/,
			'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'Эта карта не может обслуживаться в данном терминале или нет разрешения на данный тип сделки .',
			'this card is not permitted for this transaction or type of credit.': 'Данная сделка или вид кредита не могут быть выполнены этой картой.',
			'expired card.': 'Истёк рок действия карты.',
			'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'Ошибка платежа, сумма сделки должна быть равна первому очередному платежу + (фиксированный платёж умноженный на кол-во платежей).',
			'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'Невозможно осуществить дебетовую сделку превышающую лимит кредитной карты.',
			'incorrect control number.': 'Ошибка в наборе номера кредитной карты, попробуйте еще раз.' /*'Контрольная цифра неверна.'*/,
			'the beneficiary and supplier numbers were entered, however the terminal is defined as main.': ' Были введены номера получателя и поставщика, терминал определен как основной.',
			'the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).': ' Сумма сделки превышает  лимит, когда входной файл содержит j1, j2 или j3 (звонить нельзя).',
			'the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).': 'Карта заблокирована для поставщика, в котором входной файл содержит j1, j2 или j3 (звонить нельзя).',
			'random where input file contains j1 (contact prohibited).': 'Выборочно, когда входной файл содержит J1 (звонить нельзя).',
			'the terminal is prohibited from requesting authorization without transaction (j5).': 'Терминал не может запрашивать разрешение без сделки (j5).',
			'the terminal is prohibited for supplier-initiated authorization request (j6).': 'Терминал не может запрашивать разрешение по инициативе поставщика (j6).',
			'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'Терминал должен запросить разрешение, когда входной файл j1, j2 или j3 (звонить нельзя).',
			'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': ' Необходимо ввести секретный код, когда входной файл содержит j1, j2 или j3 (звонить нельзя).',
			'incorrect vehicle number.': 'Номер машины неверный.',
			'the number of the distance meter was not entered.': 'Одометр не был введен.',
			'the terminal is not defined as gas station (petrol card or incorrect transaction code was used).': ' Терминал не настроен как топливная станция (проведена топливная карта или неверный код сделки).',
			'an id number is required (for israeli cards only) but was not entered.': 'Номер удостоверения личности не введён.',
			'cvv is required but was not entered.': 'Не введён CVV2',
			'cvv and id number are required (for israeli cards only) but were not entered.': 'Не введены номер удостоверения личности или CVV2 .',
			'abs attachment was not found at the beginning of the input data in memory.': 'Не было найдено совпадений вначале входных данных в памяти ABS.',
			'the card number was either not found or found twice.': 'Номер карты не найден или найден дважды.',
			'incorrect transaction type.': 'Тип сделки неверный.',
			'incorrect transaction code.': 'Код сделки неверный.',
			'incorrect credit type.': 'Тип кредита неверный.',
			'incorrect currency.': 'Неверная валюта.',
			'the first installment and/or fixed payment are for non-installment type of credit.': 'Первый взнос и/или фиксированный платёж служат средством погашения кредита без рассрочки.',
			'number of installments exist for the type of credit that does not require this.': 'Количество платежей существует для определённого вида кредита, который этого не требует.',
			'linkage to dollar or index is possible only for installment credit.': 'Привязка к доллару или индексу возможны только для потребительского кредита.',
			'the magnetic strip is too short.': 'Длина магнитной ленты слишком короткая.',
			'the pin code device is not defined.': 'Прибор для набора секретного кода не определен.',
			'must enter the pin code number.': 'Необходимо ввести секретный код.',
			'smart card reader not available - use the magnetic reader.': 'Устройство для чтения смарт-карт недоступно - используйте магнитный считыватель.',
			'must use the smart card reader.': 'Необходимо провести карту через устройство для чтения смарт-карт.',
			'denied - locked card.': 'Отказ - карта заблокирована.',
			'denied - smart card reader action didn\'t end in the correct time.': 'Отказ - процесс считывание смарт-карт не закончен в нужное врем.',
			'denied - data from smart card reader not defined in system.': 'Отказ - данные, полученные от устройства для чтения смарт-карт не найдены в системе.',
			'incorrect pin code.': 'Неверный секретный код.',
			'currency does not exist in vector 59.': 'Валюта не существует в векторе 59.',
			'the club code entered does not match the credit type.': 'Введенный код клуба не соответствует типу кредита.',
			'cannot cancel charge transaction. please create a credit transaction.': 'Аннулирование оплаты по сделке невозможно, следует произвести возврат.',
			'unable to read/write/open the tran file.': 'Невозможно прочитать/написать/открыть файл TRAN.',
			'no authorization from credit company for clearance.': 'Введённая карта не поддерживается.' /*'Нет разрешения от кредитной компании.'*/,
			'the terminal is not permitted to send queries for immediate debit cards.': 'У терминала нет разрешения на отправку запроса о немедленном кредите.',
			'the transaction amount is too large, divide it into a number of transactions.': 'Сумма сделки слишком велика - разбить на несколько сделок.',
			'the terminal is not authorized to execute forced transactions.': 'Терминал не имеет разрешения на выполнение вынужденных сделок.',
			'the terminal is not authorized for cards with service code 587.': 'У терминала нет разрешения для карт с сервисным кодом 587.',
			'the terminal is not authorized for immediate debit cards.': 'Терминал не имеет разрешения на мгновенную дебетовую карт.',
			'the terminal is not authorized for installment transactions.': 'Терминал не имеет разрешения на сделку в рассрочк.',
			'the terminal is authorized for installment transactions only, not telephone/signature.': 'Терминал не уполномочен совершать сделки  по телефону или по подписи, только в рассрочку.',
			'the terminal is not authorized for telephone transactions.': 'Терминал не имеет разрешения на телефонную сделку.',
			'the terminal is not authorized for signature-only transactions.': 'Терминал не имеет разрешения для сделок только с подписью.',
			'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': ' У терминала нет разрешения на сделку в долларах.',
			'the terminal is not authorized for club transactions.': ' У терминала нет разрешения на клубную сделку.',
			'the terminal is not authorized for star/point/mile transactions.': 'Терминал не имеет разрешения на сделки в «звездочках / баллах / милях».',
			'the terminal is not authorized for isracredit credit.': 'Терминал не имеет разрешения на получение кредита Isracredi.',
			'the terminal is not authorized for amex credit.': 'У терминала нет разрешения для Amex Credit.',
			'the terminal is not authorized for dollar linkage.': ' У терминала нет разрешения на привязку к доллару.',
			'the terminal is not authorized for index linkage.': 'У терминала нет разрешения на привязку к индексу.',
			'the terminal is not authorized for index linkage with foreign cards.': 'Терминал не имеет разрешения на привязку к индексу для иностранных карт.',
			'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'Терминал не имеет разрешения на сделки «звездочки / баллы / мили для данного вида кредита.',
			'the terminal is not authorized for isra 36 credit.': 'Терминал не имеет разрешения на кредит Isra 36.',
			'the terminal is not authorized for amex 36 credit.': 'Терминал не имеет разрешения на кредит Amex 36.',
			'the terminal is not authorized for this club code.': 'У терминала нет разрешения для этого кода клуба.',
			'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'Терминал не имеет разрешения на срочную дебетовую сделку (за исключением срочных дебетовых карт).',
			'the terminal is not authorized to accept visa card staring with 3.': 'У терминала нет разрешения принимать карты Visa, которые начинаются на 3.',
			'the terminal is not authorized to execute credit transactions above the ceiling.': 'Терминал не может выполнять кредитные сделки превышающие максимально разрешенный предел.',
			'the card is not permitted to execute club transactions.': 'Данной картой невозможно совершить клубную сделку.',
			'the card is not permitted to execute star/point/mile transactions.': 'Данной картой невозможно выполнить сделки со звездочками / баллами / милями.',
			'the card is not permitted to execute dollar transactions (regular or telephone).': 'Данной картой невозможно выпонить сделку в долларах (обычную или телефонную).',
			'the card is not valid according to isracard`s list of valid cards.': 'Данная карта недействительна в соответствии со списком действительных карт Isracard.',
			'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'Карта неисправна в соответствии с определением системы (VECTOR1 Isracard) - количество цифр на карте неверно.',
			'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'Данной картой невозможно выполнять долларовые сделки в соответствии с определением системы (VECTOR1 Isracard).',
			'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'Карта принадлежит группе карт, которая не имеет права выполнять сделки в соответствии с определением системы (Visa VECTOR20).',
			'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'Префикс карты (7 цифр) недействителен в соответствии с определением системы (21VECTOR of Diners).',
			'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'Карта не может совершать сделки в рассрочку в соответствии со списком действительных карт Isracard.',
			'the number of installments is too large according to isracard`s list of valid cards.': 'Количество платежей слишком велико в соответствии со списком действительных карт Isracard.',
			'visa and diners cards are not permitted for club installment transactions.': 'Карты Visa и Diners не допускаются к совершению клубных сделок в рассрочку.',
			'series of cards are not valid according to system definition (isracard vector5).': 'Ряд карт недействителен в соответствии с определением системы (VECTOR5 Isracard).',
			'invalid service code according to system definitions (isracard vector6).': ' Неверный сервисный код в соответствии с определением системы (VECTOR6 Isracard).',
			'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'Префикс карты (2 цифры) неверный в соответствии с определением системы (VECTOR7 Isracard).',
			'invalid service code according to system definitions (visa vector12).': 'Неверный сервисный код в соответствии с определением системы (Visa VECTOR12).',
			'invalid service code according to system definitions (visa vector13).': 'Неверный сервисный код в соответствии с определением системы (Visa VECTOR13).',
			'immediate debit card is prohibited for executing credit transaction.': 'Для мгновенной дебетовой картой запрещено выполнять данную сделку.',
			'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'Данной картой невозможно осуществлять сделки в рассрочку в соответствии с 31 альфа вектором.',
			'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'Данной картой невозможно выполнять телефонные операции и операции только с подписью на основании альфа вектор no. 31 .',
			'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'Данной картой невозможно выполнять телефонные сделки на основании альфа vector no. 31.',
			'credit is not approved for immediate debit cards.': 'Кредит не разрешен для срочных дебетовых карт.',
			'credit is not approved for foreign cards.': 'Кредит не разрешен для иностранных карт.',
			'incorrect club code.': 'Неверный клубный код.',
			'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'Данной картой невозможно выполнять гибкие кредитные сделки (желательно + 30) в соответствии с определением системы (21VECTOR of Diners).',
			'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'Данной картой невозможно выполнять немедленные дебетовые сделки в соответствии с определением системы (Diners VECTOR21).',
			'the payment amount is too low for credit transactions.': 'Сумма оплаты слишком мала для осуществления кредитной сделки.',
			'incorrect number of installments for credit transaction.': 'Количество платежей по кредитной сделке неверно.',
			'zero ceiling for this type of card for regular credit or credit transaction.': 'По данной карте для кредитной сделки установлен лимит 0.',
			'zero ceiling for this type of card for immediate debit credit transaction.': 'По данной карте для срочной дебетовой сделки установлен лимит 0.',
			'zero ceiling for this type of card for immediate debit in dollars.': 'По данной карте для срочной дебетовой сделки в долларах установлен лимит 0..',
			'zero ceiling for this type of card for telephone transaction.': 'По данной карте для телефонной сделки установлен лимит 0.',
			'zero ceiling for this type of card for credit transaction.': 'По данной карте для кредитной сделки установлен лимит 0.',
			'zero ceiling for this type of card for installment transaction.': 'По данной карте для сделки в рассрочку  установлен лимит 0.',
			'american express card issued abroad not permitted for instalments transaction.': 'Невозможно осуществить сделку в рассрочку картой American Express, выданной за границей.',
			'jcb cards are only permitted to carry out regular credit transactions.': 'Картой JCB можно выполнить только обычную кредитную сделки.',
			'the amount in stars/points/miles is higher than the transaction amount.': 'Сумма в звездочках /баллах / милях превышает сумму сделки.',
			'the club card is not within terminal range.': 'Клубная карта не находится в пределах терминала.',
			'star/point/mile transactions cannot be executed.': 'Невозможно выполнить сделку в звездочках / баллах / милях.',
			'dollar transactions cannot be executed for this type of card.': 'Данной картой невозможно выполнить сделку в долларах.',
			'credit transactions cannot be executed with other than regular credit.': 'Невозможно совершить кредитную сделку иным способом кроме обычного',
			'amount of discount on stars/points/miles is higher than the permitted.': 'Размер скидки в звездочках / баллах /милях превышает позволенный.',
			'forced transactions cannot be executed with credit/immediate debit card.': 'Невозможно совершить вынужденную сделку посредством кредита\срочной дебетной карты.',
			'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'Предыдущая сделка не может быть отменена (кредитная сделка или номер карты не совпадает).',
			'double transaction.': 'Двойная сделка.',
			'the terminal is not permitted for index linkage of this type of credit.': 'Терминал не имеет разрешения на привязку данного кредита к индексу.',
			'the terminal is not permitted for dollar linkage of this type of credit.': 'Терминал не имеет разрешения на привязку данного кредита к доллару.',
			'the card is invalid according to system definitions (isracard vector1).': 'Карта недействительна в соответствии с определением системы (вектор 1 Isracard).',
			'unable to execute the self-service transaction if the gas station does not have self service.': 'Невозможно совершить сделку посредством самообслуживания, если заправочные станции не предусматривают самообслуживания.',
			'credit transactions are forbidden with stars/points/miles.': 'Кредитная сделка с помощью звездочек /баллов / миль запрещена.',
			'dollar credit transactions are forbidden on tourist cards.': 'Запрещается совершать долларовые сделки туристической картой.',
			'phone transactions are not permitted on club cards.': ' Сделка по телефону невозможна для клубных карт.',
			'application error.': 'Ошибка приложения.',
			'transaction amount missing or zero.': 'Сумма сделки отсутствует или равна нулю..',
			'no communication to pelecard.': 'Нет связи с пеле-карто.',
			'doubled transaction.': 'Двойная сделка.',
			'terminal number does not exist.': 'Номер терминала не существует.',
			'terminal executes broadcast and/or updating data. please try again later.': 'Терминал в процессе трансляции и\ или обновления, повторите попытку позже.',
			'user name and/or password not correct. please call support team.': 'Имя пользователя и / или пароль неверны, пожалуйста, обратитесь в службу поддержки.',
			'user password has expired. please contact support team.': 'Срок действия пароля истек, пожалуйста, обратитесь в службу поддержки.',
			'please enter confirm password': 'Введите пароль для подтверждения',
			'the passwords must match': 'Пароли должны совпадать',
			'locked user. please contact support team.': 'Пользователь заблокирован, пожалуйста, обратитесь в службу поддержки.',
			'blocked terminal. please contact account team.': 'Терминал заблокирован, пожалуйста, обратитесь в бухгалтерию.',
			'token number abnormal.': 'Номер талона неверный.',
			'user is not authorized in this terminal.': 'Пользователь не может выполнять сделки на этом терминале.',
			'validity structure invalid. use mmyy structure only.': 'Неверная структура годности, использовать только сруктуру MMYY.',
			'ssl verifying access is blocked. please contact support team.': 'Доступ к проверке сертификата безопасности заблокирован. Обратитесь в службу поддержки.',
			'data not exist.': 'Нет данных.',
			'general error. please contact support team.': 'Общая ошибка, пожалуйста, обратитесь в службу поддержки.',
			'necessary values are blocked/wrong.': 'Необходимые значения  заблокированны / неверны.',
			'general error. repeat action.': 'Общая ошибка, пожалуйста, обратитесь в службу поддержки.',
			'necessary values missing to complete installments transaction': 'Необходимые значения отсутствуют для завершения сделки в рассрочку.',
			/*end pele card errors*/

			'sorry, we do not deliver to your area': 'Извините, но в данный момент мы не осуществляем доставку в ваш регион',
			'please type in your email and we will let you know when we expand to your location': 'Оставте нам ваш электронный адрес и мы сообщим когда будут выполняться доставки в ваш регион',
			'keep in touch': 'Сообщить мне',
			'at_hour': 'В',
			'the waiting period will not exceed two hours': 'Доставка в течение 2-х часов',
            'similar products': 'Похожие товары',
            'additional special\'s variety': 'Дополнительное разнообразие акционных товаров',
			'select from variety': 'Выбрать из ассортимента',
            'product info disclaimer': 'Не следует полагаться на детали, указанные на сайте о составе продукта, могут быть ошибки или несоответствия в информации, точные данные указаны на товаре. Перед использованием проверьте данные на упаковке продукта.',
			'employee discount': 'Скидка работникам',
			'card id': 'Номер карты',
			'discount': 'Скидка',
			'remaining obligo': 'Остаток бюджета',
			'monthly obligo': 'Ежемесячный бюджет',
			'obligo type': 'Тип бюджета',
			'monthly discount obligo': 'ежемесячный бюджет скидки',
			'cardid: required error': 'Пожалуйста, введите номер карты',
			'pin: required error': 'Пожалуйста, введите Пин код',
			'employee discount card holder?': 'Держатель дисконтной карты работника?',
			'employee discount card data': 'Данные вашей дисконтной карты работника',
			'employee discount account not found': 'Дисконтная карта работника не найдена',
			'estimated employee savings': 'Примерная экономия работника',
			'unlimited': 'Без ограничений',
            'show more': 'Показать все',
			'show less': 'Показать меньше',
			'we couldn\'t find any products': 'Товары не найдены',
			'or': 'или',
			'organization management': 'Управление организацией',
			'no available deliveries in the upcoming days': 'Нет доставки в ближайшие дни',
			'address_warning_enter_house_num': 'Пожалуйста, введите номер дома',
			'so we can assign you to the right branch': 'чтобы мы определили правильное отделение для вас',
			'items to cart': 'Товары в корзину',

			'your products from a website': 'Ваши продукты с сайта',
			'inventory availability may vary according to the delivery area': 'Наличие товаров может меняться в зависимости от зоны доставки',
			'please note - we could not identify {number}': 'Обратите внимание - мы не смогли определить {number}',
			'more products': 'других продуктов',
			'more product': 'другой продукт',
			'from the {name} website': 'с сайта {name}',
			'out of stock': 'нет в наличии',
			'added to cart': 'добавлено в корзину',
			'finish & go to cart': 'Закончить и перейти в корзину',
			'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'Ваша учетная запись еще не была подтверждена с помощью электронной почты - пожалуйста, подтвердите, нажав на ссылку, отправленную на ваш почтовый ящик',
			'resend activation link': 'Отправить ссылку активации повторно',
			'user email address not found': 'Адрес электронной почты пользователя не найден',
			'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'На ваш электронный адрес будет отправлено письмо с подтверждением. Нажмите ссылку в течение следующих {link_ttl} минут',
			'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'Ваш аккаунт требует активации, письмо со ссылкой для активации было отправлено на ваш адрес электронной почты. Нажмите ссылку в течение следующих {link_ttl} минут',
            'your account requires activation.': 'Ваш аккаунт требует активации.',
            'email with activation link have been sent to your email.': 'Письмо со ссылкой для активации было отправлено на ваш адрес электронной почты.',
            'the link is valid for {link_ttl} minutes': 'Ссылка активна в течение следующих {link_ttl} минут',
            'activation code expired': 'Срок действия кода активации истек',
            'website login': 'Вход на сайт',
            'send a new link': 'Отправить новую ссылку',
            'sorry, this link has expired': 'Извините, срок действия этой ссылки истек',
			'a new link can be sent': 'Можно отправить новую ссылку',
            'you have successfully registered for the site': 'Вы успешно зарегистрировались на сайте',
            'this account has already been verified': 'Пользователь уже активирован',
			'you may enter the trade site': 'Вы можете войти на сайт',
            'this account is an organizational account. you may need to verify it similarly from time to time': 'Эта учетная запись является учетной записью организации. Время от времени вам может потребоваться аналогичная активация',
			'please verify your identity': 'Пожалуйста, подтвердите вашу личность',
			'to verify your identity, please enter a phone number': 'Чтобы подтвердить свою личность, введите номер телефона',
			'continue to registration': 'Продолжить регистрацию',
			'phone number does not match': 'Номер телефона не совпадает',
			'user invitation not found': 'Приглашение пользователя не найдено',
			'user already registered': 'Пользователь уже зарегистрирован',
			'phone number hint': 'подсказка номера',
			'account verification succeed': 'Пользователь успешно активирован',

			'user products list not found': 'Список продуктов пользователя не найден',
			'user products list is disabled for this retailer': 'Список продуктов пользователя отключен для этого сайта',
			'my lists': 'Мои списки',
			'my products': 'Мои продукты',
			'my products list': 'Мои продукты',
			'products i usually purchase': 'Продукты, которые я покупаю',
			'no products were selected': 'Товары не выбранны',
			'your time is precious': 'Ваше время дорого',
			'we have prepared a list of your personal products': 'Мы подготовили список ваших личных товаров',
			'we have prepared a list of products you usually purchase': 'Мы подготовили список товаров которые вы покупаете',
			'add products from the list': 'Добавить товары из списка',
			'in cart': 'В корзине',
			'cart': 'Корзина',
			'select products and click import to cart': 'Отметьте продукты и нажмите Добавить',

			'we use cookies to improve your experience': 'Мы используем файлы cookie, чтобы вам было удобнее',
			'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': 'Мы используем файлы cookie или другие трекеры для облегчения использования сайта, повышения его производительности и безопасности, персонализации предлагаемого контента, включая персонализированный рекламный контент, и сбора статистики трафика и навигации. Для размещения некоторых файлов cookie, кроме функциональных файлов cookie и файлов cookie для обеспечения безопасности, требуется ваше согласие. ',
			'accept': 'Принять',
			'accept all cookies': 'Принять все файлы cookie',
			'setup my cookies': 'Настроить мои файлы cookie',
			'managing cookies': 'Управляющие файлы cookie',
			'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'Эти файлы cookie позволяют вам пользоваться сервисами, предлагаемыми сайтом, оптимизировать его использование и персонализацию (в частности, персонализацию предложений и рекламы) в зависимости от вашего профиля.',
			'marketing': 'Маркетинг',
			'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'Маркетинговые файлы cookie используются для повышения релевантности рекламных объявлений, которые вы видите, и для того, чтобы вы не видели одну и ту же рекламу несколько раз.',
			'additional features': 'Дополнительных функций',
			'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'TЭти файлы cookie позволяют активировать на сайте дополнительные функции, например, для облегчения ваших покупок.',
			'audience measurement': 'Измерения аудитории',
			'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'Эти файлы cookie позволяют нам получать статистику навигации на нашем сайте. Мы используем эти данные для выявления возможных сбоев в работе сайта, улучшения сайта, в маркетинговых целях и для персонализации вашего пребывания на сайте.',
			'google analytics': 'Google Analytics',
			'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'Эти файлы cookie позволяют нам получать статистику о количестве посещений нашего сайта (например, количество посещений, наиболее просматриваемые страницы и т.д.). Мы используем эти данные, чтобы лучше понимать своих пользователей, проверять эффективность своего контента, получать аналитические данные и определять возможные сбои в работе сайта для внесения улучшений.',
			'functional (not configurable)': 'Функциональные (ненастраиваемые)',
			'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'Эти файлы cookie позволяют запоминать ваши варианты выбора и предпочтения в вашей учетной записи и предоставлять функции, облегчающие использование сайта. Они обязательны для правильного функционирования сайта и строго необходимы для предоставления сервиса, который вы прямо запросили.',
			'time slot unavailable': 'Временной интервал доставки недоступен',
			'we\'re sorry, this time slot is no longer available. please select a different time': 'Сожалеем, этот временной интервал больше не доступен. Пожалуйста, выберите другое время доставки',
			'return to schedule': 'Вернуться к расписанию',
			'sitemap': 'Карта сайта',
			'pages': 'Страницы',
			'the {product_count} products listed below will be': '{product_count} следующих продуктов будут',
			'removed from your cart': 'удалены из корзины',
			'out of stock products': 'следующих товаров нет в наличии',
			'confirm': 'подтвердить',
			'back to cart': 'назад в корзину',
			'the items below will be removed from your cart': 'следующие товары будут удалены из вашей корзины',
			'{product_count} out of stock products will not be supplied': '{product_count} товар(ов) отсутствуют на складе и поэтому не будут доставлены',
			'{coupons_count} expired coupons cannot be redeemed': 'срок действия {coupons_count} купон(ов) истек и их нельзя использовать',

			'products marked with': 'Товары, отмеченные',
			'limited quantity': 'Ограниченное количество',
			'tag are limited to': 'тег ограничены',
			'items only.': 'только предметы.',
			'are you sure you want to remove all products from your shopping cart?': 'Вы уверены что хотите удалить все продукты с Вашей корзины?',
			'yes, clear cart': 'да, очистить корзину',
			'clear cart?': 'Очистить корзину?',
			'supervisions': 'Кашрут',
			'expired': 'срок действия истек',
			'registration attempt has been blocked, please contact support': 'Попытка регистрации заблокирована, обратитесь в службу поддержки',
			'account has been suspended, please contact support': 'Аккаунт заблокирован, обратитесь в службу поддержки',
			'tavplus': 'Tav Plus+',
			'your tav plus': 'Ваш Tav Plus',
			'add voucher': 'Добавить ваучер',
			'tav_plus_credit_card_disclaimer_short': 'Использование ваучеров ограничено вводом кредитной карты в качестве резервного средства платежа. Окончательная сумма счета за заказ будет определена в момент получения товара и может отличаться из-за легковесных, отсутствия или замены...',
			'tav_plus_credit_card_disclaimer_long': 'Использование ваучеров ограничено вводом кредитной карты в качестве резервного средства платежа. Окончательная сумма счета за заказ будет определена в момент получения товара и может отличаться из-за легковесных, отсутствия или замены товаров. В случае превышения суммы, введенной для оплаты заказа, или остатка ваучера, остаток суммы будет списан с кредитной карты. Ваучеры/подарочная карта должны храниться до получения заказа и соответствующего списания средств, при условии, что это оговорено в правилах.',
			'for additional terms and details': 'Дополнительные условия и подробности',
			'go to site': 'смотрите на сайте',
			'your voucher details': 'Данные вашего ваучера',
			'please enter your voucher number': 'пожалуйста, введите номер вашего ваучера',
			'voucher number': 'Номер ваучера',
			'if your voucher is a plastic card, please keep it in your possession until receiving your order': 'Если ваш ваучер представляет собой пластиковую карту, пожалуйста, храните ее при себе до получения заказа',
			'balance': 'Баланс',
			'amount to pay': 'Сумма к оплате',
			'delivery-fee-explain-default': 'Окончательная сумма платы за доставку будет обновлена при оформлении заказа и может быть изменена в зависимости от региона/времени доставки или любых дополнительных скидок. ',
			'delivery_type_1_fee': 'Kомиссия доставка',
			'delivery_type_2_fee': 'Kомиссия самовывоз',
			'includes_delivery_type_1_fee': 'Bключает комиссию в размере доставка',
			'includes_delivery_type_2_fee': 'Bключает комиссию в размере самовывоз',
			'not_includes_delivery_fee': '(без учета стоимости доставки)',
			'valid until': 'Годен до',
			'proceed to checkout': 'Завершить покупку',

			'add coupon': 'Добавить купон',
			'your points': 'Ваши баллы',
			'new': 'Новый',
			'buy with points': 'Купить за баллы',
			'almost expired': 'Срок действия почти истек',
			'coupon clipped': 'Купон использован',
			'add product': 'Добавить товар',
			'product in cart': 'Товар в корзине',
			'clip coupon': 'Использовать купон',
			'clipped externally': 'Получен в другом месте',
			'personal coupons': 'Персональные купоны',
			'may interest you': 'Вас может заинтересовать',
			'clip for {x} points': 'Использовать за {x} баллов',
			'not enough points': 'Недостаточно баллов',
			'coupon expires today': 'Срок действия купона истекает сегодня',
			'coupon expires in {x} days': 'Срок действия купона истекает через {x} дней',
			'clip for': 'Использовать за',
			'valid': 'Действует',
			'can be redeemed once': 'Можно использовать один раз',
			'can be redeemed {x} times': 'Можно использовать {x} раз',

			'last_minute_recommendations_headline': 'у нас есть рекомендации специально для вас.',
			'last_minute_recommendations_title_coupons': 'Купоны, которые могут сэкономить ваши деньги',
			'last_minute_recommendations_title_products_usually': 'Возможно, вы забыли эти продукты?',
			'last_minute_recommendations_title_products_complementary': 'Вам также может понравиться...',
			'last_minute_recommendations_title_specials_offers': 'Специальные предложения, которые вы не должны пропустить',
			'last_minute_recommendations_title_products_offers': 'Вы не должны пропустить эти продукты',

			'account_deletion_common_customer_support_1': 'Для получения помощи позвоните в службу поддержки клиентов:',
			'account_deletion_common_customer_support_2': 'Повторите попытку позже или позвоните в службу поддержки клиентов:',
			'account_deletion_account_title': 'Удалить учетную запись',
			'account_deletion_init_title': 'Удаление учетной записи',
			'account_deletion_recaptcha_description': 'Для удаления вашей учетной записи требуется проверка с использованием нескольких шагов.',
			'account_deletion_authentication_description': 'Письмо с проверочным кодом отправлено на адрес {userEmail}',
			'account_deletion_authentication_help_spam': 'Возможно, вам потребуется проверить папку со спамом',
			'account_deletion_authentication_help_code_1': 'Не получили код?',
			'account_deletion_authentication_help_code_2': 'Отправить еще раз',
			'account_deletion_authentication_help_error_try_again_1': 'Повторите попытку или',
			'account_deletion_authentication_help_error_try_again_2': 'получите новый код',
			'account_deletion_authentication_help_error_max_tries': 'Вы превысили количество возможных попыток, повторите попытку позже.',
			'Expired code': 'Код с истекшим сроком действия',
			'Invalid code': 'Неверный код',
			'account_deletion_confirmation_description_1': 'Вы собираетесь',
			'account_deletion_confirmation_description_2': 'навсегда удалить свою учетную запись',
			'account_deletion_confirmation_description_3': 'Все данные вашей учетной записи и будущие заказы будут удалены',
			'account_deletion_confirmation_description_4': 'Это действие не может быть отменено',
			'account_deletion_confirmation_help_input_1': 'Введя слово «CONFIRM», вы подтвердите удаление своей учетной записи.',
			'account_deletion_confirmation_help_input_2': 'Для подтверждения введите «CONFIRM»',
			'account_deletion_already_deleted_title': 'Продолжение невозможно',
			'account_deletion_already_deleted_description': 'Эта учетная запись удалена и находится в процессе обработки',
			'account_deletion_notification_title': 'Ваша учетная запись будет удалена через {numOfDaysUntilDeletion} дней',
			'account_deletion_notification_description': 'Любые заказы, размещенные для доставки или самовывоза после этого времени, не будут доставлены.',
			'account_deletion_error_title': 'Системная ошибка',
			'account_deletion_error_description': 'Продолжение невозможно из-за системной ошибки',
			'please type in your zip code': 'Введите свой почтовый индекс',
			'find address': 'Найди адрес',
			'you cannot edit this field before entering a zip code for your address': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
			'please choose your address': 'Выберите свой адрес',
			'you will be able to edit the address details': 'Вы сможете изменить данные адреса',
			'you cannot edit this field': 'Вы не можете вносить изменения в это поле',
			'not editable error': 'Вы не можете вносить изменения в это поле, пока не введете почтовый индекс для своего адреса.',
			'not_editable_locked error': 'Для того чтобы изменить адрес, выберите из списка почтовый индекс.',
			'not_editable_locked_empty error': 'Введите свой почтовый индекс и выберите адрес из списка.',
			'zipcode error': 'Вы не можете вносить изменения в это поле',
			'there are no available items for this promotion': 'Нет доступных товаров для этой акции',
			'proceed to checkout': 'Завершить покупку',

			'add_units_coupon': 'Добавьте еще {remaining} товаров, чтобы получить скидку',
			'add_currency_coupon': 'Добавьте еще {remaining} товаров, чтобы получить скидку',
			'add_measure_coupon': 'Добавьте еще {remaining} {unit}, чтобы получить скидку',
			'add_units_cart_coupon': 'Добавьте {remaining} товаров',
			'add_unit_cart_coupon': 'Добавьте {remaining} товар',
			'add_currency_cart_coupon': 'Добавьте {remaining}',
			'add_measure_cart_coupon': 'Добавьте {remaining} {unit}',
			'add_units_special_purchase': 'Добавьте еще {remaining}',
			'buy_units_total': 'Купите {total} товаров',
			'buy_unit_total': 'Купите {total} товаров',
			'buy_currency_total': 'Купите за {total}' ,
			'buy_measure_total': 'Купите {total} {unit}',

			'suggestions_dialog_header_1': 'Рекомендации по замене',
			'suggestions_dialog_header_2': 'выберите замену из предлагаемых нами аналогичных продуктов',
			'suggestions_dialog_header_3': 'Нет на складе',
			'suggestions_dialog_header_4': 'Рекомендации по замене:',
			'ok, continue' : 'Ок, ПРОДОЛЖИТЬ',
            'change chosen time': 'изменить дату доставки',

			'hover description for cvv': 'Номер CVV («Проверочный параметр карты») на вашей кредитной или дебетовой карте представляет собой трехзначный номер на фирменных кредитных и дебетовых картах VISA, Mastercard и Discover. на кредитной или дебетовой карте American Express это будет четырехзначный цифровой код.',
			'enter cvv number': '',

			'the coupon displayed below, will not be valid for this chosen time': 'Обратите внимание, купон, показанный ниже, не будет действителен в течение выбранного времени',
			'the expiration of these {coupon_number} coupons will not be valid for this chosen time': 'Обратите внимание, что срок действия этих {coupon_number} купонов недействителен в течение выбранного периода времени',
			'the coupon displayed below, will not be valid for this chosen time': 'Этот купон не будет действителен в течение выбранного времени',
			'these {coupon_number} coupons will not be valid for this chosen time': 'Эти {coupon_number} купона будут недействительны в течение выбранного периода времени',
			'price does not include bottle deposit': 'Цена не включает стоимость бутылки',
			'price includes bottle deposit': 'Цена включает стоимость бутылки',
			'balance to be paid': 'Баланс к оплате',
			'please enter the card number to check the balance': 'Пожалуйста, введите номер карты, чтобы проверить баланс',
			'redeem': 'Активировать',
			'please save the voucher or gift card until you receive your order and are charged': 'Пожалуйста, сохраните ваучер или подарочную карту до получения вашего заказа и списания средств',
			'choose gift card/s': '',
			'add gift card': '',
			'redeem gift card & discount cards': 'Чтобы использовать ПОДАРОЧНУЮ КАРТУ',
			'for gift card and discount cards': '',
			'gift_card_error_message': 'Мы не смогли обработать вашу ПОДАРОЧНУЮ КАРТУ. Пожалуйста, попробуйте еще раз или используйте другую карту',
			'wrong_gift_card_amount_error': 'Сумма превышает стоимость корзины',
			'amount_exceeds_balance': 'Сумма превышает баланс',
			'sorry, currently we don\'t deliver to this address for further inquiries call to the customer service.': 'К сожалению, в настоящее время мы не осуществляем доставку по данному адресу. Для дальнейшей информации обратитесь в службу поддержки',
			'storage instructions': 'Правила хранения',
			'january': 'январь',
			'february': 'февраль',
			'march': 'Маршировать',
			'april': 'апрель',
			'may': 'Может',
			'june': 'Июнь',
			'july': 'Июль',
			'august': 'Август',
			'september': 'Сентябрь',
			'october': 'Октябрь',
			'november': 'ноябрь',
			'december': 'Декабрь',
			'redeemed': 'погашено',
			'points gained': 'Получено баллов',
			'source': 'источник',
			'when': 'Когда',
			'gg_map_dialog_title': 'Выберите адрес',
			'gg_map_dialog_hint': 'При определении вашего точного местоположения возникли проблемы. Пожалуйста, убедитесь, что вы точно расположили метку местоположения. Это поможет нам определить ваш точный адрес',
			'gg_map_alert_need_location': 'Пожалуйста, включите местоположение, чтобы использовать Google Карты.',
			'gg_map_alert_error': 'Есть проблемы при отображении карт Google.',
			'gg_map_alert_unsupport': 'Геолокация не поддерживается этим браузером.',
			'autocomplete_adrs_didnt_find': 'Вы не нашли свой адрес?',
			'autocomplete_adrs_choose_location': 'Выберите свое местоположение на карте',
			'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'K сожалению, ваш город не найден. Выберите адрес из предложений автозаполнения или закрепите свой адрес c помощью опции Google Maps.',
			'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'K сожалению, ваш адрес не найден. Выберите адрес из предложений автозаполнения или закрепите свой адрес c помощью опции Google Maps.',
			'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'К сожалению, ваш почтовый индекс не найден. Пожалуйста, выберите адрес из предложенных в автозаполнении или укажите адрес с помощью опции google maps.',
			'countryInput: required error': 'Пожалуйста, введите вашу страну',
			'country_of_origin_title': 'Страна происхождения',
			'origin_country_legal_text': 'Обратите внимание, что страна происхождения указана на момент заказа. Фактически страна происхождения поставленного продукта может быть другой в зависимости от наличия продукта на складе в момент доставки',
			'after package deduction': 'После вычета расходов на упаковку',
			'is this address correct? you can now edit and refine it': 'Этот адрес правильный? Вы можете изменить или уточнить его',
			'edit your address': 'Изменить адрес',
			'contact_us': 'Связь с нами',
			'how_can_we_help_you_today': 'Как мы можем помочь вам?',
			'leave_us_a_message': 'Оставьте нам сообщение, и мы свяжемся с вами в ближайшее время',
			'fullname': 'Полное имя',
			'phone': 'Номер телефона',
			'email': 'Эл. адрес',
			'message': 'Как мы можем вам помочь?',
			'send': 'Отправить',
			'your_message_has_been_sent': 'Ваше сообщение отправлено',
			'thank_you_for_contact_us': 'Благодарим Вас за обращение к нам',
			'searchtext_storename_placeholder': '{Название магазина}',
			'customer support phone number': 'Номер телефона службы поддержки клиентов',
			'please wait, we are uploading the products': 'Пожалуйста, подождите, мы загружаем товары',
			'complete_and_proceed_to_checkout': 'Завершить и перейти к оплате',
			'please notice, the following products are not available on this chosen time.': 'Обратите внимание, что следующие продукты недоступны в выбранное время.',
			'the following products are not available on this chosen time.': 'что следующие продукты недоступны в выбранное время.',
			'if you continue, they will be removed from your cart.': 'Если вы продолжите, они будут удалены из вашей корзины.',
			'result for “{title}”': 'Результаты по “{title}”',
			'find a product': 'Найти продукт',
			'results by brand:': 'Результаты по бренду:',
			'please enter cvv to continue': 'Пожалуйста, введите CCV, чтобы продолжить',
			'checkout is opened on another screen': 'Оформление заказа открыто на другом экране',
			'loading coupons, please wait...': 'Мы загружаем купоны. Пожалуйста, подождите...',
			'please wait, we are loading the products': 'Пожалуйста, подождите, мы загружаем товары',
			'receipt': 'квитанция',
			'api_error_message': 'Произошла ошибка{error_message} (ошибка {error_code}). Повторите попытку через несколько минут. Если проблема не исчезнет, обратитесь в службу поддержки клиентов',
			'payment error': 'Ошибка оплаты',
			'error received': 'Произошла ошибка',
			'please check your credit card details or contact your credit card provider': 'Проверьте данные своей кредитной карты или свяжитесь с поставщиком кредитной карты',
            'address_form_field_label_city': 'Город',
            'address_form_field_label_address': 'Адрес',
            'address_form_field_label_zip_code': 'Почтовый индекс',
            'address_form_field_label_postal_code': 'Почтовый индекс',
            'address_form_field_label_entrance': 'Номер подъезда',
            'address_form_field_label_apartment_number': 'Номер квартиры',
            'address_form_field_label_building_code': 'Номер здания',
            'address_form_field_label_state': 'Страна',
            'address_form_field_label_comments': 'Примечания',
            'address_form_field_label_apartment_suite_unit': 'Квартира / Апартаменты / Жилая единица',
            'address_form_field_label_providence': 'Провиденс',
            'address_form_field_label_more_details': 'Дополнительная информация',
			'address_form_field_label_floor': 'этаж',
			'address_form_field_label_street': 'Улица',
			'address_form_field_label_house_number': 'Номер дома',	

			'address_form_field_error_city': 'Пожалуйста, введите корректный город',
			'address_form_field_error_address': 'Пожалуйста, введите корректный адрес',
			'address_form_field_error_zip_code': 'Пожалуйста, введите корректный почтовый индекс',
			'address_form_field_error_postal_code': 'Пожалуйста, введите корректный индекс',
			'address_form_field_error_entrance': 'Пожалуйста, введите корректный вход',
			'address_form_field_error_apartment_number': 'Пожалуйста, введите корректный номер квартиры',
			'address_form_field_error_building_code': 'Пожалуйста, введите корректный код здания',
			'address_form_field_error_state': 'Пожалуйста, введите корректный штат',
			'address_form_field_error_comments': 'Пожалуйста, введите комментарий',
			'address_form_field_error_apartment_suite_unit': 'Пожалуйста, введите корректную квартиру/свиту/единицу',
			'address_form_field_error_providence': 'Пожалуйста, введите корректный регион',
			'address_form_field_error_more_details': 'Пожалуйста, введите больше деталей',
			'address_form_field_error_floor': 'Пожалуйста, введите действительный этаж',
                
			'address_drop_pin_message_1': 'Местоположение успешно закреплено. Измените при необходимости.',
			'address_drop_pin_message_cancel': 'Отменить закрепленный адрес',

			'autocomplete_city_not_found_non_droppin': 'К сожалению, ваш город не найден. Пожалуйста, выберите адрес из списка или обратитесь в службу поддержки за помощью.',
			'autocomplete_address_not_found_non_droppin': 'К сожалению, ваш адрес не найден. Пожалуйста, выберите адрес из списка или обратитесь в службу поддержки за помощью.',
			'autocomplete_zip_code_not_found_non_droppin': 'К сожалению, ваш почтовый индекс не найден. Пожалуйста, выберите адрес из списка или обратитесь в службу поддержки за помощью.',

            'pickup time:': 'Время самовывоза:',
            'pickup address:': 'Адрес самовывоза:',
            'delivery time:': 'Время доставки:',
            'delivery address:': 'Адрес доставки:',
            'on_day': '',
            'wish_to_change_delivery': 'Хотите изменить время доставки?',
            'wish_to_change_pickup': 'Хотите изменить время самовывоза?',
            'select date & time': 'Выберите дату и время',
            'save & change delivery time': 'Сохранить и изменить время доставки',
            'save & change pickup time': 'Сохранить и изменить время самовывоза',
            'back to home page': 'Вернуться на главную страницу',
            'your order has been updated': 'Ваш заказ обновлен',

            'checkout_finish_last_update_time_delivery': 'Вы можете обновить время доставки заказа до',
            'checkout_finish_last_update_time_pickup': 'Вы можете обновить время самовывоза заказа до',
            'forgot_sth_want_to_change_delivery': 'Хотите изменить время доставки?',
            'forgot_sth_want_to_change_pickup': 'Хотите изменить дату самовывоза?',

            'update_order_popup_title_def': 'Вы можете изменить порядок товаров или время {delivery method}',
            'update_order_popup_order_item_def': 'изменить товары в заказе',
            'update_order_popup_delivery_time_def': 'обновить время доставки',
            'update_order_popup_pickup_time_def': 'обновить время самовывоза',
            'update_order_popup_cancel_def': 'Хотите отменить заказ? Нажмите здесь, чтобы отменить {cancel order}',
            'update_order_popup_lastest_time_def': 'Вы можете изменить время {delivery method} вашего заказа до {time calculate}',
            'update_order_popup_err_cant_update_time': 'Обновление времени {delivery method} недоступно',

            'irre_sales_collect_one': 'Этот {saleType} станет недействителен в новое указанное время и будет рассчитан по обычной цене',
            'irre_sales_collect_many': 'Эти {count} {saleType} станут недействительны в новое указанное время и будут рассчитываться по обычной цене',
            'irre_sales_checkout_one': 'При изменении этого заказа {saleType} станет недействителен и цена товара будет рассчитана по обычной цене',
            'irre_sales_checkout_many': 'При изменении заказа {count} {saleType} станут недействительны и цена товара будет рассчитана по обычной цене',
            'irre_sales_oos_one': 'Эти товары в настоящее время недоступны и будут удалены из вашей корзины.',
            'irre_sales_oos_many': 'Эти товары в настоящее время недоступны и будут удалены из вашей корзины.',
            'irre_sales_btn_continue': 'Да, обновить',
            'irre_sales_btn_change_slot': 'изменить дату {delivery method}',
            'irre_sales_btn_cancel_update': 'Отменить обновления',
            'thank you for your order!': 'Спасибо за ваш заказ!',
			'delivery_disclaimer_default_text':'Окна доставки можно выбирать только во время оформления заказа',

			"add your credit card": "Добавьте свою кредитную карту",
			"payment methods": "Способы оплаты",
			"your credit cards": "Ваши кредитные карты",
			"add a credit card": "Добавить кредитную карту",
			"gift cards": "Подарочные карты",
			"add gift cards": "Добавить подарочные карты",
			"choose gift card/s": "Выберите ПОДАРОЧНУЮ КАРТУ(-Ы)",
			"please enter the card number to check balance": "Для проверки баланса введите номер карты",
			"{gift card name} details": "Информация о {название подарочной карты}",
			"on the next screen, you can select your payment amount": "На следующем экране вы сможете выбрать сумму платежа",
			"balance:": "Баланс:",
			"please enter amount to pay": "Введите сумму для оплаты",
			"amount exceeds balance": "Сумма превышает баланс",
			"please save the voucher or gift card until you receive your order and are charged.": "Сохраните ваучер или подарочную карту до получения вашего заказа и списания средств.",
			"remove card": "Удалить карту",
			"see more": "узнать больше",
			"show less": "Показать меньше",
			"amount exceeds shopping cart value": "Сумма превышает стоимость корзины",
			'user not connected to organization': 'Пользователь не подключен к организации',

			'default_first_time_popup_title': 'Добро пожаловать!',
			'default_first_time_popup_sub_title': 'Как бы вы хотели получить свой заказ?',
			'default_first_time_popup_sub_title_pickup': 'Выберите ближайший магазин для удобного самовывоза!',
			'default_first_time_popup_sub_title_delivery': 'Введите свой адрес, чтобы увидеть доступные варианты доставки рядом с вами',
			'default_returning_popup_title': 'Изменить регион',
			'default_returning_popup_sub_title': 'Как бы вы хотели получить свой заказ?',
			'default_returning_popup_sub_title_pickup': 'Выберите новое место самовывоза',
			'default_returning_popup_sub_title_delivery': 'Найдите новые варианты доставки',

			'default_one_store_popup_title': 'Отличная новость!',
			'default_one_store_popup_sub_title': 'Мы можем выполнить доставку в ваш регион!',
			'default_one_store_popup_continue_button_text': 'Продолжить покупки',

			'default_multiple_store_popup_title': 'Вам повезло!',
			'default_multiple_store_popup_sub_title': 'У нас есть несколько магазинов, обслуживающих ваш регион',

			'default_no_store_popup_title': 'Нам очень жаль',
			'default_no_store_popup_sub_title': 'В настоящее время мы не осуществляем доставку в ваш регион. Оставьте свой адрес электронной почты, и мы сообщим вам, когда у нас появятся дополнительные варианты.',

			'delivery_input_zip_code_placeholder': 'Почтовый индекс (например: 75011)',
			'back_btn_text': 'Назад',

			'try a different location': 'Попробуйте выбрать другое место',
			'delivery_input_address_placeholder': 'Введите адрес доставки'
		};
	});
})(angular);
